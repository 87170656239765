// Node packages
import React from 'react';
import Textfield from '@react/react-spectrum/Textfield';
import { Grid } from '@material-ui/core';
import Button from '@react/react-spectrum/Button';
import Select from '@react/react-spectrum/Select';

import NumberInput from '@react/react-spectrum/NumberInput';
import { OfferObject } from '../../../models/memberships/Offer';

import { getValidationState } from '../../../utils/FormUtils';

import './Form.css';
import { getVIPSKUOptions, setVIPMPSKUValue } from '../../../RecordsPage/service/SelectService';
import { VIPSkuObject } from '../../../models/memberships/VIPSku';

interface OfferFormProps {
  offer: OfferObject;
  checkValid: boolean;
  index: number;
  vipSku: VIPSkuObject;
  disableOffers: boolean;
  updateOfferState: (order: OfferObject, index: number) => void;
  deleteOffer: (index: number) => void;
  refreshOfferForm: (vipSku: string, index: number) => void;
}

function OfferForm(props: OfferFormProps): React.ReactElement {
  const { checkValid, index, disableOffers, updateOfferState, deleteOffer, refreshOfferForm } = props;

  function updateSKU(value: string | string[]) {
    props.offer.productId = setVIPMPSKUValue(value.toString());
    refreshOfferForm(value.toString(), props.index);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <p className='form-field-label'>VIP SKU (Optional)</p>
        <Select
            aria-label='VIP SKU'
            value={props.vipSku.vipSku}
            options={getVIPSKUOptions()}
            disabled= {disableOffers}
            onSelect={value => {
              updateSKU(value);
            }}
          />
      </Grid>
      <Grid item md={4} xs={12}>
        <p className='form-field-label'>Offer ID</p>
        <Textfield
          className='form-field-textfield'
          value={props.offer.productId}
          validationState={getValidationState(checkValid, props.offer.productId)}
          disabled={props.vipSku.isDisabled}
          onChange={value => {
            props.offer.productId = value;
            updateOfferState(props.offer, props.index);
          }}
        />
      </Grid>
      <Grid item md={2} xs={6}>
        <p className='form-field-label'>Quantity</p>
        <div className='form-number-input-container'>
          <NumberInput
            min={1}
            max={1000}
            value={props.offer.quantity}
            onChange={value => {
              if (value) {
                props.offer.quantity = value;
                updateOfferState(props.offer, index);
              }
            }}
          />
        </div>
      </Grid>
      <Grid item md={1} xs={12}>
        <div className='form-delete-container'>
          <Button quiet variant='primary' className='form-delete-button' onClick={event => deleteOffer(index)}>
            Delete
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default OfferForm;
