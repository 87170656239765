// Node packages
import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import { TabView, Tab } from '@react/react-spectrum/TabView';

// Custom styling and utils
import ViewAvailableOffersPage from './components/ViewAvailableOffersPage/ViewAvailableOffersPage';
import ViewAvailableCountriesPage from './components/ViewAvailableCountriesPage/ViewAvailableCountriesPage';
import ViewFileLinksPage from './components/ViewFileLinksPage/ViewFileLinksPage';
import ViewErrorCodesPage from "./components/ViewErrorCodesPage/ViewErrorCodesPage";
import ViewLanguageCodesPage from './components/ViewLanguageCodesPage/ViewLanguageCodesPage';

/**
 * OffersPage contains the page to organize and view product offers.
 */

const ResourcesTab = ['View Available Offers', 'View Available Countries', 'Partner API Files & Links', 'View Error Codes', 'View Locales'];

function ResourcesPage(): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSelectedIndex(newSelectedTabIndex);
  };

  return (
    <div>
      <Heading variant='pageTitle'>Portal Resources</Heading>
      <TabView selectedIndex={selectedIndex} onSelect={handleTabChange}>
        {ResourcesTab.map((tabItem, index) => (
          <Tab key={index} label={tabItem} />
        ))}
      </TabView>
      {selectedIndex === 0 && <ViewAvailableOffersPage />}
      {selectedIndex === 1 && <ViewAvailableCountriesPage />}
      {selectedIndex === 2 && <ViewFileLinksPage />}
      {selectedIndex === 3 && <ViewErrorCodesPage />}
      {selectedIndex === 4 && <ViewLanguageCodesPage />}

    </div>
  );
}

export default ResourcesPage;
