import {
  ResellerRecordObject,
  CustomerRecordObject,
  OrderRecordObject,
  TransferRecordObject,
  SubscriptionRecordObject
} from '../models/ResourceModels';

export const statusReasons: { [id: string]: string } = {
  '1000': 'Success',
  '1001': 'Purchased',
  '1002': 'Pending',
  '1004': 'Inactive',
  '1008': 'Cancelled',
  '1009': 'Scheduled',
  '1010': 'Invalid Address',
  '1012': 'Blocked Account',
  '1014': 'Customer Already Exists',
  '1020': 'Inactive Distributor',
  '1022': 'Inactive Reseller',
  '1024': 'Inactive Customer',
  '1026': 'Invalid Customer ID'
};

export const statusOptions: { [id: string]: { [id: string]: string[] } } = {
  resellers: {
    '1000': ['1000', '1004', '1010', '1012'],
    '1002': ['1002'],
    '1004': ['1000', '1004', '1010', '1012'],
    '1010': ['1000', '1004', '1010', '1012'],
    '1012': ['1000', '1004', '1010', '1012']
  },
  customers: {
    '1000': ['1000', '1004', '1010', '1012', '1014'],
    '1002': ['1002'],
    '1004': ['1000', '1004', '1010', '1012', '1014'],
    '1010': ['1000', '1004', '1010', '1012', '1014'],
    '1012': ['1000', '1004', '1010', '1012', '1014'],
    '1014': ['1014']
  },
  retailresellers: {
    '1000': ['1000', '1004', '1010', '1012'],
    '1002': ['1002'],
    '1004': ['1000', '1004', '1010', '1012'],
    '1010': ['1000', '1004', '1010', '1012'],
    '1012': ['1000', '1004', '1010', '1012']
  },
  retailcustomers: {
    '1000': ['1000', '1004', '1010', '1012', '1014'],
    '1002': ['1002'],
    '1004': ['1000', '1004', '1010', '1012', '1014'],
    '1010': ['1000', '1004', '1010', '1012', '1014'],
    '1012': ['1000', '1004', '1010', '1012', '1014'],
    '1014': ['1014']
  },
  subscriptions: {
    '1000': ['1000', '1004'],
    '1002': ['1002'],
    '1004': ['1004'],
    '1009': ['1009']
  },
  retailsubscriptions: {
    '1000': ['1000', '1004'],
    '1002': ['1002'],
    '1004': ['1004']
  },
  orders: {
    '1000': ['1000', '1004', '1008', '1020', '1022', '1024', '1026'],
    '1002': ['1002'],
    '1004': ['1004', '1020', '1022', '1024', '1026'],
    '1008': ['1004', '1008', '1020', '1022', '1024', '1026'],
    '1020': ['1004', '1020', '1022', '1024', '1026'],
    '1022': ['1004', '1020', '1022', '1024', '1026'],
    '1024': ['1004', '1020', '1022', '1024', '1026'],
    '1026': ['1004', '1020', '1022', '1024', '1026']
  },
  retailorders: {
    '1000': ['1000', '1004', '1008'],
    '1001': ['1001', '1004', '1008'],
    '1002': ['1002'],
    '1004': ['1004'],
    '1008': ['1004', '1008'],
    '1024': ['1004']
  },
  transfers: {
    '1000': ['1000', '1004', '1008'],
    '1002': ['1002'],
    '1004': ['1004'],
    '1008': ['1008']
  }
};

export const resellerRecordsProperties: { key: keyof ResellerRecordObject; title: string }[] = [
  { key: 'id', title: 'Reseller ID' },
  { key: 'name', title: 'Reseller Name' },
  { key: 'createdDate', title: 'Created Date' },
  { key: 'status', title: 'Status' }
];

export const customerRecordsProperties: { key: keyof CustomerRecordObject; title: string }[] = [
  { key: 'id', title: 'Customer ID' },
  { key: 'name', title: 'Customer Name' },
  { key: 'createdDate', title: 'Created Date' },
  { key: 'status', title: 'Status' }
];

export const retailcustomerRecordsProperties: { key: keyof CustomerRecordObject; title: string }[] = [
  { key: 'id', title: 'Customer ID' },
  { key: 'createdDate', title: 'Created Date' },
  { key: 'status', title: 'Status' }
];

export const orderRecordsProperties: { key: keyof OrderRecordObject; title: string }[] = [
  { key: 'id', title: 'Order ID' },
  { key: 'accountId', title: 'Customer ID' },
  { key: 'createdDate', title: 'Created Date' },
  { key: 'status', title: 'Status' },
  { key: 'orderType', title: 'Order Type' }

];

export const transferRecordsProperties: { key: keyof TransferRecordObject; title: string }[] = [
  { key: 'id', title: 'Transfer ID' },
  { key: 'membershipId', title: 'Membership ID' },
  { key: 'createdDate', title: 'Created Date' },
  { key: 'status', title: 'Status' }
];

export const subscriptionsRecordsProperties: { key: keyof SubscriptionRecordObject; title: string }[] = [
  { key: 'id', title: 'Subscription ID' },
  { key: 'createdAt', title: 'Created Date' },
  { key: 'renewalDate', title: 'Renewal Date' },
  { key: 'renewalQuantity', title: 'Renewal Quantity' },
  { key: 'autoRenewal', title: 'Auto Renewal' },
  { key: 'status', title: 'Status' },
  { key : 'deploymentId', title: 'DeploymentId'},
  { key: 'currencyCode', title: 'CurrencyCode'},
  { key: 'allowedActions', title: 'AllowedActions' },
  { key: 'renewalCode', title: 'RenewalCode'}
];

export const retailSubscriptionsRecordsProperties: { key: keyof SubscriptionRecordObject; title: string }[] = [
  { key: 'id', title: 'Subscription ID' },
  { key: 'createdAt', title: 'Created Date' },
  { key: 'renewalDate', title: 'Renewal Date' },
  { key: 'renewalQuantity', title: 'Renewal Quantity' },
  { key: 'autoRenewal', title: 'Auto Renewal' },
  { key: 'status', title: 'Status' },
  { key: 'offerId', title: 'OfferId' }
];

export const subResources: { [id: string]: string[] } = {
  resellers: ['customers'],
  customers: ['orders', 'subscriptions', 'deployments'],
  subscriptions: [],
  orders: [],
  transfers: [],
  retailorders: ['retailsubscriptions'],
  retailresellers: ['retailcustomers'],
  retailcustomers: ['orders']
};

export const resourceProperties: { [id: string]: any[] } = {
  resellers: resellerRecordsProperties,
  customers: customerRecordsProperties,
  orders: orderRecordsProperties,
  retailresellers: resellerRecordsProperties,
  retailcustomers: retailcustomerRecordsProperties,
  retailorders: orderRecordsProperties,
  transfers: transferRecordsProperties,
  subscriptions: subscriptionsRecordsProperties,
  retailsubscriptions: retailSubscriptionsRecordsProperties,
  deployments: [{ key: 'deploymentId', title: 'Deployment Id' }]
};
