// Node packages
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// Custom components
import { DetailRecordObject, RecordObject } from '../../models/ResourceModels';
import TablePaginationActions from '../../../components/TablePaginationActions';
import ExpandableRow from '../ExpandableRow/ExpandableRow';

// Custom styling and utils
import './RecordsTable.css';

/**
 * RecordsTable renders the table containing all collapsible records.
 */

interface TableProps<T> {
  selectedTab: string;
  objects: T[];
  totalCount: number;
  pageNo: number;
  rowsPerPage: number;
  properties: {
    key: keyof T;
    title: string;
  }[];
  handleViewSubresource: (selectedTab: string, searchText: string, modalData?: any, resourceId?: string) => void;
  handleRefresh: (callback: (response: DetailRecordObject) => void, response: DetailRecordObject) => void;
  refreshCurrentTab: (currentTab: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  sortingProperties: SortingProperties;
  isCoolDown: boolean;
  handleCoolDown: (isCoolDown: boolean) => void;
  countDownTimer: number;
}

interface SortingProperties {
  direction: string;
  sort_property: string;
}

// Renders the entire records table.
function RecordsTable<T extends RecordObject>(props: React.PropsWithChildren<TableProps<T>>) {

  const {
    objects,
    properties,
    totalCount,
    pageNo,
    rowsPerPage,
    handleChangePage,
    handleViewSubresource,
    refreshCurrentTab,
    selectedTab,
    handleRefresh,
    sortingProperties,
    isCoolDown,
    handleCoolDown,
    countDownTimer
  } = props;

  function getArrow(property: string) {
    if (property === 'Created Date' && (sortingProperties.sort_property === 'created_at' || sortingProperties.sort_property === 'created_date')) {
      if(sortingProperties.direction === "asc") {
        return <ArrowUpwardIcon className='table-head-icon' />
      } else if (sortingProperties.direction === "desc") {
        return <ArrowDownwardIcon className='table-head-icon' />
      } else {
        return 
      }
    } else if ((property === 'Reseller ID' || property === 'Customer ID' || property === 'Transfer ID') && sortingProperties.sort_property === 'id') {
      if(sortingProperties.direction === "asc") {
        return <ArrowUpwardIcon className='table-head-icon' />
      } else if (sortingProperties.direction === "desc") {
        return <ArrowDownwardIcon className='table-head-icon' />
      } else {
        return 
      }
    } else if (property === 'Order ID' && sortingProperties.sort_property === 'order_id') {
      if(sortingProperties.direction === "asc") {
        return <ArrowUpwardIcon className='table-head-icon' />
      } else if (sortingProperties.direction === "desc") {
        return <ArrowDownwardIcon className='table-head-icon' />
      } else {
        return 
      }
    } else {
      return
    }
    
  }

  function getColumnsPerPage() {
    if(selectedTab === 'retailcustomers') {
      return 4
    }else if(selectedTab === 'orders' || selectedTab === 'retailorders') {
      return 7
    } else {
      return 6
    }
  }

  const renderTable = () => {
    return (
      <TableContainer>
        <Table size='medium'>
          <TableHead>
            <TableRow>
              <TableCell />
              {properties.map(property => {
                  return (
                    <TableCell key={String(property.key)} className='records-table-head'>
                      {property.title} {getArrow(property.title)}
                    </TableCell>
                  );
              })}
              {selectedTab === "retailorders" ? (
                <TableCell key='activate' className='records-table-head'>
                  Activate
                </TableCell>
              ) : selectedTab !== "retailcustomers" && selectedTab !== "retailresellers" &&(
                <TableCell key='report' className='records-table-head'>
                  Get Support
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {objects.map((object, index) => (
              <ExpandableRow
                key={index}
                object={object}
                selectedTab={selectedTab}
                properties={properties}
                handleViewSubresource={handleViewSubresource}
                refreshCurrentTab={refreshCurrentTab}
                handleRefresh={handleRefresh}
                isCoolDown={isCoolDown}
                handleCoolDown={handleCoolDown}
                countDownTimer={countDownTimer}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                colSpan={getColumnsPerPage()}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={pageNo}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  };

  return <div>{objects.length === 0 ? <p>No Data</p> : renderTable()}</div>;
}

export default RecordsTable;
