import { pageSizeOptions, sortByReasons, orderByReasons } from './SelectProperties';
  
  
// For Page Number
export function getPageSizeOptionsWithReason() {
    let options = ['10', '20', '30', '50', '100']
    let pageSizeOptionsWithReason = options.map(option => {
        return {
        label: `${getPageSizeReason(option)}`,
        value: option
        };
    });
    return pageSizeOptionsWithReason;
}

function getPageSizeReason(status: string): string {
    return pageSizeOptions[String(status)];
}


// For Sort By
export function getOrderByOptionsWithReason() {
    let options = ['asc', 'desc']
    let orderByOptionsWithReason = options.map(option => {
        return {
        label: `${getOrderByReason(option)}`,
        value: option
        };
    });
    return orderByOptionsWithReason;
}

function getOrderByReason(status: string): string {
    return orderByReasons[String(status)];
}

// For Order By
export function getSortByOptionsWithReason() {

    let options = ["membership_id", "ims_org", "created_date"];//orderBy[recordType.toLowerCase()];
    let sortByOptionsWithReason = options.map(option => {
        return {
        label: `${getSortByReason(option)}`,
        value: option
        };
    });
    return sortByOptionsWithReason;
}

function getSortByReason(status: string): string {
    return sortByReasons[String(status)];
}
