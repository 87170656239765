import { IAdobeIdData } from '@identity/imslib/adobe-id/IAdobeIdData'
import { AdobeIMS } from '@identity/imslib/adobe-ims/AdobeIMS';
import { IEnvironment } from '@identity/imslib/adobe-id/IEnvironment';

export type UserProfile = {
  userId: string;
  email: string;
  displayName: string;
}

declare global {
  interface Window { 
    adobeid?: IAdobeIdData;
    adobeIMS: AdobeIMS;
  }
}

const PROD_IMS = 'https://auth.services.adobe.com/imslib/imslib.min.js';
const STAGE_IMS = 'https://auth-stg1.services.adobe.com/imslib/imslib.js';

export enum IMSEnvironment {
  STAGING,
  DEV,
  PROD
}

function currentEnvironment() {
  if (hostname === 'partnerportal-dev.adobe.com') {
    return IMSEnvironment.STAGING;
  } else if (hostname === 'partnerportal-sandbox.adobe.com') {
    return IMSEnvironment.PROD;
  } else {
    return IMSEnvironment.DEV;
  }
}

const hostname = window && window.location && window.location.hostname;
const imsEnvironment = currentEnvironment();

let userProfile: UserProfile

// list of callbacks to run when signing in (e.g., to ensure no data polution between sign-ins)
const signInCallbacks: Function[] = []

// list of callbacks to run when signing out (e.g., to ensure no data remaining after sign-out)
const signOutCallbacks: Function[] = []

let initializePromise: Promise<UserProfile> | undefined

/**
 * IMSAccountService provides access to Identity Management Services (IMS) through imslib.js.
 */
export class IMSAccountService {
  static initialize(): Promise<UserProfile> {
    if (initializePromise) {
      return initializePromise
    }

    initializePromise = new Promise((resolve, reject) => {
      window.adobeid = {
        client_id: 'partnerportal-ui',
        scope: 'openid',
        locale: 'en-US',
        environment: imsEnvironment === IMSEnvironment.PROD ? IEnvironment.PROD : IEnvironment.STAGE,
        redirect_uri: () => window.location.href,
        async onReady(_state) {
          if (!window.adobeIMS.isSignedInUser()) {
            signIn()
            return
          }

          try {
            resolve(userProfile = await window.adobeIMS.getProfile())
          } catch (error) {
            reject(error)
          }
        },
        onError(type, message) {
          reject(new Error(`${type}: ${message}`))
        },
        onAccessToken() {},
        onReauthAccessToken() {},
        onAccessTokenHasExpired: (): void => {
          signIn()
        }
      };

      // Load imslib.js.
      const imsScriptTag = document.createElement('script');
      const imsScriptTagSrc = imsEnvironment === IMSEnvironment.PROD ? PROD_IMS : STAGE_IMS;
      imsScriptTag.src = imsScriptTagSrc;
      imsScriptTag.async = true;
      document.body.appendChild(imsScriptTag);
    });

    return initializePromise
  }

  static getUserProfile() {
    return userProfile
  }

  static getAccessToken() {
    if (!window.adobeIMS) {
      throw new Error('await initialize() before getting the accessToken')
    }

    return window.adobeIMS.getAccessToken()!
  }

  static async signOut() {
    await runCallbacks(signOutCallbacks, 'signout')
    window.adobeIMS.signOut()
  }

  static onSignOut(callback: Function) {
    signOutCallbacks.push(callback)
  }

  static onSignIn(callback: Function) {
    signInCallbacks.push(callback)
  }

  static onSignInNOut(callback: Function) {
    IMSAccountService.onSignIn(callback)
    IMSAccountService.onSignOut(callback)
  }
}

async function runCallbacks(callbacks: Function[], context: string) {
  for (const cb of callbacks) {
    try {
      await cb();
    } catch (error) {
      console.warn(`failed to run callback before ${context}:`, error);
    }
  }
}

async function signIn() {
  await runCallbacks(signInCallbacks, 'signin')
  window.adobeIMS.signIn()
}