// Node packages
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Heading from '@react/react-spectrum/Heading';
import { Tab } from '@react/react-spectrum/TabView';
import { TabView } from '@react/react-spectrum/TabView';
import Select from '@react/react-spectrum/Select';
import Link from '@react/react-spectrum/Link';

// Custom components
import RecordsTable from './components/RecordsTable/RecordsTable';
import RecordsSearchBar from './components/RecordsSearchBar/RecordsSearchBar';

// Custom styling and utils
import './RecordsPage.css';
import { resourceProperties } from './service/TableProperties';
import { DetailRecordObject } from './models/ResourceModels';
import { searchWithParameters, SearchResourcesResponse } from '../providers/ResourcesAPI';
import { ErrorResponse } from '../models/ErrorResponse';
import ErrorDialog from '../../src/utils/Dialogs/ErrorDialog';
import FilterDialog from '../../src/utils/Dialogs/FilterDialog';
import { FilterParameters } from '../models/utils/FilterParameters';
import { triggerAutoRenewal, checkTriggerStatus } from '../providers/RenewalAPI';
import { getEnvironment, Environment } from '../config/EnvironmentConfig';

import { getFilterByOptionsWithReason, getOrderByOptionsWithReason, getSortByOptionsWithReason, getPageSizeOptionsWithReason } from './service/SelectService';
import DeployTosDetailDialog from '../utils/Dialogs/DeployTosDetailDialog';


/**
 * RecordsPage contains the page that displays all records.
 */

function RecordsPage() {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [isFiltering, setIsFiltering] = React.useState(false);
  let [selectOrderValue, setSelectOrderValue] = React.useState('desc');
  let [selectSortValue, setSelectSortValue] =  React.useState('created_at');
  let [selectPageSizeValue, setselectPageSizeValue] = React.useState('10');
  let [currentPage, setCurrentPageValue] = React.useState('1');
  let [selectFilterValue, setSelectFilterValue] = React.useState('id');
  let [selectOrderType, setOrderType] = React.useState('NEW,RENEWAL');
  const [isCoolDown, setIsCoolDown] = React.useState(false);
  const [displayDeployTosModal, setDisplayDeployTosModal] = React.useState(false);
  const [deploysModalData, setDeploysModalData] = React.useState(null);
  const [resourceId, setResourceId] = React.useState<string | undefined>('');
  var [countDownTimer, setCountDownTimer] = React.useState(120);


  const [searchResourcesResponse, setSearchResourcesResponse] = React.useState<SearchResourcesResponse | undefined>();
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  const [sortingProperties, setSortingProperties] = React.useState<SortingProperties>({direction: selectSortValue, sort_property:selectOrderValue});

  const tabs: string[] = ['Resellers', 'Customers', 'Orders', 'Transfers'];

  var defaultFilterParameters : FilterParameters = {
    orderNewCbx: true,
    orderRenewalCbx: true
  }

  var [filterParameters, setFilterParameters] = React.useState(defaultFilterParameters);


  const getResourceType = (tabIndex: number) => {
    return tabs[tabIndex].toLowerCase();
  };

  React.useEffect(() => {
    setSelectOrderValue('desc')
    let resourceType = getResourceType(selectedTabIndex);
    if(selectSortValue === 'created_at') {
      selectSortValue = resourceType === 'transfers' ? 'created_date' : 'created_at';
      setSelectSortValue(selectSortValue)
    }
    let queryParams = getQueryParams(resourceType, '1', '');
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    })
  }, []);

  const setView = (response: SearchResourcesResponse) => {
    if(response.httpstatus === 200) {
      checkCoolDownStatus().then(() => {
        setSearchResourcesResponse(response);
        setIsLoading(false);
        setIsError(false);
      })
    } else {
      setErrorResponse(response);
      setIsLoading(false);
      setIsError(true);
    }
  }

  const checkCoolDownStatus = () => {
    return new Promise(function(resolve, reject) {


      var environmentName;
      if (getEnvironment() === Environment.DEV) {
        environmentName = 'dev'
      } else if (getEnvironment() === Environment.PRESTAGE) {
        environmentName = 'prestage'
      } else {
        environmentName = 'dev'
      }
      var triggerAutoRenewalBody = {
        environment: environmentName
      }
      checkTriggerStatus(triggerAutoRenewalBody).then((response: any) => {

        if(response.httpstatus == 200) {
          setIsCoolDown(response.cooldown)
        } else {
          setIsCoolDown(false)
        }
        resolve(true)
      });
    });
  }

  const handleCoolDown = (isCoolDown: boolean) => {
    setIsCoolDown(isCoolDown)
    if(isCoolDown == true) {

      var environmentName;
      if (getEnvironment() === Environment.DEV) {
        environmentName = 'dev'
      } else if (getEnvironment() === Environment.PRESTAGE) {
        environmentName = 'prestage'
      } else {
        environmentName = 'dev'
      }

      var triggerAutoRenewalBody = {
        environment: environmentName
      }

      triggerAutoRenewal(triggerAutoRenewalBody).then((response: any) => {
        if(response.httpstatus == 200) {
          startCountdownTimer()
        } else {
          setIsCoolDown(false)
        }
      });
    }
    return
  };

  function startCountdownTimer() {
    var startCounting = setInterval(function(){
      countDownTimer -= 1
      if(countDownTimer <= 0){
        setIsCoolDown(false)
        countDownTimer = 120
        setCountDownTimer(120);
        clearInterval(startCounting);
      }
      setCountDownTimer(countDownTimer);
    }, 1000)
  };

  const getQueryParams = (resourceType: string, pageNo: string, searchText: string): Map<String, String> => {
    let queryParams: Map<String, String> = new Map<String, String>();
    if(selectSortValue === 'created_at') {
      selectSortValue = resourceType === 'transfers' ? 'created_date' : 'created_at';
    }
    setCurrentPageValue(pageNo)
    queryParams.set('page_no', pageNo);
    queryParams.set('size', selectPageSizeValue);
    queryParams.set('order_by', selectOrderValue);
    queryParams.set('sort_by', selectSortValue);
    // queryParams.set('id', searchText);
    if(selectFilterValue !== 'id') {
      if(resourceType === 'customers') {
        queryParams.set('association_id', searchText);
        queryParams.set('association_type', 'reseller');
        queryParams.set('type', 'customer');
        queryParams.set('id', '');

      }
      if(resourceType === 'orders'){
        queryParams.set('customer_id', searchText);
        queryParams.set('id', '');
        queryParams.set('type', selectOrderType)

      }
      if(resourceType === 'transfers') {
        queryParams.set('membership_id', searchText);
        queryParams.set('id', '');

      }
    } else {
      queryParams.delete('association_id');
      queryParams.delete('association_type');
      queryParams.set('id', searchText);
      if (resourceType === 'resellers' || resourceType === 'customers') {
        let type = resourceType.substring(0, resourceType.length - 1);
        queryParams.set('type', type);
      } else if (resourceType === 'orders') {
        queryParams.set('type', selectOrderType)
      }
    }

    return queryParams;
  };

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSelectedTabIndex(newSelectedTabIndex);
    setIsLoading(true);
    setSearchText('');
    setCurrentPageValue('1')
    setSelectFilterValue('id')
    selectFilterValue = 'id'
    let resourceType = getResourceType(newSelectedTabIndex);
    if(selectSortValue === 'created_at' || selectSortValue === 'created_date') {
      selectSortValue = resourceType === 'transfers' ? 'created_date' : 'created_at';
      setSelectSortValue(selectSortValue)
      setSortingProperties({direction: selectOrderValue, sort_property:selectSortValue})
    } else if (selectSortValue === 'id' || 'order_id') {
      selectSortValue = resourceType === 'orders' ? 'order_id' : 'id';
      setSelectSortValue(selectSortValue)
      setSortingProperties({direction: selectOrderValue, sort_property:selectSortValue})
    }
    let queryParams = getQueryParams(resourceType, '1', '');
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setIsLoading(true);
    let page = `${newPage + 1}`;
    let resourceType = getResourceType(selectedTabIndex);
    setCurrentPageValue(page)
    setSelectFilterValue('id')
    selectFilterValue = 'id'
    selectSortValue = resourceType === 'transfers' ? 'created_date' : 'created_at';
    setSelectSortValue(selectSortValue)
    let queryParams = getQueryParams(resourceType.toLowerCase(), page, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  };

  const onSearchClick = (searchText: string) => {
    setIsLoading(true);
    setSearchText(searchText);
    let resourceType = getResourceType(selectedTabIndex);
    setCurrentPageValue('1')
    let queryParams = getQueryParams(resourceType.toLowerCase(), '1', searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
      setSearchText(searchText);
    });
  };

  const handleRefresh = (callback: (response: DetailRecordObject) => void, res: DetailRecordObject) => {
    let resourceType = getResourceType(selectedTabIndex);
    let pageNo = searchResourcesResponse ? searchResourcesResponse.pageNo : 1;
    setCurrentPageValue(pageNo.toString())
    let queryParams = getQueryParams(resourceType, pageNo.toString(), searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
      callback(res);
    });
  };

  const refreshCurrentTab = (currentTab: string) => {
    refresh(currentTab, '');
  }

  const handleViewSubresource = (resourceType: string, searchText: string, modalData?: any, resourceId?: string) => {
    if (resourceType === 'deployments') {
      setResourceId(resourceId);
      setDeploysModalData(modalData);
      return
    }
    refresh(resourceType, searchText);
  };

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  // for search filter
  function updateFilter(value: string | string[]) {
    setSelectFilterValue(value.toString())
    selectFilterValue = value.toString()
  }

  // for page number
  function updatePageSize(value: string | string[]) {
    setIsLoading(true);
    setselectPageSizeValue(value.toString())
    selectPageSizeValue = value.toString()
    let resourceType = getResourceType(selectedTabIndex);
    let queryParams = getQueryParams(resourceType, currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  // for sort by
  function updateSort(value: string | string[]) {
    setIsLoading(true);
    setSelectSortValue(value.toString())
    selectSortValue = value.toString()
    setSortingProperties({direction: selectSortValue, sort_property:selectOrderValue})
    let resourceType = getResourceType(selectedTabIndex);
    let queryParams = getQueryParams(resourceType, currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  // for ordr by
  function updateOrder(value: string | string[]) {
    setIsLoading(true);
    setSelectOrderValue(value.toString())
    selectOrderValue = value.toString()
    setSortingProperties({direction: selectSortValue, sort_property:selectOrderValue})
    let resourceType = getResourceType(selectedTabIndex);
    let queryParams = getQueryParams(resourceType, currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  function openFilterDialog() {
    setIsFiltering(true);
  }

  function updateFilterParameters() {
    if(filterParameters.orderNewCbx && filterParameters.orderRenewalCbx) {
      selectOrderType = "NEW,RENEWAL"
    } else if (filterParameters.orderRenewalCbx) {
      selectOrderType = "RENEWAL"
    } else if (filterParameters.orderNewCbx)  {
      selectOrderType = "NEW"
    }
  }

  function closeFilterDialog(fp: FilterParameters) {
    setIsFiltering(false);
    filterParameters = fp
    setIsLoading(true);
    updateFilterParameters()
    let resourceType = getResourceType(selectedTabIndex);
    let queryParams = getQueryParams(resourceType, currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  function refresh(resourceType: string, searchText: string) {
    const tabValue = resourceType.charAt(0).toUpperCase() + resourceType.slice(1);
    const tabIndex = tabs.indexOf(tabValue);
    setCurrentPageValue('1')
    let queryParams = getQueryParams(resourceType, '1', searchText);
    setSelectedTabIndex(tabIndex);
    setSearchText(searchText);
    setIsLoading(true);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  function closeDeployTosDialog(shouldRefresh: boolean) {
    setDisplayDeployTosModal(!displayDeployTosModal)
    setDeploysModalData(null)
    if (shouldRefresh) {
      refresh('customers', '');
    }
  }

  interface SortingProperties {
    direction: string;
    sort_property: string;
  }

  return (
    <div>
      <Heading variant='pageTitle'>Created Records</Heading>
      <Heading variant='subtitle3'>Click on a row to see more details and edit fields</Heading>
      <TabView selectedIndex={selectedTabIndex} onSelect={handleTabChange}>
        {tabs.map((tabItem, index) => (
          <Tab key={index} label={tabItem} />
        ))}
      </TabView>
      <div className='rowC'>
      <div className='filter-by-cell'>
          <Select
            aria-label='Filter By'
            value={selectFilterValue}
            options={getFilterByOptionsWithReason(getResourceType(selectedTabIndex))}
            onSelect={value => updateFilter(value)}

          />
        </div>
      <RecordsSearchBar handleSearchClick={onSearchClick} searchText={searchText} />

        <div className='sort-by-label'>
          <b>Sorting:</b>
        </div>
        <div className='order-by-cell'>
          <Select
            aria-label='Order By'
            value={selectOrderValue}
            options={getOrderByOptionsWithReason()}
            onSelect={value => updateOrder(value)}
          />
        </div>
        <div className='sort-by-cell'>
          <Select
            aria-label='Sort By'
            value={selectSortValue}
            options={getSortByOptionsWithReason(getResourceType(selectedTabIndex))}
            onSelect={value => updateSort(value)}
          />
        </div>
        <div className='page-number-cell'>
          <Select
            aria-label='Page Number'
            value={selectPageSizeValue}
            options={getPageSizeOptionsWithReason()}
            onSelect={value => updatePageSize(value)}
          />
        </div>
        { getResourceType(selectedTabIndex) === 'orders' &&
          <div className='additional-sort-label'>
          <Link href='#' onClick={event => openFilterDialog()} className='navigation-bar-link'>
            <b>More</b>
          </Link>
          </div>
        }
      </div>
      {searchResourcesResponse && !isLoading && !isError ? (
        <RecordsTable
          objects={searchResourcesResponse.data}
          totalCount={searchResourcesResponse.totalCount}
          // Subtract 1 because table is 0 indexed
          pageNo={searchResourcesResponse.pageNo - 1}
          rowsPerPage={searchResourcesResponse.count.valueOf()}
          selectedTab={getResourceType(selectedTabIndex)}
          properties={resourceProperties[getResourceType(selectedTabIndex)]}
          handleChangePage={handleChangePage}
          handleRefresh={handleRefresh}
          handleViewSubresource={handleViewSubresource}
          refreshCurrentTab={refreshCurrentTab}
          sortingProperties={sortingProperties}
          isCoolDown={isCoolDown}
          handleCoolDown={handleCoolDown}
          countDownTimer={countDownTimer}
        />
        
      ) : [
        (errorResponse && isError ?
          <ErrorDialog
          isError={true}
          status={errorResponse.httpstatus}
          reason={errorResponse.reason}
          message={errorResponse.message}
          handleDismissErrorDialog={handleDismissErrorDialog}
          />
        :
        <CircularProgress size={20} className='loading-spinner' />
        )
      ]}
      {isFiltering &&
        <FilterDialog
        isOpen={true}
        filterParameters={filterParameters}
        handleConfirmConfirmationDialog={closeFilterDialog}
        handleDismissConfirmationDialog={closeFilterDialog}
        />
      }
      {deploysModalData &&
        <DeployTosDetailDialog
          resourceId={resourceId as string}
          modalData={deploysModalData}
          deployTosDismissDialog={closeDeployTosDialog}
          checkValid={false}
          setErrorResponse={(errorDetails: ErrorResponse) => {
            setIsError(true)
            setErrorResponse(errorDetails)
          }}
        />
      }
    </div>
  );
}

export default RecordsPage;
