import React, { useState } from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import { updateDeployTos } from '../../providers/ResourcesAPI';
import DeployToGridContainer from '../../components/DeployToGridContainer';
import { ErrorResponse } from '../../models/ErrorResponse';

interface DeployTosDetailDialog {
  modalData: any;
  resourceId: string;
  checkValid: boolean;
  deployTosDismissDialog: (refresh: boolean) => void;
  setErrorResponse: (errorDetails: ErrorResponse) => void;
}

function DeployTosDetailDialog(props: DeployTosDetailDialog) {
  const { modalData, resourceId, checkValid, deployTosDismissDialog, setErrorResponse } = props;
  const [newModalData, setNewModalData] = useState<{ [x: string]: any; }>({})

  async function handleSave() {
    const {
      deploymentId,
      status,
      companyProfile: {
        address: {
          country,
          region,
          city,
          addressLine1,
          addressLine2,
          postalCode,
          phoneNumber,
        }
      }
    } = modalData || {};

    const payload = {
      status: newModalData['status'] || status,
      companyProfile : {
        address : {
          country,
          region: newModalData['region'] || region,
          city: newModalData['city'] || city,
          addressLine1: newModalData['addressLine1'] || addressLine1,
          addressLine2 : newModalData['addressLine2'] || addressLine2,
          postalCode : newModalData['postalCode'] || postalCode,
          phoneNumber : newModalData['phoneNumber'] || phoneNumber,
        }
      }
    }

    const data = await updateDeployTos(resourceId, deploymentId, payload);

    if (data.httpstatus !== 200) {
      setErrorResponse({
        httpstatus: data.httpstatus,
        reason: data.message,
        message: data.additionalDetails[0]
      })
      deployTosDismissDialog(false);
      return;
    }

    deployTosDismissDialog(true);

  }

  return (
    <Dialog
      style={{ width: '400px' }}
      title='Deployment Details'
      open
      confirmLabel='Save'
      cancelLabel='Cancel'
      onConfirm={() => handleSave()}
      onClose={() => deployTosDismissDialog(false)}
    >
      <DeployToGridContainer
        formData={modalData}
        setNewFormData={setNewModalData}
        disabledFields={['deploymentId', 'country']}
        newFormData={newModalData}
        checkValid={checkValid}
      />
    </Dialog>
  );
}

export default DeployTosDetailDialog;
