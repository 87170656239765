import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import './ConfirmationDialog.css';

interface ConfirmationDialogProps {
    isOpen: boolean,
    title: string;
    message: string;
    variant: 'confirmation' | 'information' | 'destructive' | 'error';
    handleConfirmConfirmationDialog: () => void;
    handleDismissConfirmationDialog: () => void;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    isOpen,
    title,
    message,
    variant,
    handleConfirmConfirmationDialog,
    handleDismissConfirmationDialog
  } = props;

  return (
    <Dialog
      className='confirmation-dialog'
      title={title}
      open={isOpen}
      confirmLabel='Continue'
      cancelLabel='Cancel'
      variant={variant}
      onConfirm={event => handleConfirmConfirmationDialog()}
      onCancel={() => handleDismissConfirmationDialog()}
    >
    <div>{message}</div>

    </Dialog>
  );
}

export default ConfirmationDialog;
