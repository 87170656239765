import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Button from '@react/react-spectrum/Button';
import Select from '@react/react-spectrum/Select';
import { getContactOptionsWithReason } from '../../RecordsPage/service/SelectService';
import TextArea from '@react/react-spectrum/Textarea'
import TextField from '@react/react-spectrum/Textfield'
import { IconButton} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import ContactForm from '../../templates/form/ContactForm';
import { ContactObject } from '../../models/memberships/Contact';
import {DistributorObject} from '../../models/distributors/DistributorObject';

// Custom styling and utils
import './ModifyContactsDialog.css';
interface ModifyContactsDialogProps {
    isOpen: boolean,
    distributorObject: FullDistributorObject;
    handleDismissModifyContactsDialog: () => void;
    updateContacts: (contacts: FullDistributorObject) => void;
}

interface FullDistributorObject extends DistributorObject {
    invalid: boolean,
    isLoading: boolean,
    checkValid: boolean,
    dryRun: boolean
  }

function ModifyContactsDialog(props: ModifyContactsDialogProps) {
    const {
        isOpen,
        updateContacts,
        handleDismissModifyContactsDialog
    } = props;

    var [distributorContactObject, setDistributorContactObject] = React.useState(props.distributorObject.contacts);

    function getEmptyContactObject() {
        return {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            type: 'ADMIN'
        };
    }

    const updateContactState = (contact: ContactObject, index: number) => {
        let contacts = [...props.distributorObject.contacts];
        props.distributorObject.contacts = contacts;

        setDistributorContactObject(props.distributorObject.contacts)
        distributorContactObject = props.distributorObject.contacts
        updateContacts(props.distributorObject)
    };
    
    const handleAddContact = () => {
        let contacts = [...props.distributorObject.contacts];
        contacts.push(getEmptyContactObject());
        props.distributorObject.checkValid = false;
        props.distributorObject.contacts = contacts;

        setDistributorContactObject(props.distributorObject.contacts)
        distributorContactObject = props.distributorObject.contacts
        updateContacts(props.distributorObject)

    }; 

    const deleteContact = (index: number) => {
        let contacts = [...props.distributorObject.contacts];
        contacts.splice(index, 1);
        props.distributorObject.contacts = contacts

        setDistributorContactObject(props.distributorObject.contacts)
        distributorContactObject = props.distributorObject.contacts
        updateContacts(props.distributorObject)

        generateContactList()
        return contacts
    };

    function generateContactList() {
        return (
            props.distributorObject.contacts.map((key, index) => {
                return (
                    <ContactForm
                        key={index}
                        contact={key}
                        index={index}
                        checkValid={props.distributorObject.checkValid}
                        updateContactState={(contact, index) => updateContactState(contact, index)}
                        deleteContact={(index) => deleteContact(index)}
                    />
                )
            })
        )
    }
      
  return (
    <Dialog
      title='Add/Remove Distributor Contacts'
      open={isOpen}
      confirmLabel='Add Contact(s)'
      cancelLabel='Close'
      onConfirm={() => handleDismissModifyContactsDialog()}
      onCancel={() => handleDismissModifyContactsDialog()}
    >
    <div>
        <div>By inputting contact information, all contacts on this list will be associated with this distributor. Upon distributor creation, they will automatically get access to the Sandbox.</div>
        <br/>
        <b>Contact Information</b>
        <br/>
        {generateContactList()}
        <br/>
        <Button onClick={() => handleAddContact()}>Add</Button>
    </div>
    </Dialog>
  );
}

export default ModifyContactsDialog;