import { AddressObject } from "../../models/memberships/Address";
import { CurrencyObject, CurrencyOption } from "../../models/distributors/Currency";
import {Environment } from '../../config/EnvironmentConfig';
import { CountryObject } from "../../models/distributors/Country";

export const defaultAddressOptions: { [countryCode: string]: AddressObject } = {
    'US (NA)': {
        country: "US",
        region: "CA",
        city: "San Francisco",
        addressLine1: "3601 Lyon St",
        addressLine2: "",
        postalCode: "94123",
        phoneNumber: "",
      },
    'DE (WE)': {
        country: "DE",
        region: "SN",
        city: "Dresden",
        addressLine1: "Residenzschloss, Taschenberg 2",
        addressLine2: "",
        postalCode: "01067",
        phoneNumber: "",
      },
    'GB (WE)': {
        country: "GB",
        region: "LND",
        city: "Lutterworth",
        addressLine1: "Harrier Way",
        addressLine2: "",
        postalCode: "LE17 4XT",
        phoneNumber: "",
      },
    'JP (JP)' : {
      country: "JP",
      region: "14",
      city: "Yokohama",
      addressLine1: "8 Chome-8 Shirane",
      addressLine2: "Asahi Ward",
      postalCode: "241-0005",
      phoneNumber: ""
    },
    'SA (EE)' : {
      country: "SA",
      region: 'JED',
      city: "Jeddah",
      addressLine1: "Falastin Al-Hamra'a",
      addressLine2: "",
      postalCode: "23323",
      phoneNumber: ""
    },
    'AU (PA)' : {
      country: "AU",
      region: 'NSW',
      city: "Darlinghurst",
      addressLine1: "1 William St",
      addressLine2: "",
      postalCode: "2010",
      phoneNumber: ""
    },
    'IN (AP)' : {
      country: "IN",
      region: '11',
      city: "New Delhi",
      addressLine1: "Janpath Rd, Rajpath Area, Central Secretariat",
      addressLine2: "",
      postalCode: "110011",
      phoneNumber: ""
    },
    'MX (MX)' : {
      country: "MX",
      region: 'CMX',
      city: "Ciudad de México",
      addressLine1: "Av. Juárez S/N, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc",
      addressLine2: "",
      postalCode: "06050",
      phoneNumber: ""
    },
    'BR (LA)' : {
      country: "BR",
      region: 'RJ',
      city: "Rio de Janeiro",
      addressLine1: "Quinta da Boa Vista - São Cristóvão",
      addressLine2: "",
      postalCode: "20940-040",
      phoneNumber: ""
    },
    'Custom': {
        country: "",
        region: "",
        city: "",
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        phoneNumber: "",
      }
  };

  export const defaultPriceRegionOptions: { [countryCode: string]: CurrencyObject } = {
    'US': {
        currency: "USD",
        priceRegion: "NA",
      },
    'GB': {
        currency: "GBP",
        priceRegion: "WE",
      },
    'DE': {
        currency: "EUR",
        priceRegion: "WE",
      },
    'JP': {
        currency: 'JPY',
        priceRegion: 'JP'
    },
    'SA' : {
        currency: 'USD',
        priceRegion: 'EE'
    },
    'AU' : {
        currency: 'AUD',
        priceRegion: 'PA'
    },
    'IN' : {
      currency: 'USD',
      priceRegion: 'AP'
    },
    'MX' : {
      currency: 'USD',
      priceRegion: 'MX'
    },
    'BR' : {
      currency: 'USD',
      priceRegion: 'LA'
    },
  };

  export const priceRegionOptions: { [countryCode: string]: CurrencyOption } = {
    'NA': {
        currencies: ["USD"],
        priceRegion: "NA",
      },
    'WE': {
        currencies: ["GBP", "EUR"],
        priceRegion: "WE",
    },
    'LA': {
      currencies: ["USD"],
      priceRegion: "LA",
    },
    'JP': {
      currencies: ["JPY"],
      priceRegion: "JP",
    },
    'PA': {
      currencies: ["AUD"],
      priceRegion: "PA",
    },
    'AP': {
      currencies: ["USD"],
      priceRegion: "AP",
    },
    'MX': {
      currencies: ["USD"],
      priceRegion: "MX"
    },
    'EE': {
      currencies: ["USD"],
      priceRegion: "EE"
    }
  };

  export const currencyOptions: { [countryCode: string]: string } = {
    'USD': 'USD',
    'GBP': 'GBP',
    'EUR': 'EUR',
    'AUD': 'AUD',
    'JPY': 'JPY'
  };

  export const distributorTypeOptions: { [distributorType: string]: string } = {
    'APC': 'distributor',
    'SBP': 'sbpdistributor',
  };

  export function getCreationDestinationOptionsByEnv(env : Environment) : Record<string, string> {
    if (env === Environment.DEV) {
      return {
        'Dev (Internal Sandbox)': 'dev',
        'Stage (Internal)': 'stage'
      }
    } else if (env === Environment.PRESTAGE) {
      return {
        'Prestage (Partner Sandbox)': 'prestage'
      }
    } else {
      return {
        'Dev (Internal Sandbox)': 'dev',
        'Prestage (Partner Sandbox)': 'prestage',
        'Stage (Internal)': 'stage'
      }
    }
  }

  export function getPortalEnvironmentOptionsByEnv(env : Environment) : Record<string, string> {
    if (env === Environment.DEV) {
      return {
        'Dev (Internal Sandbox)': 'dev'
      }
    } else if (env === Environment.PRESTAGE) {
      return {
        'Prestage (Partner Sandbox)': 'prestage'
      }
    } else {
      return {
        'Dev (Internal Sandbox)': 'dev',
        'Prestage (Partner Sandbox)': 'prestage'
      }
    }
  }

  export const programTypeOptions: { [programType: string]: string } = {
    'VIPMP': 'VIPMP',
    'Retail': 'RETAIL',
  };

