import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import './Form.css';

interface ConfirmationDialogProps {
  createdMembership: boolean;
  createdMembershipId: string;
  createdMembershipEligibility: string;
  createdMembershipErrorCode: string;
  createdMembershipErrorMessage: string;
  lmGroupId: string;
  handleDismissDialog: () => void;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    createdMembership,
    createdMembershipEligibility,
    createdMembershipId,
    createdMembershipErrorCode,
    createdMembershipErrorMessage,
    lmGroupId,
    handleDismissDialog
  } = props;

  const isIneligible = createdMembershipEligibility === 'INELIGIBLE';

  return (
    <Dialog
      title='Membership Created'
      open={createdMembership}
      confirmLabel='OK'
      onConfirm={event => handleDismissDialog()}
    >
      <div>Membership ID: {createdMembershipId}</div>
      <div>Eligibility Status: {createdMembershipEligibility}</div>
      {lmGroupId && <div>Linked Membership Group ID: {lmGroupId}</div>}
      {isIneligible && (
        <div>
          <div>Ineligible Code: {createdMembershipErrorCode}</div>
          <div>Ineligible Message: {createdMembershipErrorMessage}</div>
        </div>
      )}
    </Dialog>
  );
}

export default ConfirmationDialog;
