import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';

export async function triggerAutoRenewal(body: any): Promise<any> {
  let response = await fetch(`${API_ROOT}/renewal/autorenew`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  })

  var data = {httpstatus: 500, data: []};
  data.httpstatus = response.status;
  return data;
}


export async function checkTriggerStatus(body: any): Promise<any> {
  let response = await fetch(`${API_ROOT}/renewal/check`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  })

  var data = await response.json()
  data.httpstatus = response.status;
  return data;
}