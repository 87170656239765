import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Select from '@react/react-spectrum/Select';
import { getContactOptionsWithReason } from '../../RecordsPage/service/SelectService';
import TextArea from '@react/react-spectrum/Textarea'
import TextField from '@react/react-spectrum/Textfield'
import { IconButton} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';

// Custom styling and utils
import './ContactSupportDialog.css';
interface ContactSupportDialogProps {
    isOpen: boolean,
    messageBody: string;
    handleDismissContactSupportDialog: () => void;
}

function ContactSupportDialog(props: ContactSupportDialogProps) {
  const {
    isOpen,
    messageBody,
    handleDismissContactSupportDialog
  } = props;

    // function updateContactDialog
    const [contactReason, updateContactDialog] = React.useState('vip_membership');
    let newMessageBody: string;
    let newMessageSubject: string;

    function handleCopyText() {
        navigator.clipboard.writeText(String(newMessageBody))
    };

    function handleMessageChange(value: string) {
        newMessageBody = value
    };

    function handleSubjectChange(value: string) {
        newMessageSubject = value
    };

    function setMessageBody(messageBody: string) {
        newMessageBody = messageBody
        return newMessageBody
    }

    function convertMessageBody(body: string) {
        var finalMessageBody = body.replace(/(?:\r\n|\r|\n)/g, '%0D%0A');
        return finalMessageBody
    }

    function generateSubject() {
        if (contactReason === "creation") {
            newMessageSubject = "Issue Creating Record"
        } else if (contactReason === "discrepency") {
            newMessageSubject = "Incorrect Information in Record"
        } else if (contactReason === "vip_membership") {
            newMessageSubject = "VIP Membership Issue"
        } else if (contactReason === 'transfer_failure') {
            newMessageSubject = "Transfer Failure"

        } else if (contactReason === 'order_failure') {
            newMessageSubject = "Order Failure"

        } else if (contactReason === 'api') {
            newMessageSubject = "API Error"
        } else {
            newMessageSubject = "Facing Sandbox Error"
        }  

        return newMessageSubject
    }

    function generateMailTo() {
        window.location.href = "mailto:partnerapi-integration-support@adobe.com?subject="+newMessageSubject+"&body="+convertMessageBody(newMessageBody);
    }

    function updateSelectOption(value:string) {
        updateContactDialog(value)
        generateSubject()

    }
  return (
    <Dialog
      title='Contact Support'
      open={isOpen}
      confirmLabel='Generate Email'
      cancelLabel='Close'
      onConfirm={() => generateMailTo()}
      onCancel={() => handleDismissContactSupportDialog()}
    >
        
        <b> To: </b> {"Grp-partnerapi-integration-support <partnerapi-integration-support@adobe.com>"}
        <br/>
        <br/>
        <Select
            aria-label='Contact Dalog'
            value={contactReason}
            options={getContactOptionsWithReason()}
            onSelect={value => updateSelectOption(value)}
        />
        <br/>
        <br/>
        <TextField
            value={generateSubject()}
            onChange= {value => handleSubjectChange(value)}
            className="contact-subject-dialog"
        >
        </TextField>

        <br/>
        <br/>
        <TextArea
            defaultValue={setMessageBody(messageBody)} 
            onChange={ value => handleMessageChange(value)}
            readOnly={false}
            disabled={false}
            className="contact-dialog"
        >
        </TextArea>
        <br/>
        <IconButton size='small' onClick={ () => handleCopyText()}><CopyIcon>FileCopy</CopyIcon></IconButton>
    </Dialog>
  );
}

export default ContactSupportDialog;