// Node packages
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '@react/react-spectrum/Button';
import Dialog from '@react/react-spectrum/Dialog';

// Custom components
import DetailedRecordContent from '../DetailRecordContent/DetailedRecordContent';
import { RecordObject, DetailRecordObject } from '../../models/ResourceModels';
import { ErrorResponse } from '../../../models/ErrorResponse';

// Custom styling and utils
import './ExpandableRow.css';
import * as utils from '../../service/TableService';
import EmailIcon from '@material-ui/icons/Email';
import ContactSupportDialog from '../../../utils/Dialogs/ContactSupportDialog';
import UserInfo from '../../../models/UserInfo';
import { Distributor } from '../../../providers/DistributorsAPI';
import { IMSAccountService } from '../../../service/ims/IMSAccountService';
import { getSelectedDistributor } from '../../../service/ApiService';
import { activateOrder } from '../../../providers/ResourcesAPI';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';

const useTableStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  expandCell: {
    padding: '0px 75px 0px 75px'
  }
});

interface ObjectTableProperties<T> {
  key: keyof T;
  title: string;
}

interface RowProps<T> {
  object: T;
  selectedTab: string;
  properties: ObjectTableProperties<T>[];
  handleViewSubresource: (selectedTab: string, searchText: string, modalData?: any, resourceId?: string) => void;
  handleRefresh: (callback: (response: DetailRecordObject) => void, response: DetailRecordObject) => void;
  refreshCurrentTab: (currentTab: string) => void;
  isCoolDown: boolean;
  countDownTimer: number;
  handleCoolDown: (isCoolDown: boolean) => void;
}

// Renders the rows of the table.
function ExpandableRow<T extends RecordObject>(props: RowProps<T>) {
  const classes = useTableStyles();
  const { object, properties, selectedTab, handleRefresh, handleViewSubresource, isCoolDown, handleCoolDown, countDownTimer, refreshCurrentTab } = props;
  const [open, setOpen] = React.useState(false);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [showActivationDialog, setShowActivationDialog] = React.useState(false);
  const [isLoadingActivation, setIsLoadingActivation] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();

  const handleActivateOrder = () => {
    const { distributorId, imsOrgId } = getSelectedDistributor() || {}

    setIsLoadingActivation(true);
    activateOrder(object.id).then((response: DetailRecordObject) => {
      handleRefresh(updateContent, response);
    });
  }

  const updateContent = (response: DetailRecordObject) => {
    setIsLoadingActivation(false);
    setOpen(false)
    setView(response);
  };

  const setView = (response: DetailRecordObject) => {
    if (response.httpstatus === 200) {
      setIsError(false)
      setShowActivationDialog(true)
    } else {
      setIsError(true)
      setErrorResponse(response);
    }
  }

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  function generateEmailLink() {
    setOpenEmailDialog(true)
  }

  function handleDismissContactSupportDialog() {
    setOpenEmailDialog(false)
  }

  // selectOrderValue = resourceType === 'transfers' ? 'created_date' : 'created_at';

  function getColumnsPerPage() {
    if(selectedTab === 'orders' || selectedTab === 'retailorders') {
      return 7
    } else {
      return 6
    }
  }

  function generateMessageBody() {
    let id = object.id === undefined ? 'N/A' : object.id
    let status = object.status === undefined ? 'N/A' : object.status
    let createdDate = object.createdDate === undefined ? 'N/A' : object.createdDate
    let membershipId = object.membershipId === undefined ? 'N/A' : object.membershipId
    let accountId = object.accountId === undefined ? 'N/A' : object.accountId

    var specificID = ""
    var resource = ""
    if(selectedTab === "customers") {
      resource = "customer"

    } else if (selectedTab === "orders") {
      specificID =  "\nCustomer ID: " + accountId
      resource = "order"

    } else if (selectedTab === "resellers") {
      resource = "reseller"
      
    } else if (selectedTab === "transfers") {
      specificID = + "\nMembership ID: " + membershipId
      resource = "transfer"
    }

    return "There was an issue with the " + resource + " record containing the ID: " + id +
      "\n\nAdditional Details:\nObject Status: " + status +
       "\nCreated Date: " + createdDate + specificID + "\n\nAssociated Email: " + IMSAccountService.getUserProfile().email + "\nSelected Distributor ID: " +
      getSelectedDistributor().distributorId + "\nSelected Distributor Name: " +
      getSelectedDistributor().distributorName + "\nSelected Distributor Org ID: " +
      getSelectedDistributor().imsOrgId 

  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        {properties.map(property => {
          if (String(property.key) === 'status') {
            return (
              <TableCell key={String(property.key)} align='center'>
                <div className={`resource-status-${utils.getRecordStatus(object['status'])}`}>
                  {object[property.key]}
                </div>
              </TableCell>
            );
          } else if (String(property.key) === 'id') {
            return (
              <TableCell key={String(property.key)} align='center'>
                <div
                  className='copy-id-tooltip'
                  onClick={() => {
                    navigator.clipboard.writeText(String(object[property.key]));
                  }}
                >
                  {object[property.key]}
                  <span className='copy-id-tooltiptext'>Copy</span>
                </div>
              </TableCell>
            );
          } else {
            // 
            return <TableCell key={String(property.key)} align='center'>{object[property.key]}</TableCell>;
          }
        })}
        
        {selectedTab !== "retailresellers" && selectedTab !== "retailcustomers" && <TableCell key='report' align='center'>
          {selectedTab === "retailorders" ?
            (isLoadingActivation ?
              (<CircularProgress size={20} />
              ) : [
                (errorResponse && isError ?
                  <ErrorDialog
                    isError={true}
                    status={errorResponse.httpstatus}
                    reason={errorResponse.reason}
                    message={errorResponse.message}
                    handleDismissErrorDialog={handleDismissErrorDialog}
                  />
                  :
                  object['internalStatus'] == 'SUCCESS' && <Button variant='action' onClick={event => handleActivateOrder()}>Activate</Button>
                )
              ]
            ) : (
              <IconButton size='small' onClick={() => generateEmailLink()}> <EmailIcon className='table-icon' />
              </IconButton>
            )
          }
        </TableCell>
        }
      </TableRow>
      <TableRow>
        <TableCell className={classes.expandCell} colSpan={getColumnsPerPage()}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <DetailedRecordContent
              data={object}
              selectedTab={selectedTab}
              handleRefresh={handleRefresh}
              handleViewSubresource={handleViewSubresource}
              refreshCurrentTab={refreshCurrentTab}
              isCoolDown={isCoolDown}
              handleCoolDown={handleCoolDown}
              countDownTimer={countDownTimer}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      {openEmailDialog &&
          <ContactSupportDialog
          isOpen={true}
          messageBody={generateMessageBody()}
          handleDismissContactSupportDialog={handleDismissContactSupportDialog}
          />
      }
      {
        <Dialog
          title='Activation Confirmation'
          open={showActivationDialog}
          confirmLabel='OK'
          onConfirm={event => setShowActivationDialog(false)}
        >
        {'In Production the activation will be done by the customer via Partners Portal.'}
        </Dialog>
      }
      
    </React.Fragment>
  );
}

export default ExpandableRow;
