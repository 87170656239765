// Node packages
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Custom components
import MembershipOfferContent from '../MembershipOfferContent/MembershipOfferContent';
import { CreatedMembershipObject } from '../../models/MembershipModels';

// Custom styling and utils
import './ExpandableRow.css';
import ClearIcon from '@material-ui/icons/Clear';

const useTableStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  expandCell: {
    padding: '0px 75px 0px 75px'
  }
});

interface ObjectTableProperties<T> {
  key: keyof T;
  title: string;
}

interface RowProps<T> {
  object: T;
  properties: ObjectTableProperties<T>[];
  deleteMembership: (membershipId: string) => void;
}

// Renders the rows of the table.
function ExpandableRow<T extends CreatedMembershipObject>(props: RowProps<T>) {
  const classes = useTableStyles();
  const { object, properties, deleteMembership } = props; 
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        {properties.map(property => {
          if (String(property.key) === 'id') {
            return (
              <TableCell key={String(property.key)} align='center'>
                <div
                  className='copy-id-tooltip'
                  onClick={() => {
                    navigator.clipboard.writeText(String(object[property.key]));
                  }}
                >
                  {object[property.key]}
                  <span className='copy-id-tooltiptext'>Copy</span>
                </div>
              </TableCell>
            );
          } else {
            return <TableCell key={String(property.key)} align='center'>{object[property.key]}</TableCell>;
          }
        })}
        <TableCell key='delete-row'>
            <IconButton size='small' color="secondary" onClick={() => deleteMembership(object.membershipId)}>
            <ClearIcon /> 
            </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.expandCell} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <MembershipOfferContent
              data={object.offers}
              eligibilityStatus={object.eligibilityStatus}
              eligibilityMessage={object.ineligibleMessage}
              eligibilityCode={object.ineligibleCode}
              threeYCInfo={object.commitment}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ExpandableRow;
