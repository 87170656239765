import React from 'react';
import RegExGen from 'randexp'
// UI library components
import TextField from '@react/react-spectrum/Textfield';
import { Grid } from '@material-ui/core';
import Select from '@react/react-spectrum/Select';

// Custom styles and utils
import './Form.css';
import { IAddressObject } from '../../models/memberships/Address';
import { getValidationState, getPostalCodeValidationState } from '../../utils/FormUtils';
import { CountryObject } from '../../models/distributors/Country';
import { getCountriesInPriceRegion, getRegionsInCountry } from '../../InternalPage/service/SelectService';

import { emitKeypressEvents } from 'readline';

interface AddressFormProps{
  address: IAddressObject;
  checkValid: boolean;
  isDefault: boolean;
  isCountriesDisabled: boolean;
  countries: CountryObject[];
  regions: string[];
  postalCodeRegex: string;
  updateAddressState: (address: IAddressObject) => void;
  updateValidAddress: (invalidAddress: boolean) => void;
}

function AddressForm(props: AddressFormProps): React.ReactElement {
  const { checkValid, updateAddressState, updateValidAddress } = props;
  let [selectCountry, setSelectCountry] = React.useState("");
  let [selectRegion, setSelectRegion] = React.useState("");
  let [invalidAddress, setValidAddress] = React.useState(false);

  
  const updateCountry = (country: string | string[]) => {
    setSelectCountry(country.toString())
    props.address.country = country.toString();
    updateAddressState(props.address);
    updateValidAddress(checkIfValidAddress())
  };

  const updateRegion = (region: string | string[]) => {
    setSelectRegion(region.toString())
    props.address.region = region.toString();
    updateAddressState(props.address);
    updateValidAddress(checkIfValidAddress())
  };

  const checkIfCountryDisabled = () => {
    if(props.isDefault === true) {
      updateValidAddress(false)
      return true
    } else if (props.isCountriesDisabled === true) {
      updateValidAddress(checkIfValidAddress())
      return true
    } else {
      updateValidAddress(checkIfValidAddress())
      return false
    }
  }

  const checkIfRegionDisabled = () => {
    if(props.isDefault === true) {
      updateValidAddress(false)
      return true
    } else if (selectCountry === "" || props.address.country === "") {
      updateValidAddress(checkIfValidAddress())
      return true
    } else {
      updateValidAddress(checkIfValidAddress())
      return false
    }
  }

  function returnPostalCode() {
    if(checkIfRegionDisabled() || props.postalCodeRegex === '') {
      return "Enter Postal Code"
    } else {
      return "Ex. " + new RegExGen(new RegExp(props.postalCodeRegex)).gen()
    }
  }

  function checkIfValidAddress() {
    if (props.address.addressLine1.trim() === '' || props.address.city.trim() === '' || props.address.country === '' || props.address.region === '') {
      invalidAddress = true
    } else if(getPostalCodeValidationState(true, props.address.postalCode, new RegExp(props.postalCodeRegex)) !== undefined) {
      invalidAddress = true
    } else if (props.isDefault === true) {
      invalidAddress = true
    } else {
      invalidAddress = false
    }
    return invalidAddress
  }

  return (
    <Grid container spacing={3} >
      <Grid item md={3}>
          <br />
          <b>Address</b>
          <br/>
          Country (2-char ISO)
          <br />
          <Select
              aria-label='Country'
              value={props.address.country}
              defaultValue={props.address.country}
              disabled={checkIfCountryDisabled()}
              options={getCountriesInPriceRegion(props.countries)}
              onSelect={(value) => updateCountry(value)}
          />
          <br />
      </Grid>
      <Grid item md={5}>
          <br/>
          <br/>
          Address Line 1
          <br />
          <TextField
            key={props.address.country}
            className='form-field-textfield'
            disabled={props.isDefault}
            validationState={getValidationState(checkValid, props.address.addressLine1)}
            defaultValue={props.address.addressLine1}
            placeholder="Enter Address Line 1"
            onChange={value => {
              props.address.addressLine1 = value.trim();
              updateValidAddress(checkIfValidAddress())
              updateAddressState(props.address);
            }}
          />
          <br />
      </Grid>
      <Grid item md={4}>
          <br/>
          <br />
          Address Line 2
          <br />
          <TextField
            key={props.address.country}
            className='form-field-textfield'
            disabled={props.isDefault}
            placeholder="Enter Address Line 2"
            defaultValue={props.address.addressLine2}
            onChange={value => {
              props.address.addressLine2 = value.trim();
              updateValidAddress(checkIfValidAddress())
              updateAddressState(props.address);
            }}
          />
          <br />
      </Grid>
      <Grid item md={4}>
          City
          <br />
          <TextField
            key={props.address.country}
            disabled={props.isDefault}
            validationState={getValidationState(checkValid, props.address.city)}
            defaultValue={props.address.city}
            className='form-field-textfield'
            placeholder="Enter City"
            onChange={value => {
              props.address.city = value.trim();
              updateValidAddress(checkIfValidAddress())
              updateAddressState(props.address);
            }}
          />
          <br />
      </Grid>
      <Grid item md={3}>
          Region (State/Province)
          <br />
          <Select
              aria-label='Region'
              value={props.address.region}
              defaultValue={props.address.region}
              disabled={checkIfRegionDisabled()}
              options={getRegionsInCountry(props.regions)}
              onSelect={(value) => updateRegion(value)}
          />
          <br />
      </Grid>
      <Grid item md={5}>
          Postal Code (must be valid for country)
          <br />
          <TextField
            key={props.address.country}
            className='form-field-textfield'
            disabled={props.isDefault}
            validationState={getPostalCodeValidationState(true, props.address.postalCode, new RegExp(props.postalCodeRegex))}
            defaultValue={props.address.postalCode}
            placeholder={returnPostalCode()} 
            onChange={value => {
              props.address.postalCode = value.trim();
              updateValidAddress(checkIfValidAddress())
              updateAddressState(props.address);
            }}
          />
          <br />
      </Grid>

    </Grid>
  );
}

export default AddressForm;
