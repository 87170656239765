import React from 'react';

// UI libary components
import Heading from '@react/react-spectrum/Heading';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// Custom styling and utils
import './ViewErrorCodesPage.css';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';

import {getAvailableErrorCodes} from "../../../providers/ErrorCodesAPI";
import {AvailableErrorCodesObject, AvailableErrorCodesResponse} from "../../../models/errors/ErrorCodes";

function ViewErrorCodesPage() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [allErrorCodes, setAllErrorCodes] = React.useState<AvailableErrorCodesObject[]>([]);
    const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
    const availableErrorCodesProperties: { key: keyof AvailableErrorCodesObject; title: string }[] = [
        { key: 'code', title: 'Error Codes' },
        { key: 'message', title: 'Message' },
        { key: 'httpStatus', title: 'HTTP Status' }
    ];

    React.useEffect(() => {
        getAvailableErrorCodes().then((availableErrorCodesResponse: AvailableErrorCodesResponse) => {
            if(availableErrorCodesResponse.httpstatus === 200) {
                setIsLoading(false);
                setIsError(false);
                setAllErrorCodes(availableErrorCodesResponse.data);
            } else {
                setIsError(true);
                setIsLoading(false);
                setErrorResponse(availableErrorCodesResponse);
            }
        });
    }, []);

    const handleDismissErrorDialog = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <div>
            <div className='offers-heading-container'>
                <Heading variant='subtitle1'>Error Codes</Heading>
            </div>
            <Heading variant='subtitle3'>View all available error codes, messages, and HTTP Statuses.</Heading>
            <br />
            {isLoading ? (
                <CircularProgress size={20} className='offers-table-loading-spinner' />
            ) : [

                (errorResponse && isError ?
                    <ErrorDialog
                        isError={true}
                        status={errorResponse.httpstatus}
                        reason={errorResponse.reason}
                        message={errorResponse.message}
                        handleDismissErrorDialog={handleDismissErrorDialog}
                    />
                    :
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    {availableErrorCodesProperties.map(property => (
                                        <TableCell key={String(property.key)} className='offers-table-head'>
                                            {property.title}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allErrorCodes.map((errorCode, index) => (
                                    <TableRow key={index}>
                                        {availableErrorCodesProperties.map(property => {
                                            return <TableCell key={String(property.key)}>{errorCode[property.key]}</TableCell>;
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            ]}
        </div>
    );
}

export default ViewErrorCodesPage;
