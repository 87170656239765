import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';
import { DistributorRequest } from '../models/distributors/DistributorRequest';

export async function getAllCountries(): Promise<any> {
  let response = await fetch(`${API_ROOT}/countries/all`, {
    headers: generateRequestHeaders(),
    method: 'GET'
  });

  var data = {httpstatus: 500, data: []};
  data.data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function searchCountry(countryCode: string): Promise<any> {
  let response = await fetch(`${API_ROOT}/countries/${countryCode}`, {
    headers: generateRequestHeaders(),
    method: 'GET'
  });

  var data = {httpstatus: 500, data: []};
  data.data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function getCountriesForDistributor(): Promise<any> {
  let response = await fetch(`${API_ROOT}/countries/distributor`, {
    headers: generateRequestHeaders(),
    method: 'GET'
  });

  var data = {httpstatus: 500, data: []};
  data.data = await response.json();
  data.httpstatus = response.status;
  return data;
}