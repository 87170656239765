import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';
import { MembershipObject } from '../models/memberships/Membership';

export async function getInvalidMemberships(): Promise<any> {
  let endpoint = `${API_ROOT}/memberships/invalid`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function createInvalidMembership(body: MembershipObject): Promise<any> {
  let endpoint = `${API_ROOT}/memberships/create`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });
  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function deleteMembershipById(
  membership_id: string,
): Promise<any> {
  let endpoint = `${API_ROOT}/memberships/${membership_id}`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'DELETE'
  });
  let result = await response.json();
  var data: any = {httpstatus: 500, completed: false};
  data.httpstatus = response.status;
  data.completed = result
  return data;
}