export function getValidationState(shouldCheckValue: boolean, value: string = ''): 'invalid' | 'valid' | undefined {
  let stringInvalid = value.trim() === '';
  return shouldCheckValue && stringInvalid ? 'invalid' : undefined;
}

export function getPostalCodeValidationState(shouldCheckValue: boolean, value: string, regex: RegExp): 'invalid' | 'valid' | undefined {
  let stringInvalid = value.trim() === '';
  let validCode = false

  validCode = validatePostalCode(value.trim(), regex)

  
  if(shouldCheckValue && stringInvalid) {
    return 'invalid'
  } else if(shouldCheckValue && validCode === false) {
    return 'invalid'
  } else {
    return undefined
  }
}

function validatePostalCode(code: string, regex: RegExp) {

  if (regex.test(code)){
    return (true)
  }
  return (false)
}

export function validateEmail(mail: string) {
  if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(mail)){
    return (true)
  }
  return (false)
}