export const priceRegionNames: { [code: string]: string } = {
    'AP': 'Asia Pacific (AP)',
    'EE': 'Eastern Europe (EE)',
    'JP': 'Japan (JP)',
    'LA': 'Latin America (LA)',
    'MX': 'Mexico (MX)',
    'NA': 'North America (NA)',
    'PA': 'Pacific (PA)',
    'WE': 'Western Europe (WE)'
};

export const marketSegmentNames: { [code: string]: string } = {
    'COM': 'Commercial (COM)',
    'EDU': 'Education (EDU)',
    'GOV': 'Government (GOV)'
};

export const currencies: { [code: string]: string } = {
    "USD" : "USD",
    "EUR" : "EUR",
    "GBP" : "GBP",
    "AUD" : "AUD",
    "JPY" : "JPY"
}
