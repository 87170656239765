import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import './InformationDialog.css';

interface InformationDialogProps {
    isOpen: boolean,
    title: string;
    message: string;
    className?: string;
    variant: 'confirmation' | 'information' | 'destructive' | 'error';
    handleConfirmConfirmationDialog: () => void;
}

function InformationDialog(props: InformationDialogProps) {
  const {
    isOpen,
    title,
    message,
    variant,
    className,
    handleConfirmConfirmationDialog,
  } = props;

  return (
    <Dialog
      className='information-dialog'
      title={title}
      open={isOpen}
      confirmLabel='OK'
      variant={variant}
      onConfirm={event => handleConfirmConfirmationDialog()}
    >
      <div className={className}>{message}</div>
    </Dialog>
  );
}

export default InformationDialog;
