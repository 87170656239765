import React from 'react';

// UI libary components
import Heading from '@react/react-spectrum/Heading';
import Link from '@react/react-spectrum/Link';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

function ViewFileLinksPage() {

  return (
    <div>
      <div className='etc-heading-container'>
        <Heading variant='subtitle1'>Links and Resources</Heading>
      </div>
      <Heading variant='subtitle3'>Links and resources related to the Partner API.</Heading>
      <br />
        <div>
            <TableContainer>
            <Table size='small'>
                <TableHead>
                <TableRow>
                        <TableCell>
                            Link
                        </TableCell>
                        <TableCell >
                            Description
                        </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell>
                        <Link href='https://adobe.sharepoint.com/sites/VIPMarketplacePartners'>
                            Partner Hub
                        </Link>
                    </TableCell>
                    <TableCell>
                        A secure location for Adobe to share confidential materials with VIP Marketplace partners.
                        ​​​​​​​All materials on this site are provided pursuant to your confidentiality obligations to Adobe.
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Link href='https://adobe.sharepoint.com/sites/VIPMarketplacePartners/SitePages/API-Materials.aspx'>
                            Partner API Materials
                        </Link>  
                    </TableCell>
                    <TableCell>
                        Location where the Partner API documentation, technical assets, and Sample Postman Environemnt lives.
                    </TableCell>
                </TableRow>            
                </TableBody>
            </Table>
            </TableContainer>
        </div>
    </div>
  );
}

export default ViewFileLinksPage;
