import { pageSizeOptions, sortByReasons, sortBy, orderByReasons, filterOptions, contactReasons, vipSKUOptions, vipmpSKUOptions} from './SelectProperties';
  
  
// For Page Number
export function getPageSizeOptionsWithReason() {
    let options = ['10', '20', '30', '50', '100']
    let pageSizeOptionsWithReason = options.map(option => {
        return {
        label: `${getPageSizeReason(option)}`,
        value: option
        };
    });
    return pageSizeOptionsWithReason;
}

function getPageSizeReason(status: string): string {
    return pageSizeOptions[String(status)];
}


// For Sort By
export function getOrderByOptionsWithReason() {
    let options = ['asc', 'desc'] 
    let sortByOptionsWithReason = options.map(option => {
        return {
        label: `${getOrderByReason(option)}`,
        value: option
        };
    });
    return sortByOptionsWithReason;


}

function getOrderByReason(status: string): string {
    return orderByReasons[String(status)];
}

// For Order By
export function getSortByOptionsWithReason(recordType: string) {
    let options = sortBy[recordType.toLowerCase()];
    let sortByOptionsWithReason = options.map(option => {
        return {
        label: `${getSortByReason(option)}`,
        value: option
        };
    });
    return sortByOptionsWithReason;

}

function getSortByReason(status: string): string {
    return sortByReasons[String(status)];
}

// For Search Filter
export function getFilterByOptionsWithReason(recordType: string) {
    let options = ['id']
    if(recordType === 'orders') {
        options = ['id', 'customer_id']

    } else if (recordType === 'transfers') {
        options = ['id', 'membership_id']

    } else if (recordType === 'resellers') {
        options = ['id']

    } else if (recordType === 'customers') {
        options = ['id', 'reseller_id']
    }
    let filterOptionsWithReason = options.map(option => {
        var preIDLabel = "" 
        var label = `${getFilterReason(option)}`
        if(recordType.toLowerCase() === "resellers" && label === 'ID') {
            preIDLabel = "Reseller "
        } else if (recordType.toLowerCase() === "customers" && label === 'ID') {
            preIDLabel = "Customer "
        } else if (recordType.toLowerCase() === "orders" && label === 'ID') {
            preIDLabel = "Order  "
        } else if (recordType.toLowerCase() === "transfers" && label === 'ID') {
            preIDLabel = "Transfer  "
        }
        return {
        label: preIDLabel+label,
        value: option
        };
    });
    return filterOptionsWithReason;
}

function getFilterReason(status: string): string {
    return filterOptions[String(status)];
}

// For Contact
export function getContactOptionsWithReason() {

    let options = ['vip_membership','creation', 'discrepency', 'transfer_failure', 'order_failure', 'api', 'other'];
    let contactOptionsWithReason = options.map(option => {
        return {
        label: `${getContactReason(option)}`,
        value: option
        };
    });
    return contactOptionsWithReason;
}

function getContactReason(status: string): string {
    return contactReasons[String(status)];
}

// For SKUs
export function getVIPSKUOptions() {

    let options = Object.keys(vipSKUOptions);//['none', '65324055BA01A12', '65291026BA01B12', '65304043BA01B12', '65304040BA01B12'];
    let skuOptions = options.map(option => {
        return {
        label: `${getVIPSKUs(option)}`,
        value: option
        };
    });
    return skuOptions;
}

function getVIPSKUs(status: string): string {
    return vipSKUOptions[String(status)];
}


export function setVIPMPSKUValue(status: string): string {
    return vipmpSKUOptions[String(status)];
}



