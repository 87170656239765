export const orderByReasons: { [id: string]: string } = {
    'asc': 'Ascending',
    'desc': 'Descending'
  };

export const sortByReasons: { [id: string]: string } = {
    "membership_id": "Membership ID", 
    "ims_org": "Company Name",
    "created_date": "Creation Date"
  };

  export const pageSizeOptions: { [id: string]: string } = {
    '10': '10',
    '20': '20',
    '30': '30',
    '50': '50',
    '100': '100'
  };

