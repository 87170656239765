import React from 'react';

// UI library components
import Textfield from '@react/react-spectrum/Textfield';
import { Grid } from '@material-ui/core';

// Custom styles and utils
import './Form.css';
import { IAddressObject } from '../../../models/memberships/Address';
import { getValidationState } from '../../../utils/FormUtils';

interface AddressFormProps {
  address: IAddressObject;
  checkValid: boolean;
  updateAddressState: (address: IAddressObject) => void;
}

function AddressForm(props: AddressFormProps): React.ReactElement {
  const { checkValid, updateAddressState } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={5} xs={12}>
        <p className='form-field-label'>Address Line 1</p>
        <Textfield
          className='form-field-textfield'
          value={props.address.addressLine1}
          validationState={getValidationState(checkValid, props.address.addressLine1)}
          onChange={value => {
            props.address.addressLine1 = value;
            updateAddressState(props.address);
          }}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <p className='form-field-label'>Address Line 2 (optional)</p>
        <Textfield
          className='form-field-textfield'
          value={props.address.addressLine2}
          onChange={value => {
            props.address.addressLine2 = value;
            updateAddressState(props.address);
          }}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <p className='form-field-label'>Country (2-digit ISO Code)</p>
        <Textfield
          className='form-field-textfield'
          value={props.address.country}
          validationState={getValidationState(checkValid, props.address.country)}
          onChange={value => {
            props.address.country = value;
            updateAddressState(props.address);
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>Region (ISO 3166-2 Subdivision Code)</p>
        <Textfield
          className='form-field-textfield'
          value={props.address.region}
          validationState={getValidationState(checkValid, props.address.region)}
          onChange={value => {
            props.address.region = value;
            props.updateAddressState(props.address);
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>City</p>
        <Textfield
          value={props.address.city}
          validationState={getValidationState(checkValid, props.address.city)}
          className='form-field-textfield'
          onChange={value => {
            props.address.city = value;
            props.updateAddressState(props.address);
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>Postal Code (must be valid for country)</p>
        <Textfield
          className='form-field-textfield'
          value={props.address.postalCode}
          validationState={getValidationState(checkValid, props.address.postalCode)}
          onChange={value => {
            props.address.postalCode = value;
            props.updateAddressState(props.address);
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>Phone Number (optional)</p>
        <Textfield
          className='form-field-textfield'
          value={props.address.phoneNumber}
          onChange={value => {
            props.address.phoneNumber = value;
            props.updateAddressState(props.address);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AddressForm;
