import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import './ErrorDialog.css';

interface ConfirmationDialogProps {
    isError: boolean,
    status?: number;
    message?: string;
    reason?: string;
    handleDismissErrorDialog: () => void;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    isError,
    status,
    message,
    reason,
    handleDismissErrorDialog
  } = props;

  return (
    <Dialog
      className='error-dialog'
      title='An Error Occured'
      open={isError}
      confirmLabel='OK'
      onConfirm={event => handleDismissErrorDialog()}
    >
      { (status) ? <div>Status Code: {status}</div>
                : null }
      { (message) ? <div>Message: {message}</div>
                : null }
      { (reason) ? <div>Reason: {reason}</div>
          : null }
      
    </Dialog>
  );
}

export default ConfirmationDialog;
