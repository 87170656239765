// Node packages
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableRow, CircularProgress } from '@material-ui/core';
import { getStatusOptionsWithReason } from '../../service/TableService';
import { updateSubscriptionResource } from '../../../providers/ResourcesAPI';
import MuiTableCell from '@material-ui/core/TableCell';

// Custom styling and utils
import './SubscriptionRow.css';
import * as utils from '../../service/TableService';
import Button from '@react/react-spectrum/Button';
import Select from '@react/react-spectrum/Select';
import NumberInput from '@react/react-spectrum/NumberInput';

import { RecordObject } from '../../models/ResourceModels';

import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../../src/utils/Dialogs/ErrorDialog';
import Datepicker from '@react/react-spectrum/Datepicker';
import {isHighPenFlagDevEnabled, isHighPenFlagEnabled} from "../../../config/FeatureFlags";

import {
  isAllowedActionsFlagDevEnabled,
  isAllowedActionsFlagEnabled,
} from "../../../config/FeatureFlags";

const CondensedTableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);

const useTableStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

interface RowProps<T> {
  object: T;
  subresourceType: string;
  properties: {
    key: keyof T;
    title: string;
  }[];
  handleViewSubresource: (subresourceType: string, searchText: string) => void;
}

// Renders the rows of the table.
function SubscriptionRow<T extends RecordObject>(props: RowProps<T>) {
  const classes = useTableStyles();
  const { object, properties, subresourceType } = props;
  const [subscriptionObject, setSubscriptionObject] = React.useState(object);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [status, setStatus] = React.useState(object['status']);
  const [autoRenewal, setAutoRenewal] = React.useState(object['autoRenewal']);
  const [renewalQuantity, setRenewalQuantity] = React.useState(object['renewalQuantity']);
  const [renewalDate, setRenewalDate] = React.useState("renewalDate");
  const statusOptions = getStatusOptionsWithReason(subresourceType, status);
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();

  const handleUpdateSubscription = () => {
    const subscriptionId = subscriptionObject['id'];
    setIsEditing(false);
    setIsSaving(true);
    updateSubscriptionResource(subscriptionId, status, renewalQuantity, autoRenewal, object['accountId'], renewalDate).then(response => {
      if(response.httpstatus == 200) {
        setSubscriptionObject(response);
        setIsSaving(false);
        setIsError(false);
      } else {
        setErrorResponse(response);
        setIsError(true);
        setIsSaving(false);
      }
    });
  };

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {properties.map(property =>
          String(property.key) === 'status' ? (
            <CondensedTableCell key={String(property.key)} align='center'>
              <div className={`subscription-status-${utils.getRecordStatus(subscriptionObject['status'])}`}>
                {subscriptionObject[property.key]}
              </div>
            </CondensedTableCell>
          ) : String(property.key) === 'renewalCode' ? (
              (isHighPenFlagEnabled() || isHighPenFlagDevEnabled()) ? (
                  <CondensedTableCell key={String(property.key)}>
                    {subscriptionObject[property.key]}
                  </CondensedTableCell>
              ): null
          ) : (
          String(property.key) === 'allowedActions' ? (
              (isAllowedActionsFlagEnabled() || isAllowedActionsFlagDevEnabled()) ? (
                 <CondensedTableCell key={String(property.key)}>
                   {Array.isArray(subscriptionObject[property.key])
                     ? (subscriptionObject[property.key] as string[]).join(', ')
                     : subscriptionObject[property.key]
                   }
                  </CondensedTableCell>
               ) : null
           ) : (
            <CondensedTableCell key={String(property.key)}>
              {String(property.key) === 'createdAt' || String(property.key) === 'renewalDate'
                ? String(subscriptionObject[property.key]).split('T')[0]
                : subscriptionObject[property.key]}
            </CondensedTableCell>
          )
         )
        )}
        <CondensedTableCell>
          {isSaving ? (
            <CircularProgress size={20} />
          ) : [ 
            (errorResponse && isError ?
              <ErrorDialog
              isError={true}
              status={errorResponse.httpstatus}
              reason={errorResponse.reason}
              message={errorResponse.message}
              handleDismissErrorDialog={handleDismissErrorDialog}
              />
            :
            <Button variant='action' onClick={event => setIsEditing(!isEditing)}>
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
            )]
          }
        </CondensedTableCell>
      </TableRow>
      {isEditing && (
        <TableRow className={classes.root}>
          {properties.map(property => {
            if (String(property.key) === 'status') {
              return (
                <CondensedTableCell key={String(property.key)}>
                  <Select
                    className='subscription-status-select'
                    aria-label='Status Code'
                    value={String(status)}
                    options={statusOptions}
                    onSelect={value => setStatus(value)}
                  />
                </CondensedTableCell>
              );
            } else if (String(property.key) === 'renewalDate' && subresourceType === 'retailsubscriptions') {
              // Enable editing renewal date in case of retail order subscription
              return (
                <CondensedTableCell key={String(property.key)}>
                  <Datepicker
                    value={renewalDate}
                    onChange={value => {
                      if (typeof value === 'string') {
                        setRenewalDate(value);
                      }
                    }}
                    selectionType='single'
                    type='date'
                  />
                </CondensedTableCell>
              );
            } else if (String(property.key) === 'renewalQuantity' && subresourceType !== 'retailsubscriptions') {
              // Disable editing renewal qty in case of retail order subscription
              return (
                <CondensedTableCell key={String(property.key)}>
                  <div className='subscription-number-input-container'>
                    <NumberInput
                      key={String(property.key)}
                      min={1}
                      max={1000}
                      value={renewalQuantity}
                      onChange={value => {
                        if (value) {
                          setRenewalQuantity(value);
                        }
                      }}
                      disabled={ !autoRenewal }
                    />
                  </div>
                </CondensedTableCell>
              );
            } else if (String(property.key) === 'autoRenewal') {
              return (
                <CondensedTableCell key={String(property.key)}>
                  <Select
                    className='subscription-status-select'
                    aria-label='Status Code'
                    value={String(autoRenewal)}
                    options={[
                      { label: 'true', value: 'true' },
                      { label: 'false', value: 'false' }
                    ]}
                    onSelect={value => setAutoRenewal(value === 'true')}
                  />
                </CondensedTableCell>
              );
            } else {
              return <CondensedTableCell key={String(property.key)} />;
            }
          })}
          <CondensedTableCell>
            <Button variant='action' onClick={event => handleUpdateSubscription()}>
              Save
            </Button>
          </CondensedTableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default SubscriptionRow;
