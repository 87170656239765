export enum Environment {
  PRESTAGE,
  DEV,
  LOCAL
}

const hostname = window && window.location && window.location.hostname;

export function getEnvironment(): Environment {
  let environment: Environment;

  if (hostname === 'partnerportal-dev.adobe.com') {
    environment = Environment.DEV;
  } else if (hostname === 'partnerportal-sandbox.adobe.com') {
    environment = Environment.PRESTAGE;
  } else {
    environment = Environment.LOCAL;
  }

  return environment;
}
