import React from 'react';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import { Grid } from '@material-ui/core';
import Dialog from '@react/react-spectrum/Dialog';
// import Header from '@react/react-spectrum/Header';
import TextField from '@react/react-spectrum/Textfield';
import Select from '@react/react-spectrum/Select';

// Custom styles and utils
import './GrantAccessPage.css';
import { requestAccess, Distributor } from '../../../providers/DistributorsAPI';

import { getPortalEnvironmentOptionsDescriptionByEnv } from '../../service/SelectService';
import { getEnvironment } from '../../../config/EnvironmentConfig';

interface UserAccessRequest {
  emails: string[];
  distributor: Distributor
}

function getEmptyUserAccessRequest(): UserAccessRequest {
  return {
    emails: [],
    distributor: {
      distributorId : '',
      imsOrgId : '',
      distributorName : ''
    }
  };
}

function GrantAccessPage() {
  const [successfulRequest, setSuccessfulRequest] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emails, setEmails] = React.useState<string[]>([]);
  const [accessRequest, setAccessRequest] = React.useState<UserAccessRequest>(getEmptyUserAccessRequest());
  const [distributorEnvironment, setDistributorEnvironment] = React.useState('');
  const [showRequestDialog, setShowRequestDialog] = React.useState(false);


  const handleAccessRequest = () => {
    let userAccessBody = {
      emails : accessRequest.emails,
      distributors : [
        accessRequest.distributor
      ]
    }
    let userAccessBodyJson = JSON.stringify(userAccessBody);
    // console.log(accessRequest)
    // console.log(distributorEnvironment)
    requestAccess(userAccessBodyJson, accessRequest.distributor.distributorId).then((response : boolean) => {
        // console.log(response)
        setSuccessfulRequest(response)
        setShowRequestDialog(true)
    });
  }

  return (
    <div>
      <Dialog
        title='User Modification Request'
        open={showRequestDialog}
        confirmLabel='OK'
        onConfirm={event => setShowRequestDialog(false)}
      >
        {successfulRequest ? 'Portal requests were successfully completed' : 'Failure'}
      </Dialog>
      <div className='distributor-select-heading-container'>
        <Heading variant='subtitle1'>Grant Partner Portal Access</Heading>
        <div>
          <Button variant='cta' disabled={distributorEnvironment === ''} onClick={event => handleAccessRequest()}>Grant Access</Button>
        </div>
      </div>
      <Heading variant='subtitle3'>Manually Give access to user(s) for a certain distributor</Heading>
      <br />
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <b>Distributor Environment</b>
          <br/>
          <Select
            aria-label='Distributor/Portal Environment'
            defaultValue={distributorEnvironment}
            value={distributorEnvironment}
            options={getPortalEnvironmentOptionsDescriptionByEnv(getEnvironment())}
            onSelect={(value) => setDistributorEnvironment(value)}
            className='distributor-dropdown'
          />
          <br/>
          <br/>
          <b>User Email Addresses (comma-separated)</b>
          <TextField
              multiLine
              className="add-emails-textfield"
              value={accessRequest.emails.join(",\n")}
              onChange= { value => setAccessRequest({...accessRequest, emails : value.split(",").map(item => item.trim())})}
              placeholder="Add Emails of Users to add"
          ></TextField>
        </Grid>
        <Grid item md={4} xs={12}>
            <Heading variant='subtitle2'>Distributor Name:</Heading>
            <TextField
              className='form-field-textfield'
              placeholder="Enter Distributor Name"
              onChange={value => {
                accessRequest.distributor = {...accessRequest.distributor, distributorName : value.trim()}
                setAccessRequest(accessRequest)
              }}
            />
            <Heading variant='subtitle2'>Distributor ID:</Heading>
            <TextField
              className='form-field-textfield'
              placeholder="Enter Distributor ID"
              onChange={value => {
                accessRequest.distributor = {...accessRequest.distributor, distributorId : value.trim()}
                setAccessRequest(accessRequest)
              }}
            />
            <Heading variant='subtitle2'>Distributor IMS Org ID:</Heading>
            <TextField
              className='form-field-textfield'
              placeholder="Enter Distributor IMS Org ID"
              onChange={value => {
                accessRequest.distributor = {...accessRequest.distributor, imsOrgId : value.trim()}
                setAccessRequest(accessRequest)
              }}
            />
        </Grid>
      </Grid>
    </div>
  );
}

export default GrantAccessPage;
