import React from 'react';
import Select from '@react/react-spectrum/Select';

// UI libary components
import Heading from '@react/react-spectrum/Heading';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import TablePaginationActions from '../../../components/TablePaginationActions';

// Custom styling and utils
import './CreatedMembershipsPage.css';
import { searchWithParameters } from '../../../providers/ResourcesAPI';
import { deleteMembershipById } from '../../../providers/MembershipsAPI';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../../src/utils/Dialogs/ErrorDialog';
import { getOrderByOptionsWithReason, getSortByOptionsWithReason, getPageSizeOptionsWithReason } from '../../service/SelectService';
import ExpandableRow from '../ExpandableRow/ExpandableRow';
import { CreatedMembershipObject, CreatedMembershipResponse } from '../../models/MembershipModels';

function CreatedMembershipsPage() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [allMemberships, setAllMemberships] = React.useState<CreatedMembershipObject[]>([]);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  const createdMembershipProperties: { key: keyof CreatedMembershipObject; title: string }[] = [
    { key: 'membershipId', title: 'Membership ID' },
    { key: 'companyName', title: 'Company Name' },
    { key: 'eligibilityStatus', title: 'Eligibility Status' },
    { key: 'creationDate', title: 'Creation Date' }
  ];

  let [selectOrderValue, setSelectOrderValue] = React.useState('asc'); // asc, desc - works
  let [selectSortValue, setSelectSortValue] = React.useState('created_date'); // membership_id, ims_org (company name)
  let [selectPageSizeValue, setselectPageSizeValue] = React.useState('20');
  const [sortingProperties, setSortingProperties] = React.useState<SortingProperties>({direction: selectSortValue, sort_property:selectOrderValue});

  React.useEffect(() => {
    setIsLoading(true);
    let queryParameters: Map<String, String> = new Map<String, String>();
    queryParameters.set('page_no', String(pageNo));
    queryParameters.set('size', selectPageSizeValue);
    queryParameters.set('order_by', selectOrderValue);
    queryParameters.set('sort_by', selectSortValue);

    searchWithParameters('memberships', queryParameters).then((response: CreatedMembershipResponse) => {
      if(response.httpstatus == 200) {
        setIsLoading(false);
        setIsError(false);
        setAllMemberships(response.data);
        setPageNo(response.pageNo);
        setTotalCount(response.totalCount);
      } else {
        setErrorResponse(response);
        setIsLoading(false);
        setIsError(true);
      }
    });
  }, [pageNo]);

  const getQueryParams = (): Map<String, String> => {
    let queryParams: Map<String, String> = new Map<String, String>();

    queryParams.set('page_no', String(pageNo));
    queryParams.set('size', selectPageSizeValue);
    queryParams.set('order_by', selectOrderValue);
    queryParams.set('sort_by', selectSortValue);

    return queryParams;
  };

  function deleteMembership(membershipId: string) {
    setIsLoading(true);
    deleteMembershipById(membershipId).then((response) => {
      if(response.httpstatus == 200) {
        searchWithParameters('memberships', getQueryParams()).then((response: CreatedMembershipResponse) => {
          setView(response);
        });
      } else {
        setErrorResponse(response);
        setIsLoading(false);
        setIsError(true);
      }
    })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    // Add 1 to newPage because TablePagination is 0 indexed while search results are 1 indexed
    let pageNo = newPage + 1;
    setPageNo(pageNo);
  };

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const setView = (response: CreatedMembershipResponse) => {
    if(response.httpstatus == 200) {
      setIsLoading(false);
      setIsError(false);
      setAllMemberships(response.data);
      setPageNo(response.pageNo);
      setTotalCount(response.totalCount);
    } else {
      setErrorResponse(response);
      setIsLoading(false);
      setIsError(true);
    }
  }

  // for page number
  function updatePageSize(value: string | string[]) {
    setIsLoading(true);
    setselectPageSizeValue(value.toString())
    selectPageSizeValue = value.toString()
    let queryParameters = getQueryParams();
    searchWithParameters('memberships', queryParameters).then((response: CreatedMembershipResponse) => {
      setView(response);
    });
  }

  // for sort by
  function updateSort(value: string | string[]) {
    setIsLoading(true);
    setSelectSortValue(value.toString())
    selectSortValue = value.toString()
    setSortingProperties({direction: selectSortValue, sort_property:selectOrderValue})
    let queryParameters = getQueryParams();
    searchWithParameters('memberships', queryParameters).then((response: CreatedMembershipResponse) => {
      setView(response);
    });
  }

  // for order by
  function updateOrder(value: string | string[]) {
    setIsLoading(true);
    setSelectOrderValue(value.toString())
    selectOrderValue = value.toString()
    setSortingProperties({direction: selectSortValue, sort_property:selectOrderValue})
    let queryParameters = getQueryParams();
    searchWithParameters('memberships', queryParameters).then((response: CreatedMembershipResponse) => {
      setView(response);
    });
  }

  interface SortingProperties {
    direction: string;
    sort_property: string;
  }

  return (
    <div>
      <div className='memberships-heading-container'>
        <Heading variant='subtitle1'>All Memberships</Heading>
      </div>
      <Heading variant='subtitle3'>View and delete membership ids you have created</Heading>
      <br />
      {isLoading ? (
        <CircularProgress size={20} className='memberships-table-loading-spinner' />
      ) : [

        (errorResponse && isError ?
        <ErrorDialog
        isError={true}
        status={errorResponse.httpstatus}
        reason={errorResponse.reason}
        message={errorResponse.message}
        handleDismissErrorDialog={handleDismissErrorDialog}
        />
        :
        <div>
          <div className='rowC'>
            <div className='sort-by-label'>
              <b>Sorting:</b>
            </div>
            <div className='order-by-cell'>
              <Select
                aria-label='Order By'
                value={selectOrderValue}
                options={getOrderByOptionsWithReason()}
                onSelect={value => updateOrder(value)}

              />
            </div>
            <div className='sort-by-cell'>
              <Select
                aria-label='Sort By'
                value={selectSortValue}
                options={getSortByOptionsWithReason()}
                onSelect={value => updateSort(value)}
              />
            </div>
            <div className='page-number-cell'>
              <Select
                aria-label='Page Number'
                value={selectPageSizeValue}
                options={getPageSizeOptionsWithReason()}
                onSelect={value => updatePageSize(value)}
              />
            </div>
          </div>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell />
                    {createdMembershipProperties.map(property => {
                        return (
                          <TableCell key={String(property.key)} className='records-table-head'>
                            {property.title}
                          </TableCell>
                        );
                    })}
                    <TableCell key='delete' className='memberships-table-head'>
                      Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allMemberships.map((membership, index) => (
                  <ExpandableRow
                  key={index}
                  object={membership}
                  properties={createdMembershipProperties}
                  deleteMembership={deleteMembership}
                  />
                ))}

              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[parseInt(selectPageSizeValue)]}
                    colSpan={7}
                    count={totalCount}
                    rowsPerPage={parseInt(selectPageSizeValue)}
                    page={pageNo - 1}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      )
    ]}
    </div>
  );
}

export default CreatedMembershipsPage;
