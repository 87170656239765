
import React from 'react';
import UserInfo from '../../../models/UserInfo';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';
import ConfirmationDialog from '../../../utils/Dialogs/ConfirmationDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { resetToFactoryDefault, createDefaultSet, createDefaultReseller, createDefaultCustomer, createDefaultOrder, createDefaultMembership, createDefaultTransfer } from '../../../providers/DefaultDataAPI';
import { getSelectedDistributor } from '../../../service/ApiService';

function FactoryDefaultPage() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingResellerRecord, setIsLoadingResellerRecord] = React.useState(false);
    const [isLoadingCustomerRecord, setIsLoadingCustomerRecord] = React.useState(false);
    const [isLoadingOrderRecord, setIsLoadingOrderRecord] = React.useState(false);
    const [isLoadingMembershipRecord, setIsLoadingMembershipRecord] = React.useState(false);
    const [isLoadingTransferRecord, setIsLoadingTransferRecord] = React.useState(false);

    const [isError, setIsError] = React.useState(false);
    var [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isComplete, setIsComplete] = React.useState(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState("");

    const [defaultResellerId, setDefaultResellerId] = React.useState("");
    const [defaultCustomerId, setDefaultCustomerId] = React.useState("");
    const [defaultMembershipId, setDefaultMembershipId] = React.useState("");
    const orderStatusList = ["1000", "1004", "1008"]
    const [orderStatus, setOrderStatus] = React.useState("");

    const handleFactoryDefaultSubmit = () => {
        // console.log("Reset the data")
        setIsOpen(true)
    };
    
    const handleConfirmResetDialog = () => {
        // console.log("Confirm the reset ")
        setIsOpen(false)
        setIsLoading(true);
        resetToFactoryDefault().then((response) => {
            setIsOpen(false)
            // console.log("We have reset the data")
            setIsLoading(false);
            if(response.httpstatus === 200) {
                setConfirmationMessage("All of the data in sandbox has been returned to factory defaults.")
                setIsError(false)
                setIsComplete(true)
            } else {
                setErrorResponse(response);
                setIsError(true)
                setIsComplete(false)
            }

        });
    };

    const handleCreateDefaultSet = () => {
        const { distributorId, imsOrgId } = getSelectedDistributor() || {};

        setIsLoading(true);

        var defaultSet = {
            accountId : distributorId,
            tenantId : imsOrgId
        }
        createDefaultSet(defaultSet).then((response) => {
            setIsLoading(false);
            if(response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The reseller, customer, order, membership, and transfer records were created successfully.")
                setIsError(false)
                setIsComplete(true)

            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
            }

        });
    };

    const handleCreateDefaultReseller = () => {
        setIsLoadingResellerRecord(true);
        const { distributorId, imsOrgId } = getSelectedDistributor() || {}
        var defaultSet = {
            accountId : distributorId,
            tenantId : imsOrgId
        }
        createDefaultReseller(defaultSet).then((response) => {
            setIsLoadingResellerRecord(false);
            if(response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The reseller record was created successfully.")
                setIsError(false)
                setIsComplete(true)
                setDefaultResellerId(response.completed.resellerId)

            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
            }

        });
    };

    const handleCreateDefaultCustomer = () => {
        const { distributorId, imsOrgId } = getSelectedDistributor() || {}

        setIsLoadingCustomerRecord(true);
        setOrderStatus("")
        var defaultSet = {
            accountId : distributorId,
            tenantId : imsOrgId,
            resellerId : defaultResellerId
        }
        createDefaultCustomer(defaultSet).then((response) => {
            setIsLoadingCustomerRecord(false);
            if(response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The customer record was created successfully.")
                setIsError(false)
                setIsComplete(true)
                setDefaultCustomerId(response.completed.customerId)

            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
            }

        });
    };

    const handleCreateDefaultOrder = () => {
        const { distributorId, imsOrgId } = getSelectedDistributor() || {}

        setIsLoadingOrderRecord(true);
        let promise = new Promise((resolve, reject) => resolve())
        orderStatusList.forEach( (statusCode, index) => {
            var defaultSet = {
                accountId : distributorId,
                tenantId : imsOrgId,
                customerId : defaultCustomerId,
                orderStatus : statusCode
            }
            setOrderStatus("This might take a while. Creating default orders...")
            promise = promise.then(() => {
                return createDefaultOrder(defaultSet).then((response) => {
                    if((response.httpstatus === 200 || response.httpstatus === 201) && response.completed.lineItems) {
                        setOrderStatus("This might take a while. Created default orders ("+(index+1)+"/"+orderStatusList.length+")...")

                        if(statusCode == "1008") {
                            setIsLoadingOrderRecord(false);
                            setConfirmationMessage("The order records were created successfully.")
                            setIsComplete(true)
                            setIsError(false)
                            setOrderStatus("Successfully created all default orders.")
                        }
                    } else {
                        setIsError(true)
                        errorResponse = response.completed
                        errorResponse!.httpstatus = response.httpstatus
                        setErrorResponse(errorResponse)
                        setOrderStatus("There was an error.")
                    }

                });
            })
        })
    };

    const handleCreateDefaultMembership = () => {
        const { distributorId, imsOrgId } = getSelectedDistributor() || {}

        setIsLoadingMembershipRecord(true);
        var defaultSet = {
            accountId : distributorId,
            tenantId : imsOrgId,
            resellerId : defaultResellerId
        }
        createDefaultMembership(defaultSet).then((response) => {
            setIsLoadingMembershipRecord(false);
            if(response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The membership records was created successfully.")
                setIsError(false)
                setIsComplete(true)
                setDefaultMembershipId(response.completed.membershipId)

            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
            }

        });
    };

    const handleCreateDefaultTransfer = () => {
        const { distributorId, imsOrgId } = getSelectedDistributor() || {};

        setIsLoadingTransferRecord(true);
        var defaultSet = {
            accountId : distributorId,
            tenantId : imsOrgId,
            resellerId : defaultResellerId,
            membershipId : defaultMembershipId
        }
        createDefaultTransfer(defaultSet).then((response) => {
            setIsLoadingTransferRecord(false);
            if(response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The transfer record was created successfully. Clearing the used Membership ID.")
                setIsError(false)
                setIsComplete(true)
                setDefaultMembershipId("")
            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
                setDefaultMembershipId("")
            }

        });
    };


    const handleDismissResetDialog = () => {
        // Cancel the reset
        setIsOpen(false)
    };

    const handleConfirmDialog = () => {
        // Completed task
        if(isLoadingOrderRecord == false) {
            setOrderStatus("")
        }
        setIsComplete(false)
    };

    const handleDismissErrorDialog = () => {
        // handle error
        sessionStorage.clear();
        window.location.reload();
    };
    
    return (
        <div>
            <div className='settings-heading-container'>
                <Heading variant='subtitle1'>Create Default Records</Heading>
                <Heading variant='subtitle3'>Create default reseller, customer, order, membership, and transfer records with one click.</Heading>
                {/* <Heading variant='subtitle2'>Resetting to factory default cannot be undone.</Heading> */}
                <br />
                <br />
                {isLoading ? (
                <CircularProgress size={20} />
                ) : [
                (errorResponse && isError ?
                <ErrorDialog
                isError={true}
                status={errorResponse.httpstatus}
                reason={errorResponse.reason}
                message={errorResponse.message}
                handleDismissErrorDialog={handleDismissErrorDialog}
                />
                :
                <div>
                    <Button onClick={event => handleCreateDefaultReseller()}>
                        Create Default Reseller 
                    </Button> 
                    {isLoadingResellerRecord ? (
                    <CircularProgress size={20} />
                    ) : null }
                    <br/>
                    Creates a default reseller. You must create a Reseller to create a default Customer or default Membership.
                    <br />
                    <b>Default Reseller ID:</b> {defaultResellerId}
                    <br/>
                    <br />
                    <Button disabled={defaultResellerId == ""} onClick={event => handleCreateDefaultCustomer()}>
                        Create Default Customer 
                    </Button> 
                    {isLoadingCustomerRecord ? (
                    <CircularProgress size={20} />
                    ) : null }
                    <br />
                    Creates a default Customer. You muse create a Customer to create a default Order.
                    <br />
                    <b>Default Customer ID:</b> {defaultCustomerId}
                    <br/>
                    <br/>
                    <Button disabled={defaultCustomerId == ""} onClick={event => handleCreateDefaultOrder()}>
                        Create Default Order 
                    </Button> 
                    {isLoadingOrderRecord ? (
                    <CircularProgress size={20} />
                    ) : null }
                    <br/>
                    Creates a default successful order (1000), inactive order (1004), and cancelled order (1008). 
                    <br />
                    <b>Order Creation Status:</b> {orderStatus}
                    <br />
                    <br />
                    <Button disabled={defaultResellerId == ""}  onClick={event => handleCreateDefaultMembership()}>
                        Create Default Membership 
                    </Button> 
                    {isLoadingMembershipRecord ? (
                    <CircularProgress size={20} />
                    ) : null }
                    <br />
                    Creates a default membership. You must create a Membership to create a default Transfer. 
                    <br />
                    <b>Default Membership ID:</b> {defaultMembershipId}
                    <br/>
                    <br/>
                    <Button disabled={defaultMembershipId == ""}  onClick={event => handleCreateDefaultTransfer()}>
                        Create Default Transfer 
                    </Button> 
                    {isLoadingTransferRecord ? (
                    <CircularProgress size={20} />
                    ) : null }
                    <br />
                    Creates a default transfer. You must create a new default Membership each time you create a transfer. 
                    <br />
                    <br/>
                    <Button onClick={event => handleCreateDefaultSet()}>
                        Create Default Set 
                    </Button> 
                    {/* <br />
                    Creates a default reseller, customer, successful order, membership, and transfer.
                    <br />
                    <br />
                    <Button disabled={true} variant='warning' onClick={event => handleFactoryDefaultSubmit()}>
                        Reset Sandbox To Factory Default (Coming Soon)
                    </Button>  */}
                </div>
                )]}
                {isOpen &&
                    <ConfirmationDialog
                    title="Are you sure you want to reset the sandbox data?"
                    isOpen={true}
                    variant='destructive'
                    message="Once you corfirm this action, this cannot be undone."
                    handleConfirmConfirmationDialog={handleConfirmResetDialog}
                    handleDismissConfirmationDialog={handleDismissResetDialog}
                    />
                }
                {isComplete &&
                    <ConfirmationDialog
                    title="Task Complete"
                    isOpen={true}
                    variant='information'
                    message={confirmationMessage}
                    handleConfirmConfirmationDialog={handleConfirmDialog}
                    handleDismissConfirmationDialog={handleConfirmDialog}
                    />
                }
            </div>
            <br />
        </div>
    );
} 


export default FactoryDefaultPage;