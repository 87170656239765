import React from 'react';

// UI library components
import Textfield from '@react/react-spectrum/Textfield';
import { Grid } from '@material-ui/core';
import Button from '@react/react-spectrum/Button';

// Custom styles and utils
import './Form.css';
import { ContactObject } from '../../../models/memberships/Contact';
import { getValidationState } from '../../../utils/FormUtils';

interface ContactFormProps {
  contact: ContactObject;
  checkValid: boolean;
  index: number;
  updateContactState: (contact: ContactObject, index: number) => void;
  deleteContact: (index: number) => void;
}

function ContactForm(props: ContactFormProps): React.ReactElement {
  const { checkValid, index, updateContactState, deleteContact } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>First Name</p>
        <Textfield
          className='form-field-textfield'
          value={props.contact.firstName}
          validationState={getValidationState(checkValid, props.contact.firstName)}
          onChange={value => {
            props.contact.firstName = value;
            updateContactState(props.contact, index);
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>Last Name</p>
        <Textfield
          className='form-field-textfield'
          value={props.contact.lastName}
          validationState={getValidationState(checkValid, props.contact.lastName)}
          onChange={value => {
            props.contact.lastName = value;
            updateContactState(props.contact, index);
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <p className='form-field-label'>Email (must be valid email format)</p>
        <Textfield
          className='form-field-textfield'
          value={props.contact.email}
          validationState={getValidationState(checkValid, props.contact.email)}
          onChange={value => {
            props.contact.email = value;
            updateContactState(props.contact, index);
          }}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <p className='form-field-label'>Phone Number (optional)</p>
        <Textfield
          className='form-field-textfield'
          value={props.contact.phoneNumber}
          onChange={value => {
            props.contact.phoneNumber = value;
            updateContactState(props.contact, props.index);
          }}
        />
      </Grid>
      <Grid item md={1} xs={12}>
        <div className='form-delete-container'>
          <Button quiet variant='primary' className='form-delete-button' onClick={event => deleteContact(props.index)}>
            Delete
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
