import {API_ROOT} from '../config/ApiConfig';
import {
  generateRequestHeaders,
  getUpdateResourceBody,
  getSubscriptionStatus,
  mapToQueryString,
  getThreeYCUpdateBody,
  getThreeYCUpdateBodyForOnlyAction,
  getCreatePaRequestBody,
  getLMEnrollRequestBody
} from '../service/ApiService';

export interface SearchResourcesResponse {
  totalCount: number;
  count: number;
  pageNo: number;
  data: any[];
  httpstatus: number;
  reason: string;
  message: string;
}

export interface DeploymentsResponse {
  limit: number,
  offset: number,
  count: number,
  totalCount: number,
  items: any[],
  httpstatus: number;
  reason: string;
  message: string;
}

export async function searchWithParameters(
  type: string,
  parameters: Map<String, String>
): Promise<SearchResourcesResponse> {
  let queryString = mapToQueryString(parameters);
  type = type.startsWith('retail') ?type.slice(6): type;
  let endpoint = `${API_ROOT}/${type}/search${queryString}`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function getDeploymentsByCustomerId(customerId: string): Promise<DeploymentsResponse> {
  const endpoint = `${API_ROOT}/customers/${customerId}/deployments`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders()
  });
  const data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function updateDeployTos(
  customerId: string,
  deploymentId: string,
  payload: { [x: string]: any; }
): Promise<any> {
  const endpoint = `${API_ROOT}/customers/${customerId}/deployments/${deploymentId}`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify(payload)
  });
  const data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function getRecordDetailById(
  type: string,
  id: string,
  membershipId: string,
  customerId: string
): Promise<any> {
  type = type.startsWith('retail')?type.slice(6): type;
  let endpoint = `${API_ROOT}/${type}/${id}`;
  if (type === 'transfers') {
    endpoint = `${endpoint}?membership_id=${membershipId}`;
  } else if (type === 'orders') {
    endpoint = `${endpoint}?customer_id=${customerId}`;
    // endpoint = `${endpoint}?customer_id=${customerId}?type=${orderType}`;
  } 
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function updateResourceStatus(
    type: string,
    id: string,
    newStatus: string,
    globalSalesEnabled: string,
    accountId: string,
    cotermDate: string,
    oldCoTermDate: string | undefined,
    createdDate: string,
    marketSegment: string | undefined,
    marketSegments: string[] | undefined,
    MarketSubSegments: string[] | undefined
): Promise<any> {
  type = type.startsWith('retail') ?type.slice(6): type;
  let endpoint = `${API_ROOT}/${type}/${id}`;
  if (type === 'orders') {
    endpoint = `${endpoint}?customer_id=${accountId}`;
  }

  let marketSubSegments = MarketSubSegments;
  let body = getUpdateResourceBody(type, newStatus, globalSalesEnabled, cotermDate, oldCoTermDate, createdDate, marketSegment, marketSegments, marketSubSegments);
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify(body)
  });
  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function updateSubscriptionResource(
  id: string,
  statusCode: string,
  renewalQuantity: number,
  autoRenewal: boolean,
  accountId: string,
  renewalDate: string
): Promise<any> {
  let endpoint = `${API_ROOT}/subscriptions/${id}`;
  let status = getSubscriptionStatus(statusCode);
  let body = {
    status: status,
    renewalQuantity: renewalQuantity,
    autoRenewal: autoRenewal,
    accountId: accountId,
    renewalDate: renewalDate
  };
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify(body)
  });

  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function customEditThreeYCCommittmentTerms(
  id: string,
  newStartDate: string,
  newEndDate: string,
  newStatus: string,
  areRequestedTermsPresent: boolean
): Promise<any> {
  let endpoint = `${API_ROOT}/customers/${id}/commitment-terms`;
  let body = getThreeYCUpdateBody(newStartDate, newEndDate, newStatus, 'CUSTOM_EDIT', areRequestedTermsPresent);
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function createApprovalCode(
  id: string
): Promise<any> {
  const endpoint = `${API_ROOT}/customers/${id}/approval-code`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST'
  });

  const data = await response.text();
  return data;
}

export async function createPA(
  customerId: string,
  resellerId: string,
  product: string,
  quantity: number
): Promise<any> {
  const endpoint = `${API_ROOT}/resellers/${resellerId}/open-pa/${customerId}`;
  let body = getCreatePaRequestBody(product, quantity);
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    body: JSON.stringify(body),
    method: 'POST'
  });

  const data = await response.text();
  return data;
}

export async function generateLinkedAccountCode(
  linkedId: string,
  customerId: string
): Promise<any> {
  const endpoint = `${API_ROOT}/account-groups/${linkedId}/approval-code?account_id=${customerId}`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST'
  });

  const data = await response.json();
  return data;
}

export async function priceLevelRefresh(
  linkedId: string,
  customerId: string
): Promise<any> {
  const endpoint = `${API_ROOT}/account-groups/${linkedId}/refresh/${customerId}`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST'
  });

  const data = await response;

  if (response.status !== 200) {
    return data.json();
  }
  return data;
}

export async function enrollLMMember(
  customerId: string,
  approvalCode: string,
): Promise<any> {
  const endpoint = `${API_ROOT}/account-groups/enroll`;
  let body = getLMEnrollRequestBody(customerId, approvalCode);

  const response = await fetch(endpoint, {
    body: JSON.stringify(body),
    headers: generateRequestHeaders(),
    method: 'POST'
  });
  const data = await response;

  if (response.status !== 200) {
    return data.json();
  }

  return data;
}

export async function lmTermsStatus(
  customerId: string,
  termsAcceptanceStatus: string,
): Promise<any> {
  const endpoint = `${API_ROOT}/account-groups/terms`;
  const body = {
    accountId: customerId,
    termsAcceptanceStatus: termsAcceptanceStatus
  };
  const response = await fetch(endpoint, {
    body: JSON.stringify(body),
    headers: generateRequestHeaders(),
    method: 'POST'
  });

  const data = await response;

  if (response.status !== 200) {
    return data.json();
  }

  return data.text();
}

export async function updateThreeYCCommittmentTermsWithOnlyAction(
  id: string,
  action: string
): Promise<any> {
  let endpoint = `${API_ROOT}/customers/${id}/commitment-terms`;
  let body = getThreeYCUpdateBodyForOnlyAction(action);
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function activateOrder(id: string): Promise<any> {
  const endpoint = `${API_ROOT}/orders/${id}/activate`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST'
  });
  const data = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function returnOrder(id: string): Promise<any> {
  const endpoint = `${API_ROOT}/orders/${id}/return`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST'
  });
  const data = await response.json();
  data.httpstatus = response.status;
  return data;
}