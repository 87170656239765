import { defaultAddressOptions, defaultPriceRegionOptions, priceRegionOptions, currencyOptions, distributorTypeOptions, getCreationDestinationOptionsByEnv, getPortalEnvironmentOptionsByEnv, programTypeOptions} from './SelectProperties';
import { AddressObject } from '../../models/memberships/Address';
import { CurrencyObject, CurrencyOption } from '../../models/distributors/Currency';
import { getEnvironment, Environment } from '../../config/EnvironmentConfig';
import { CountryObject } from '../../models/distributors/Country';
import { priceRegionNames } from '../../models/utils/common';

// For Default Address
export function getDefaultAddressOptionsWithReason() {
    let options = Object.keys(defaultAddressOptions)

    let defaultAddressOptionsWithReason = options.map(option => {
        return {
            label: option,
            value: `${JSON.stringify(getDefaultAddress(option))}`
        };
    });
    return defaultAddressOptionsWithReason;
}

function getDefaultAddress(countryCode: string): AddressObject {
    return defaultAddressOptions[String(countryCode)];
}

// For Default Price Region
export function getDefaultPriceRegionOptionsWithReason() {
    let options = Object.keys(defaultPriceRegionOptions)
    let defaultPriceRegionOptionsWithReason = options.map(option => {
        return {
            label: `${priceRegionNames[option]}`,
            value: `${JSON.stringify(getDefaultPriceRegion(option))}`
        };
    });
    return defaultPriceRegionOptionsWithReason;
}

export function getDefaultPriceRegion(countryCode: string): CurrencyObject {
    return defaultPriceRegionOptions[String(countryCode)];
}

// For Price Region
export function getPriceRegionOptionsWithReason() {
    let options = Object.keys(priceRegionOptions)
    let priceRegionOptionsWithReason = options.map(option => {
        return {
            label: `${priceRegionNames[option]}`,
            value: `${JSON.stringify(getPriceRegion(option))}`
        };
    });
    return priceRegionOptionsWithReason;
}

export function getPriceRegion(countryCode: string): CurrencyOption {
    return priceRegionOptions[String(countryCode)];
}


// For Currencies
export function getCurrencyOptionsWithReason(priceRegion: string) {
    let options = Object.keys(currencyOptions)
    if(priceRegion !== "") {
        var defaultCurrencyInfo = JSON.parse(priceRegion) as CurrencyOption
        options = defaultCurrencyInfo.currencies
    }
    let currencyOptionsWithReason = options.map(option => {
        return {
            label: `${getCurrency(option)}`,
            value: `${getCurrency(option)}`
        };
    });
    return currencyOptionsWithReason;
}

function getCurrency(currencyCode: string): string {
    return currencyOptions[String(currencyCode)];
}

// For Distributor Type
export function getDistributorTypeWithReason() {
    let options = Object.keys(distributorTypeOptions)
    let defaultDistributorTypeWithReason = options.map(option => {
        return {
            label: option,
            value: `${getDistributorType(option)}`
        };
    });
    return defaultDistributorTypeWithReason;
}

export function getDistributorType(distributorType: string): string {
    return distributorTypeOptions[String(distributorType)];
}

// For Distributor Creation Destination
export function getCreationDestinationWithReasonByEnv(env : Environment) {
    let optionMap : Record<string, string> = getCreationDestinationOptionsByEnv(env)
    let options = Object.keys(optionMap)
    let creationDestinationWithReason = options.map(option => {
        return {
            label: option,
            value: `${optionMap[String(option)]}`
        };
    });
    return creationDestinationWithReason;
}

export function getPortalEnvironmentOptionsDescriptionByEnv(env : Environment) {
  let optionMap : Record<string, string> = getPortalEnvironmentOptionsByEnv(env)
  let options = Object.keys(optionMap)
    let optionsWithDescription = options.map(option => {
        return {
            label: option,
            value: `${optionMap[String(option)]}`
        };
    });
    return optionsWithDescription;
}

// For Countries in a Price Region
export function getCountriesInPriceRegion(countries: CountryObject[]) {
    let countriesWithReason = countries.map(option => {
        return {
            label: option.name + " (" + option.code+ ")",
            value: option.code
        };
    });
    return countriesWithReason;
}

// For Regions in a Country
export function getRegionsInCountry(regionList: string[]) {
    let regionsWithReason = regionList.map(option => {
        return {
            label: option,
            value: option
        };
    });
    return regionsWithReason;
}

// For Program Type
export function getProgramTypeWithReason() {
    let options = Object.keys(programTypeOptions)
    let defaultProgramTypeWithReason = options.map(option => {
        return {
            label: option,
            value: `${getProgramType(option)}`
        };
    });
    return defaultProgramTypeWithReason;
}

export function getProgramType(programType: string): string {
    return programTypeOptions[String(programType)];
}