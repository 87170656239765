// Node packages
import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import { TabView, Tab } from '@react/react-spectrum/TabView';

// Custom styling and utils
import FactoryDefaultPage from './components/FactoryDefaultPage/FactoryDefaultPage';
import AddressTestingPage from './components/AddressTestingPage/AddressTestingPage';
// import DataImportPage from './components/DataImportPage/DataImportPage';

// import InternalPartnerAccessPage from '../InternalPage/components/InternalPartnerAccessPage/InternalPartnerAccessPage';
import { checkIfAdobeUser } from '../service/ApiService';
/**
 * SettingsPage contains the page to handle the settings associated wih this sandbox.
 */

const ToolsTabs = ['Create Default Records', 'Test Addresses'];

function ToolsPage(): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSelectedIndex(newSelectedTabIndex);
  };

  return (
    <div>
      <Heading variant='pageTitle'>API Tools</Heading>
      <TabView selectedIndex={selectedIndex} onSelect={handleTabChange}>
        { ToolsTabs.map((tabItem, index) => (
            <Tab key={index} label={tabItem} />
          ))
        }
      </TabView>
      {selectedIndex === 0 && <FactoryDefaultPage />}
      {selectedIndex === 1 && <AddressTestingPage />}
      {/* {selectedIndex === 2 && <DataImportPage />} */}

    </div>
  );
}

export default ToolsPage;
