import React from "react";
import Dialog from "@react/react-spectrum/Dialog";
import TextField from '@react/react-spectrum/Textfield'
import Heading from '@react/react-spectrum/Heading';
import "./LinkedMemberEnrollDialog.css";

interface LinkedMemberEnrollDialogProps {
  isOpen: boolean;
  title: string;
  customerId: string;
  approvalCode?: string;
  variant: "confirmation" | "information" | "destructive" | "error";
  handleLinkedMemberEnrollDialog: (customerId: string, approvalCode: string) => void;
  handleLMDismissConfirmationDialog: () => void;
}

function LinkedMemberEnrollDialog(props: LinkedMemberEnrollDialogProps) {
  const { isOpen, title, variant, customerId, handleLinkedMemberEnrollDialog, handleLMDismissConfirmationDialog } = props;
  const [approvalCode, setApprovalCode] = React.useState<string>("");

  const handleSubmit = (customerId: string, approvalCode: string) => {
    handleLinkedMemberEnrollDialog(customerId, approvalCode);
    setApprovalCode("")
  };

  return (
    <Dialog
      className="lm-dialog"
      title={title}
      open={isOpen}
      cancelLabel="Cancel"
      confirmLabel="Submit"
      variant={variant}
      onConfirm={(event) => handleSubmit(customerId, approvalCode)}
      onCancel={() => {
        setApprovalCode("")
        handleLMDismissConfirmationDialog()
      }}
    >
      <Heading variant='subtitle2'>Approval Code</Heading>
      <TextField
        defaultValue={approvalCode}
        value={approvalCode}
        onChange={(value) =>
          setApprovalCode(value)
        }
      />
    </Dialog>
  );
}

export default LinkedMemberEnrollDialog;
