import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';

export async function getLocales(): Promise<any> {
    let response = await fetch(`${API_ROOT}/locales`, {
      headers: generateRequestHeaders(),
      method: 'GET'
    });
  
    var data = {httpstatus: 500, data: []};
    data.data = await response.json();
    data.httpstatus = response.status;
    return data;
       
}