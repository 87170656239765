import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';

export async function getAvailableErrorCodes(): Promise<any> {
    let endpoint = `${API_ROOT}/errorcodes`;
    let response = await fetch(endpoint, {
        headers: generateRequestHeaders(),
    });
    let data = await response.json();
    if (response.status !== 200 ) {
        data.data = []
        data.httpstatus = response.status;
        return data
    } else {
        let formattedResponse = {data: [], httpstatus: 500, message: "Missing Data", reason: "Invalid distributor or authorization"}
        formattedResponse.data = data
        formattedResponse.httpstatus = response.status;
        return formattedResponse;

    }
}