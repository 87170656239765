import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Checkbox from '@react/react-spectrum/Checkbox'
import { FilterParameters } from '../../models/utils/FilterParameters'
import { fileURLToPath } from 'url';
// Custom styling and utils
// import './FilterDialog.css';
interface FilterDialogProps {
    isOpen: boolean,
    filterParameters: FilterParameters;
    handleConfirmConfirmationDialog: (filterParameters: FilterParameters) => void;
    handleDismissConfirmationDialog: (filterParameters: FilterParameters) => void;

}

function FilterDialog(props: FilterDialogProps) {
  const {
    isOpen,
    filterParameters,
    handleConfirmConfirmationDialog,
    handleDismissConfirmationDialog,
  } = props;

  let [orderNewCbxSelected, setOrderNewCbxSelected] = React.useState(filterParameters.orderNewCbx);
  let [orderRenewalCbxSelected, setOrderRenewalCbxSelected] = React.useState(filterParameters.orderRenewalCbx);

  function updateOrderNewCbxSelected(checked: boolean) {
    setOrderNewCbxSelected(checked)
    filterParameters.orderNewCbx = checked
  }

  function updateOrderRenewalCbxSelected(checked: boolean) {
    setOrderRenewalCbxSelected(checked)
    filterParameters.orderRenewalCbx = checked
  }

  return (
    <Dialog
      title='Select Additional Filters'
      open={isOpen}
      confirmLabel='Filter'
      cancelLabel='Close'
      onConfirm={event => handleConfirmConfirmationDialog(filterParameters)}
      onCancel={() => handleDismissConfirmationDialog(filterParameters)}
    >
        <b> Order Type (Defaults to NEW): </b>
        <br/>
        <Checkbox value="orderNewCbx" defaultChecked={orderNewCbxSelected} checked={orderNewCbxSelected} onChange={ (checked, event) => updateOrderNewCbxSelected(checked)}>New Orders</Checkbox>
        <br />
        <Checkbox value="orderRenewalCbx" defaultChecked={orderRenewalCbxSelected} checked={orderRenewalCbxSelected} onChange={ (checked, event) => updateOrderRenewalCbxSelected(checked)}>Renewal Orders</Checkbox>
        <br/>
    </Dialog>
  );
}

export default FilterDialog;