// Node packages
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@react/react-spectrum/Select';

// Custom components
import RecordsTable from './components/RecordsTable/RecordsTable';
import RecordsSearchBar from './components/RecordsSearchBar/RecordsSearchBar';

// Custom styling and utils
import './RecordsPage.css';
import { resourceProperties } from './service/TableProperties';
import { DetailRecordObject } from './models/ResourceModels';
import { searchWithParameters, SearchResourcesResponse } from '../providers/ResourcesAPI';
import { ErrorResponse } from '../models/ErrorResponse';
import ErrorDialog from '../utils/Dialogs/ErrorDialog';
import { triggerAutoRenewal, checkTriggerStatus } from '../providers/RenewalAPI';
import { getEnvironment, Environment } from '../config/EnvironmentConfig';

import { getFilterByOptionsWithReason, getOrderByOptionsWithReason, getSortByOptionsWithReason, getPageSizeOptionsWithReason } from './service/SelectService';

/**
 * RecordsPage contains the page that displays all records.
 */

interface RetailResourcePageProps {
  resourceType: string;
  searchStr: string;
  handleViewSubresource: (resourceType: string, searchText: string, modalData?: any, resourceId?: string) => void;
}

function RetailResourcePage(props: RetailResourcePageProps) {
  const { resourceType, searchStr, handleViewSubresource } = props;
  const [searchText, setSearchText] = React.useState(searchStr);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  let [selectOrderValue, setSelectOrderValue] = React.useState('desc');
  let [selectSortValue, setSelectSortValue] = React.useState('created_date');
  let [selectPageSizeValue, setSelectPageSizeValue] = React.useState('10');
  let [currentPage, setCurrentPageValue] = React.useState('1');
  let [selectFilterValue, setSelectFilterValue] = React.useState('id');
  let [selectOrderType, setOrderType] = React.useState('REGISTER,NEW,RENEWAL,RETURN');
  const [isCoolDown, setIsCoolDown] = React.useState(false);
  var [countDownTimer, setCountDownTimer] = React.useState(120);


  const [searchResourcesResponse, setSearchResourcesResponse] = React.useState<SearchResourcesResponse | undefined>();
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  const [sortingProperties, setSortingProperties] = React.useState<SortingProperties>({ direction: selectSortValue, sort_property: selectOrderValue });

  React.useEffect(() => {
    setSelectOrderValue('desc')
    let queryParams = getQueryParams('1', searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    })
  }, []);

  const setView = (response: SearchResourcesResponse) => {
    if (response.httpstatus === 200) {
      checkCoolDownStatus().then(() => {
        setSearchResourcesResponse(response);
        setIsLoading(false);
        setIsError(false);
      })
    } else {
      setErrorResponse(response);
      setIsLoading(false);
      setIsError(true);
    }
  }

  const checkCoolDownStatus = () => {
    return new Promise(function (resolve, reject) {


      var environmentName;
      if (getEnvironment() === Environment.DEV) {
        environmentName = 'dev'
      } else if (getEnvironment() === Environment.PRESTAGE) {
        environmentName = 'prestage'
      } else {
        environmentName = 'dev'
      }
      var triggerAutoRenewalBody = {
        environment: environmentName
      }
      checkTriggerStatus(triggerAutoRenewalBody).then((response: any) => {

        if (response.httpstatus == 200) {
          setIsCoolDown(response.cooldown)
        } else {
          setIsCoolDown(false)
        }
        resolve(true)
      });
    });
  }

  const handleCoolDown = (isCoolDown: boolean) => {
    setIsCoolDown(isCoolDown)
    if (isCoolDown == true) {

      var environmentName;
      if (getEnvironment() === Environment.DEV) {
        environmentName = 'dev'
      } else if (getEnvironment() === Environment.PRESTAGE) {
        environmentName = 'prestage'
      } else {
        environmentName = 'dev'
      }

      var triggerAutoRenewalBody = {
        environment: environmentName
      }

      triggerAutoRenewal(triggerAutoRenewalBody).then((response: any) => {
        if (response.httpstatus == 200) {
          startCountdownTimer()
        } else {
          setIsCoolDown(false)
        }
      });
    }
    return
  };

  function startCountdownTimer() {
    var startCounting = setInterval(function () {
      countDownTimer -= 1
      if (countDownTimer <= 0) {
        setIsCoolDown(false)
        countDownTimer = 120
        setCountDownTimer(120);
        clearInterval(startCounting);
      }
      setCountDownTimer(countDownTimer);
    }, 1000)

  };

  const getQueryParams = (pageNo: string, searchText: string): Map<String, String> => {
    let queryParams: Map<String, String> = new Map<String, String>();
    setCurrentPageValue(pageNo)
    queryParams.set('page_no', pageNo);
    queryParams.set('size', selectPageSizeValue);
    queryParams.set('order_by', selectOrderValue);
    queryParams.set('sort_by', selectSortValue);
    if(selectFilterValue !== 'id') {
      if(resourceType === 'retailcustomers') {
        queryParams.set('association_id', searchText);
        queryParams.set('association_type', 'reseller');
        queryParams.set('type', 'customer');
        queryParams.set('id', '');

      }
      if(resourceType === 'retailorders'){
        queryParams.set('customer_id', searchText);
        queryParams.set('id', '');
        queryParams.set('type', selectOrderType)

      }
    } else {
      queryParams.delete('association_id');
      queryParams.delete('association_type');
      queryParams.set('id', searchText);
      if (resourceType === 'retailresellers' || resourceType === 'retailcustomers') {
        let type = resourceType.substring(6, resourceType.length - 1);
        queryParams.set('type', type);
      } else if (resourceType === 'retailorders') {
        queryParams.set('type', selectOrderType)
      }
    }
    return queryParams;
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setIsLoading(true);
    let page = `${newPage + 1}`;
    setCurrentPageValue(page)
    setSelectFilterValue('id')
    selectFilterValue = 'id'
    selectSortValue = 'created_date';
    setSelectSortValue(selectSortValue)
    let queryParams = getQueryParams(page, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  };

  const onSearchClick = (searchText: string) => {
    setIsLoading(true);
    setSearchText(searchText);
    setCurrentPageValue('1')
    let queryParams = getQueryParams('1', searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
      setSearchText(searchText);
    });
  };

  const handleRefresh = (callback: (response: DetailRecordObject) => void, res: DetailRecordObject) => {
    let pageNo = searchResourcesResponse ? searchResourcesResponse.pageNo : 1;
    setCurrentPageValue(pageNo.toString())
    let queryParams = getQueryParams(pageNo.toString(), searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
      callback(res);
    });
  };

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  // for search filter
  function updateFilter(value: string | string[]) {
    setSelectFilterValue(value.toString())
    selectFilterValue = value.toString()
  }

  // for page number
  function updatePageSize(value: string | string[]) {
    setIsLoading(true);
    setSelectPageSizeValue(value.toString())
    selectPageSizeValue = value.toString()
    let queryParams = getQueryParams(currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  // for sort by
  function updateSort(value: string | string[]) {
    setIsLoading(true);
    setSelectSortValue(value.toString())
    selectSortValue = value.toString()
    setSortingProperties({ direction: selectSortValue, sort_property: selectOrderValue })
    let queryParams = getQueryParams(currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  // for ordr by
  function updateOrder(value: string | string[]) {
    setIsLoading(true);
    setSelectOrderValue(value.toString())
    selectOrderValue = value.toString()
    setSortingProperties({ direction: selectSortValue, sort_property: selectOrderValue })
    let queryParams = getQueryParams(currentPage, searchText);
    searchWithParameters(resourceType, queryParams).then((response: SearchResourcesResponse) => {
      setView(response);
    });
  }

  interface SortingProperties {
    direction: string;
    sort_property: string;
  }

  return (
    <div>
      <div className='rowC'>
        <div className='filter-by-cell'>
          <Select
            aria-label='Filter By'
            value={selectFilterValue}
            options={getFilterByOptionsWithReason(resourceType.slice(6))}
            onSelect={value => updateFilter(value)}

          />
        </div>
        <RecordsSearchBar handleSearchClick={onSearchClick} searchText={searchText} />

        <div className='sort-by-label'>
          <b>Sorting:</b>
        </div>
        <div className='order-by-cell'>
          <Select
            aria-label='Order By'
            value={selectOrderValue}
            options={getOrderByOptionsWithReason()}
            onSelect={value => updateOrder(value)}
          />
        </div>
        <div className='sort-by-cell'>
          <Select
            aria-label='Sort By'
            value={selectSortValue}
            options={getSortByOptionsWithReason(resourceType)}
            onSelect={value => updateSort(value)}
          />
        </div>
        <div className='page-number-cell'>
          <Select
            aria-label='Page Number'
            value={selectPageSizeValue}
            options={getPageSizeOptionsWithReason()}
            onSelect={value => updatePageSize(value)}
          />
        </div>
      </div>
      {searchResourcesResponse && !isLoading && !isError ? (
        <RecordsTable
          objects={searchResourcesResponse.data}
          totalCount={searchResourcesResponse.totalCount}
          // Subtract 1 because table is 0 indexed
          pageNo={searchResourcesResponse.pageNo - 1}
          rowsPerPage={searchResourcesResponse.count.valueOf()}
          selectedTab={resourceType}
          properties={resourceProperties[resourceType]}
          handleChangePage={handleChangePage}
          handleRefresh={handleRefresh}
          handleViewSubresource={handleViewSubresource}
          sortingProperties={sortingProperties}
          isCoolDown={isCoolDown}
          handleCoolDown={handleCoolDown}
          countDownTimer={countDownTimer}
        />

      ) : [
        (errorResponse && isError ?
          <ErrorDialog
            isError={true}
            status={errorResponse.httpstatus}
            reason={errorResponse.reason}
            message={errorResponse.message}
            handleDismissErrorDialog={handleDismissErrorDialog}
          />
          :
          <CircularProgress size={20} className='loading-spinner' />
        )
      ]}
    </div>
  );
}

export default RetailResourcePage;
