import React from 'react';

// UI library components
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';
import { CircularProgress } from '@material-ui/core';

// Custom styles and services
import './AccessDeniedComponent.css';

interface AccessDeniedComponentProps {
  isLoading: boolean;
}

function AccessDeniedComponent(props: AccessDeniedComponentProps): React.ReactElement {
  const { isLoading } = props;

  const AlertIcon = (
    <svg xmlns='http://www.w3.org/2000/svg' height='90' viewBox='0 0 18 18' width='100'>
      <title>AlertMedium</title>
      <rect id='ToDelete' fill='#ff13dc' opacity='0' width='18' height='18' />
      <path d='M8.564,1.289.2,16.256A.5.5,0,0,0,.636,17H17.364a.5.5,0,0,0,.436-.744L9.436,1.289a.5.5,0,0,0-.872,0ZM10,14.75a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,14.75v-1.5A.25.25,0,0,1,8.25,13h1.5a.25.25,0,0,1,.25.25Zm0-3a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,11.75v-6a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25Z' />
    </svg>
  );

  return (
    <div>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <div>
          <div className='access-denied-warning'>
            <IllustratedMessage
              heading='Access Denied'
              description='Please contact your distributor administrator to grant you access.'
              illustration={AlertIcon}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AccessDeniedComponent;
