import { isWorldWideFlagDevEnabled, isWorldWideFlagEnabled } from '../config/FeatureFlags';
import {Distributor} from '../providers/DistributorsAPI';
import {IMSAccountService} from './ims/IMSAccountService';

const APPLICATION_JSON = 'application/json';
const IMS_CLIENT_ID = 'partnerportal-ui';

// storage constants
const SELECTED_DISTRIBUTOR_ID = 'selected-distributor'

IMSAccountService.onSignInNOut(() => {
  sessionStorage.removeItem(SELECTED_DISTRIBUTOR_ID)
})

let distributors: Distributor[] = []
let selectedDistributor: Distributor

export function setDistributorList(distributors_: Distributor[]) {
  distributors = distributors_
  const selectedDistributorId = sessionStorage.getItem(SELECTED_DISTRIBUTOR_ID)
  selectDistributor(findDistributorById(selectedDistributorId) || distributors[0])
}

export function getAllDistributors() {
  return distributors;
}

export function selectDistributor(distributor: Distributor) {
  sessionStorage.setItem(SELECTED_DISTRIBUTOR_ID, distributor?.distributorId)
  selectedDistributor = distributor
}

export function getSelectedDistributor() {
  return selectedDistributor!;
}

function findDistributorById(id: string | null) {
  return id ? distributors?.find(({distributorId}) => id === distributorId) : undefined
}

export const checkIfAdobeUser = () => {
  const profile = IMSAccountService.getUserProfile()
  const email = profile?.email
  return email?.endsWith?.("@adobe.com")
}

export const generateRequestHeaders = () => {
  const accessToken = IMSAccountService.getAccessToken().token
  const distributorId = selectedDistributor?.distributorId || '';

  return {
    accept: APPLICATION_JSON,
    'x-api-key': IMS_CLIENT_ID,
    'content-type': APPLICATION_JSON,
    'distributor-id': distributorId,
    'Authorization': `Bearer ${accessToken}`
  };
};

export function getUpdateResourceBody(
  type: string,
  newStatus: string,
  globalSalesEnabled: string,
  cotermDate: string,
  oldCoTermDate: string | undefined,
  createdDate: string,
  marketSegment: string | undefined,
  marketSegments: string[] | undefined,
  marketSubSegments: string[] | undefined
) {
  let body;
  if (type === 'customers' && cotermDate !== '') {
    body = {
      status: newStatus,
      cotermDate: cotermDate,
      oldCoTermDate: oldCoTermDate
    };
  } else if (type === 'customers' || type === 'resellers') {
    body = {
      status: newStatus
    };
  } else if (type === 'orders' && createdDate !== '') {
    body = {
      statusCode: newStatus,
      createdAt: createdDate
    };
  } else {
    body = {
      statusCode: newStatus
    };
  }

  return {
    ...body,
    ...(type === 'customers' || type === 'resellers') ? {
      ...marketSegment ? { marketSegment } : {},
      ...marketSegments ? { marketSegments } : {},
      ...marketSubSegments ? { marketSubSegments } : {},
    }: {},
    ...(isWorldWideFlagEnabled() || isWorldWideFlagDevEnabled()) && type === 'customers'
    ? {
      globalSalesEnabled: globalSalesEnabled === 'true'
        ? true
        : false
      }
    : {}
  }
}

export function getSubscriptionStatus(statusCode: string) {
  let status;
  if (statusCode === '1000') {
    status = 'ACTIVE';
  } else if (statusCode === '1002') {
    status = 'PENDING';
  } else {
    status = 'INACTIVE';
  }
  return status;
}

export function getThreeYCUpdateBody(newStartDate: string,
                                     newEndDate: string,
                                     newStatus: string,
                                     action: string,
                                     areRequestedTermsPresent: boolean) {
  let commitment;
  commitment = {
    startDate: newStartDate,
    endDate: newEndDate,
    status: newStatus
  };
  let customEditDetails;
  if (areRequestedTermsPresent) {
    customEditDetails = {
      commitment: commitment,
      commitmentRequest: commitment
    };
  } else {
    customEditDetails = {
      commitment: commitment
    }
  }
  let body;
  body = {
    type: 'THREE_YEAR_COMMIT',
    customEditDetails: customEditDetails,
    action: action
  };
  return body;
}

export function getThreeYCUpdateBodyForOnlyAction(action: string) {
  if (action === 'ENROLL') {
    return {
        mode: 'DEFAULT',
        type: 'THREE_YEAR_COMMIT',
        action: action
      };
  }
  else{
    return  {
      type: 'THREE_YEAR_COMMIT',
      action: action
    };
  }
  
}

export function getCreatePaRequestBody(product: string, quantity: number) {
  return {
    productName: product,
    quantity: quantity
  };
}

export function getLMEnrollRequestBody(customerId: string, approvalCode: string) {
  return {
    customerAccountId: customerId,
    approvalCode: approvalCode
  };
}

export function getIMSOrgID() : String {
  let IMSOrgID = selectedDistributor.imsOrgId;
  return IMSOrgID;
}

export function isSelectedDistributorProgramRetail() : boolean {
  return selectedDistributor?.programType ==='RETAIL'
}

export function mapToQueryString(queryMap: Map<String, String>): String {
  let queryString = '';
  if (queryMap.size > 0) {
    queryString += '?';
  }
  Array.from(queryMap.keys()).forEach(key => {
    queryString = queryString + key + '=' + queryMap.get(key) + '&';
  });
  return queryString;
}
