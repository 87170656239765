import {IMSAccountService} from "./ims/IMSAccountService";
import {FG_URL} from "../config/ApiConfig";
import {getIMSOrgID, mapToQueryString} from "./ApiService";


const IMS_CLIENT_ID = 'partnerportal-ui';
const content_type = 'application/x-www-form-urlencoded';

const generateRequestHeaders = () => {
  const accessToken = IMSAccountService.getAccessToken().token

  return {
    'content-type': content_type,
    'x-api-key': IMS_CLIENT_ID,
    'cache-control': 'no-cache',
    'Authorization': `Bearer ${accessToken}`
  };
};

export interface FloodGateResponse {
  releases : Array<Releases> ;
}
interface Releases {
  features : Array<string> ;
}

export async function fetchFeatureFlags(
): Promise<FloodGateResponse> {
  let queryParams: Map<String, String> = new Map<String, String>();
  queryParams.set('clientId','partnerportal');
  queryParams.set('CommonOrgID',getIMSOrgID());
  let url = FG_URL + mapToQueryString(queryParams);
  let response = await fetch(url, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  data.httpstatus = response.status;
  return data;
}
