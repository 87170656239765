import React, { ReactElement } from 'react';

import { Grid } from '@material-ui/core';
import Select from '@react/react-spectrum/Select';
import TextField from '@react/react-spectrum/Textfield'
import Heading from '@react/react-spectrum/Heading';
import { getValidationState } from '../utils/FormUtils';

type DeployToGridContainer = {
  disabledFields: string[];
  formData: any;
  newFormData: { [x: string]: any; };
  checkValid: boolean;
  setNewFormData: (modalData: any) => void;
}

type HeadingsMapType = {
  'deploymentId': string;
  'status': string;
  'country': string;
  'region' : string;
  'city': string;
  'addressLine1': string;
  'addressLine2': string;
  'postalCode' : string;
  'phoneNumber': string;
}
  
  
enum HeadingsMap {
  'deploymentId' = 'Deployment Id',
  'status' = 'Status',
  'country' = 'Country',
  'region' = 'Region',
  'city' = 'City',
  'addressLine1' = 'Address Line 1',
  'addressLine2' = 'Address Line 2',
  'postalCode' = 'Postal Code',
  'phoneNumber'=  'Phone Number',
}

const options = [
  { label: 'Inactive', value: '1004' },
  { label: 'Active', value: '1000' }
];

const nonRequiredFields = ['addressLine2', 'phoneNumber'];

function DeployToGridContainer(props: DeployToGridContainer) {
    const { formData, newFormData, disabledFields, setNewFormData, checkValid } = props;
    const renderGridItem = (heading: string, value: string) => {
        return (
          <Grid item md={6} xs={12}>
            <Heading variant='subtitle2'>
              {HeadingsMap[heading as keyof HeadingsMapType] || heading}
            </Heading>
            {heading === 'status'
              ? <Select
                  style={{
                    width: '50%'
                  }}
                  aria-label='Status Code'
                  value={newFormData[heading] || value}
                  options={options}
                  onSelect={value => setNewFormData({
                    ...newFormData,
                    [heading]: value
                  })}
                />
              : <TextField
                  defaultValue={value}
                  value={newFormData[heading]}
                  onChange={value => setNewFormData({
                    ...newFormData,
                    [heading]: value
                  })}
                  validationState={nonRequiredFields.includes(heading) ? undefined : getValidationState(checkValid, newFormData[heading])}
                  disabled={disabledFields.includes(heading)}
                  placeholder={HeadingsMap[heading as keyof HeadingsMapType] || heading}
                />
            }
          </Grid>
        )
      }
    
      const renderGrid = (data: { [x: string]: any; }): ReactElement => {
        return (
          <>
            {Object.keys(data).map(
              item => typeof data[item] === 'object'
                ? renderGrid(data[item])
                : renderGridItem(item, data[item])
            )}
          </>
        )
      }
  return (
    <Grid container spacing={2} wrap={'wrap'}>
    {renderGrid(formData)}
  </Grid>
  );
}

export default DeployToGridContainer;
