import React from 'react';

// UI library components
import { Toolbar } from '@material-ui/core';
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Link from '@react/react-spectrum/Link';

// Custom styling and utils
import './NavigationBar.css';
import { IMSAccountService } from '../../service/ims/IMSAccountService';
import {ENV_NAME} from '../../config/ApiConfig'

/**
 * NavigationBar renders the main navigation bar and is shown on every page.
 */

function NavigationBar(): React.ReactElement {
  const logout = () => {
    IMSAccountService.signOut();
  };

  return (
    <div className='navigation-bar-container'>
      <Toolbar variant='dense' className='navigation-bar-toolbar'>
        <div>
          <Heading variant='subtitle1' className='navigation-bar-heading'>
            PARTNER API {ENV_NAME}
          </Heading>
          <Link href='/internal' className='navigation-bar-link'>
            Internal
          </Link>
        </div>

        <div className='navbar-user-info-container'>
          <Button onClick={logout}>Logout</Button>
        </div>
      </Toolbar>
    </div>
  );
}

export default NavigationBar;
