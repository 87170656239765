import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders, mapToQueryString } from '../service/ApiService';
// import { ProductObject } from '../models/products/Offer';

export async function getAvailableOffers(params: Map<String, String>): Promise<any> {
  const queryString = params?.size > 0 ? mapToQueryString(params) : '';
  const endpoint = `${API_ROOT}/products/available${queryString}`;
  const response = await fetch(endpoint, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  if (response.status !== 200 ) {
      data.data = []
      data.httpstatus = response.status;
      return data 
  } else {
    let formattedResponse = {data: [], httpstatus: 500}
      formattedResponse.data = data
      formattedResponse.httpstatus = response.status;
    return formattedResponse;
  }
}