import React from 'react';

// UI libary components
import Heading from '@react/react-spectrum/Heading';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// Custom styling and utils
import './ViewLanguageCodesPage.css';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';

import {getLocales} from "../../../providers/LocaleAPI";
import {LocaleRes, LocaleResponse} from "../../../models/LocaleResponse";

function ViewLanguageCodesPage() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [allLanguageCodes, setAllLanguageCodes] = React.useState<LocaleRes[]>([]);
    const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
    const availableLocalesProperties: { key: keyof LocaleRes; title: string }[] = [
        { key: 'languageCode', title: 'Language Codes' }
    ];

    React.useEffect(() => {
        getLocales().then((localesResponse: LocaleResponse) => {
            if(localesResponse.httpstatus === 200) {
                setIsLoading(false);
                setIsError(false);
                setAllLanguageCodes(localesResponse.data);
            } else {
                setIsError(true);
                setIsLoading(false);
                setErrorResponse(localesResponse);
            }
        });
    }, []);

    const handleDismissErrorDialog = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <div>
            <div className='offers-heading-container'>
                <Heading variant='subtitle1'>Locales</Heading>
            </div>
            <Heading variant='subtitle3'>View all available langauge codes.</Heading>
            <br />
            {isLoading ? (
                <CircularProgress size={20} className='offers-table-loading-spinner' />
            ) : [

                (errorResponse && isError ?
                    <ErrorDialog
                        isError={true}
                        status={errorResponse.httpstatus}
                        reason={errorResponse.reason}
                        message={errorResponse.message}
                        handleDismissErrorDialog={handleDismissErrorDialog}
                    />
                    :
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    {availableLocalesProperties.map(property => (
                                        <TableCell key={String(property.key)} className='offers-table-head'>
                                            {property.title}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allLanguageCodes.map((languageCode, index) => (
                                    <TableRow key={index}>
                                        {availableLocalesProperties.map(property => {
                                            return <TableCell key={String(index)}>{languageCode[property.key]}</TableCell>;
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            ]}
        </div>
    );
}

export default ViewLanguageCodesPage;
