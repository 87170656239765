import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';
import { DistributorRequest } from '../models/distributors/DistributorRequest';

export async function testAddress(body: DistributorRequest): Promise<any> {
    let finalResult = await fetch(`${API_ROOT}/test/address`, {
      headers: generateRequestHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    }).then(response =>
  
      response.json().then(data => ({status: response.status, body: data, rest: response}))
    ).then( data => {
  
      var result = {httpstatus: 500, completed: false};
      result.completed = data.body
      if(data.status) {
        result.httpstatus = data.status;
      } 
  
      return result;
    }).catch(function(error) {
      var data = {httpstatus: 500, completed: false};
      return data;
    });
  
    return finalResult;
  }