// Node packages
import React from 'react';
import { Grid } from '@material-ui/core';

// Custom styling and utils
import './MembershipOfferContent.css';
import { MembershipOfferObject, ThreeYCInfo } from '../../models/MembershipModels';
import { PricesObject, AvailableOffersObject, AvailableOffersResponse } from '../../../models/products/Offer';

  // Custom styling and utils
import { getAvailableOffers } from '../../../providers/ProductsAPI';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../../src/utils/Dialogs/ErrorDialog';
import ThreeYCStatus from "../../../constants/ThreeYCStatus";

/**
 * MembershipOfferContent renders the collapsible content of single record row in records table.
 * Contains the detailed information about a record and edit views for the record.
 */

interface MembershipOfferContentProps {
  data: MembershipOfferObject[];
  eligibilityStatus: string;
  eligibilityMessage: string;
  eligibilityCode: string;
  threeYCInfo: ThreeYCInfo;
}

function MembershipOfferContent(props: MembershipOfferContentProps) {
  const { data, eligibilityStatus, eligibilityMessage, eligibilityCode, threeYCInfo: threeYCInfo } = props;
  const offerProperties: { key: keyof MembershipOfferObject; title: string }[] = [
    { key: 'offerId', title: 'Offer ID' },
  ];
  var offerIdList: string[] = []

  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [allOffers, setAllOffers] = React.useState<AvailableOffersObject[]>([]);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();

  const styleOfferId = (offerId: string, index:number) => {   
    var addComma = (index !== offerIdList.length-1) ? "," : ""
    var prices: string[] = []
    var specificOffer = allOffers.find(x => x.productId === offerId)
    if(specificOffer) {
        specificOffer.prices.forEach( (price) => {
            prices.push(price.currency)
        })
    }
    return (
        <div
        className='offer-details-tooltip'
        onClick={() => {
            navigator.clipboard.writeText(String(offerId));
          }}
      >
        {offerId+addComma}
    <span className='offer-details-tooltiptext'>{specificOffer?.productName} ({prices.join(", ")})</span>
      </div>
    )
  }

  React.useEffect(() => {
    getAvailableOffers().then((availableOffersResponse: AvailableOffersResponse) => {
      if(availableOffersResponse.httpstatus === 200) {
        setIsLoading(false);
        setIsError(false);
        setAllOffers(availableOffersResponse.data);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorResponse(availableOffersResponse);
      }
    });
  }, []);

  const renderContent = () => {
    return (

      <div>
            <Grid container spacing={5}>
                <Grid item xs={10}>
                    <br/>
                    <b>Eligibility Status: </b> {eligibilityStatus}
                    <br/>
                    { eligibilityCode ? (
                        <div>
                        <b>Eligibility Code: </b>{eligibilityCode}
                        <br/> 
                        </div> ) : (null
                    )}
                    { eligibilityMessage ? (
                        <div>
                        <b>Eligibility Message: </b>{eligibilityMessage}
                        <br/> 
                        </div>
                        ) : (null
                    )}
                </Grid>
                {threeYCInfo ? (<Grid item xs={10}>
                  <b>3YC Status: </b>{threeYCInfo.status}
                  <br/>
                  {threeYCInfo.status == ThreeYCStatus.COMMITTED ? (
                    <div>
                    <b>3YC Start Date: </b>{threeYCInfo.startDate}
                    <br/>
                    <b>3YC End Date: </b>{threeYCInfo.endDate}
                    <br /><br />
                    <b>Minimum Commit Quantities</b>
                    {threeYCInfo.minimumQuantities.map((minQty) => {
                      return (<div>< b>{minQty.offerType}</b>: {minQty.quantity}</div>)
                    })}
                    </div>
                  ) : (null)}
                </Grid>) : (null)}
            </Grid>
          {(data.length > 0) ? (
                <div>
                    {data.map((membership, index) => 
                        
                        {offerProperties.map(property => {
                            if(property.key === "offerId") {
                                offerIdList.push(membership[property.key])
                                offerIdList = Array.from(new Set(offerIdList))
                            } 
                        })}
                        
                    )}
                    <br/>
                    <b>Offer IDs Associated With Membership:</b> 
                    {offerIdList.map( (offerId, index)=> {
                        return styleOfferId(offerId, index)
                    })}
                    <br />
                    <br/>
                </div>
        ): ( 
            <br/>
        )}
      </div>
    );
  };

  return (
    <div> 
        { renderContent() }
    </div>
  )
}

export default MembershipOfferContent;
