// Node packages
import React from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import Provider from '@react/react-spectrum/Provider';

// Custom components
import AccessDeniedPage from './components/AccessDeniedPage/AccessDeniedPage';
// import DistributorSelectionPage from './ManageDistributors/components/DistributorSelectionPage/DistributorSelectionPage';
import ManageDistributorsPage from './ManageDistributors/ManageDistributorsPage';
import NavigationBar from './components/NavigationBar/NavigationBar';
import InternalNavigationBar from './components/NavigationBar/InternalNavigationBar';
import RecordsPage from './RecordsPage/RecordsPage';
import TransfersPage from './TransfersPage/TransfersPage';
import ToolsPage from './ToolsPage/ToolsPage';
import InternalPage from './InternalPage/InternalPage';
import ResourcesPage from './ResourcesPage/ResourcesPage';

// Custom styling and utils
import './App.css';
import { IMSAccountService } from './service/ims/IMSAccountService';
import { getDistributors } from './providers/DistributorsAPI';

import { checkIfAdobeUser, setDistributorList, isSelectedDistributorProgramRetail } from './service/ApiService';
import {fetchFeatureFlags} from "./service/FloodgateService";
import {isFlagEnabled} from "./config/FeatureFlags";
import RetailRecordsPage from './ToolsPage/RetailRecordsPage';

/**
 * Contains entire application.
 * Manages login state for entire application.
 */

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [validProfile, setValidProfile] = React.useState(false);
  const [internalProfile, setInternalProfile] = React.useState(false);

  React.useEffect(() => {
    ensureUserProfile().finally(() => setIsLoading(false));

    async function ensureUserProfile() {
      try {
        await IMSAccountService.initialize();

        const distributorsList = await getDistributors();

        if (distributorsList.length) {
          setDistributorList(distributorsList);
          setValidProfile(true);
          
          const response = await fetchFeatureFlags();
          if( response !== undefined ) {
            isFlagEnabled(response);
          }
        }

        const isAdobeUser = checkIfAdobeUser()
        setInternalProfile(isAdobeUser);
      } catch (error) {
        console.error('failed to initialize', error)
      }
    }
  }, []);

  const ValidatedApp = (
    <div className='App'>
      <NavigationBar />
      <div className='App-content'>
        <BrowserRouter>
          <Switch>
            <Route exact path='/internal' component={InternalPage} />
            <Route exact path='/tools' component={ToolsPage} />
            <Route exact path='/resources' component={ResourcesPage} />
            <Route exact path='/transfers' component={TransfersPage} />
            <Route exact path='/records' component={RecordsPage} />
            <Route exact path='/distributors' component={ManageDistributorsPage} />
            <Redirect to='/records' />
          </Switch>
        </BrowserRouter>
      </div>
      <div className='App-footer' />
    </div>
  );

  const RetailValidatedApp = (
      <div className='App'>
        <NavigationBar />
        <div className='App-content'>
          <BrowserRouter>
            <Switch>
              <Route exact path='/internal' component={InternalPage} />
              <Route exact path='/retail/records' component={RetailRecordsPage} />
              <Route exact path='/distributors' component={ManageDistributorsPage} />
              <Redirect to='/retail/records' />
            </Switch>
          </BrowserRouter>
        </div>
        <div className='App-footer' />
      </div>
    );

  const InternalValidatedApp = (
    <div className='App'>
      <InternalNavigationBar />
      <div className='App-content'>
        <BrowserRouter>
          <Switch>
            <Route exact path='/internal' component={InternalPage} />
            <Redirect to='/internal' />
          </Switch>
        </BrowserRouter>
      </div>
      <div className='App-footer' />
    </div>
  );

  return <Provider theme='light'>{
    isLoading ? <AccessDeniedPage isLoading={true} /> :
    validProfile ? isSelectedDistributorProgramRetail() ? RetailValidatedApp : ValidatedApp :
    internalProfile ? InternalValidatedApp :
    <AccessDeniedPage isLoading={false} />
  }</Provider>
}

export default App;
