import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Select from "@react/react-spectrum/Select";
import TextField from '@react/react-spectrum/Textfield'


interface CreatePADialogProps {
  isOpen: boolean;
  customerId: string;
  resellerId: string;
  handleConfirmConfirmationDialog: (customerId : string, resellerId : string, productValue : string, productQty : number) => void;
  handleDismissConfirmationDialog: () => void;
}

function CreatePADialog(props: CreatePADialogProps) {
  const {
    isOpen,
    customerId,
    resellerId,
    handleConfirmConfirmationDialog,
    handleDismissConfirmationDialog,
  } = props;

  let prodVal = '';
  let prodQty = 0;

  const [productValue, setProductValue] = React.useState(prodVal);
  const [productQty, setProductQty] = React.useState(prodQty);

  const getProductOptions = [
    {
      label: "Acrobat Pro",
      value: "Acrobat Pro",
    },
    {
      label: "Acrobat Standard DC",
      value: "Acrobat Standard DC",
    },
    {
      label: "Adobe Captivate",
      value: "Adobe Captivate",
    },
    {
      label: "Audition",
      value: "Audition",
    },
    {
      label: "Illustrator",
      value: "Illustrator",
    },
    {
      label: "Lightroom",
      value: "Lightroom",
    },
    {
      label: "Photoshop",
      value: "Photoshop",
    },
    {
      label: "InDesign",
      value: "InDesign",
    }
  ]

  return (
    <Dialog
      title='Create PA'
      open={isOpen}
      confirmLabel='Submit'
      cancelLabel='Cancel'
      onConfirm={event => handleConfirmConfirmationDialog(customerId, resellerId, productValue, productQty)}
      onCancel={() => handleDismissConfirmationDialog()}
    >
      <div>
        <br/>
        Product :&nbsp;
        {
          <Select
            aria-label='Product'
            name='product'
            value={productValue}
            options={getProductOptions}
            onSelect={value => setProductValue(value)}
          />
        }
        <br/><br/>
        Quantity :&nbsp;
        {
          <TextField
            name='quantity'
            placeholder="Enter Quantity"
            value={productQty}
            onChange={value => setProductQty(value)}
          />
        }
        {
          <TextField
            name='customerId'
            hidden={true}
            value={customerId}
          />
        }
        {
          <TextField
            name='resellerId'
            hidden={true}
            value={resellerId}
          />
        }
      </div>
    </Dialog>
  );
}

export default CreatePADialog;