// Node packages
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Heading from '@react/react-spectrum/Heading';

// Custom components
import SubresourcesTable from './SubresourcesTable/SubresourcesTable';

// Custom styling and utils
import { resourceProperties } from '../service/TableProperties';
import { searchWithParameters, SearchResourcesResponse, getDeploymentsByCustomerId, DeploymentsResponse } from '../../providers/ResourcesAPI';
import { ErrorResponse } from '../../models/ErrorResponse'
import ErrorDialog from '../../../src/utils/Dialogs/ErrorDialog'

interface SubresourcesSectionProp {
  title: string;
  subresourceType: string;
  resourceType: string;
  resourceId: string;
  handleViewSubresource: (selectedTab: string, searchText: string) => void;
}

function SubresourcesSection(props: SubresourcesSectionProp): React.ReactElement {
  const { title, subresourceType, resourceType, resourceId, handleViewSubresource } = props;
  const [data, setData] = React.useState<any[] | undefined>(undefined);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined | DeploymentsResponse>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    if (subresourceType === 'deployments') {
      getDeploymentsByCustomerId(resourceId).then((response: DeploymentsResponse) => {
        setIsLoading(false);
        if(response.httpstatus == 200) {
          if (response && response.items.length >0) {
            setData(response?.items);
            setIsError(false);
          }
        } else {
          setIsError(true);
          setIsLoading(false);
          setErrorResponse(response);
        }
      });
      return
    }
    let queryParams: Map<String, String> = new Map<String, String>();
    if (subresourceType === 'retailsubscriptions') {
      queryParams.set('order_id', resourceId);
    }
    else if (subresourceType === 'subscriptions') {
      queryParams.set('customer_id', resourceId);
      queryParams.set('program_type', 'VIPMP');
    } else {
      if (subresourceType === 'orders') {
        let type = resourceType === 'resellers' ? 'reseller_id' : 'customer_id';
        queryParams.set(type, resourceId);
      } else {
        queryParams.set('association_id', resourceId);
        let association_type = resourceType.startsWith('retail') ?resourceType.substring(6, resourceType.length - 1)
        : resourceType.substring(0, resourceType.length - 1);
        queryParams.set('association_type', association_type);
        let type = subresourceType.startsWith('retail') ?subresourceType.substring(6, resourceType.length - 1)
        : subresourceType.substring(0, resourceType.length - 1);
        queryParams.set('type', type);
      }
      queryParams.set('page_no', '1');
      queryParams.set('size', '15');
      let sortString = subresourceType === 'transfers' || resourceType.startsWith('retail') ? 'created_date' : 'created_at';
      queryParams.set('sort_by', sortString);
      queryParams.set('order_by', 'desc');
    }
    searchWithParameters(subresourceType, queryParams).then((response: SearchResourcesResponse) => {
      setIsLoading(false);
      if(response.httpstatus == 200) {
        if (response.data && response.data.length > 0) {
          setData(response.data);
          setIsError(false);
        }
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorResponse(response);
      }
    });
  }, []);

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      {data && <Heading variant='subtitle2'>{title}</Heading>}
      {isLoading ? (
        <CircularProgress size={20} className='table-loading-spinner' />
      ) : 
        [
          (errorResponse && isError ?
            <ErrorDialog
            isError={true}
            status={errorResponse.httpstatus}
            reason={errorResponse?.reason}
            message={errorResponse?.message}
            handleDismissErrorDialog={handleDismissErrorDialog}
            />
            :
          <div>
            {data && (
              <SubresourcesTable
                handleViewSubresource={handleViewSubresource}
                objects={data}
                resourceId={resourceId}
                subresourceType={subresourceType}
                properties={resourceProperties[subresourceType]}
              />
            )}
          </div>
        )
      ]
    }
    </div>
  );
}

export default SubresourcesSection;
