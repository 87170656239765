import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';

export async function createOrganization(createOrganizationRequest: string): Promise<any> {
  let response = await fetch(`${API_ROOT}/banyan/create_org`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: createOrganizationRequest
  });

  var data = {httpstatus: 500, data: []};

  data.data = await response.json();
  data.httpstatus = response.status;
  return data;
}