
import React from 'react';
import RegExGen from 'randexp'

// Custom components
import AccessDeniedComponent from '../../../components/AccessDeniedComponent/AccessDeniedComponent';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';
import ConfirmationDialog from '../../../utils/Dialogs/ConfirmationDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { testAddress } from '../../../providers/TestAPI';
import { getAllCountries } from '../../../providers/CountryAPI';
import TextField from '@react/react-spectrum/Textfield'
import Link from '@react/react-spectrum/Link'
import { Grid } from '@material-ui/core';
import Select from '@react/react-spectrum/Select';
// Custom styling and utils
import { getDefaultPriceRegion, getPriceRegionOptionsWithReason, getCurrencyOptionsWithReason} from '../../../InternalPage/service/SelectService';
import { getValidationState, getPostalCodeValidationState } from '../../../utils/FormUtils';
import { getEnvironment, Environment } from '../../../config/EnvironmentConfig';
import { getCurrentUserDistributorInfo }from '../../../utils/Functions/utils'
// for form
// Custom Components
import AddressForm from '../../../templates/form/AddressForm';
// Custom styling and utils
import { AddressObject, IAddressObject } from '../../../models/memberships/Address';
import { CurrencyObject, CurrencyOption } from '../../../models/distributors/Currency';
import { CountryObject, CountriesResponse } from '../../../models/distributors/Country';
import {DistributorObject} from '../../../models/distributors/DistributorObject';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

interface AddressTestObject {
    address: IAddressObject,
    currencies: CurrencyObject[],
    invalid: boolean,
    isLoading: boolean,
    checkValid: boolean
}

interface FullDistributorObject extends DistributorObject {
    invalid: boolean,
    isLoading: boolean,
    checkValid: boolean,
  }

function getEmptyAddressObject() {
    return {
        country: '',
        region: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        phoneNumber: ''
    };
}

function getEmptyContactObject() {
    return {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        type: 'ADMIN'
    };
}

function getEmptyCurrenciesObject() {
    return {
        priceRegion: '',
        currency: '',
    };
}

function CreateDistributorPage() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    var [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();

    const [isLoadingTest, setIsLoadingTest] = React.useState(false);
    const [loadedTest, setLoadedTest] = React.useState(false);
    const [testCompleted, setTestCompleted] = React.useState(false);
    var [testStatus, setTestStatus] = React.useState("");

    // Make dialog box saying script is complete
    let [selectDefaultAddress, setSelectDefaultAddress] = React.useState('');
    let [isDefault, setIsDefault] = React.useState(false);
    const [isAddressInvalid, setIsAddressInvalid] = React.useState(true);
    let [selectCreationDestination, setSelectCreationDestination] = React.useState("dev");

    let [selectPriceRegion, setSelectPriceRegion] = React.useState("");
    let [selectCurrency, setSelectCurrency] = React.useState("");

    let [allCountries, setAllCountries] = React.useState<CountryObject[]>([]);
    const [reducedCountryList, setReducedCountryList] = React.useState<CountryObject[]>([]);
    let [isCountriesDisabled, setIsCountriesDisabled] = React.useState(true);
    const [reducedRegionList, setReducedRegionList] = React.useState<string[]>([]);
    const [postalCodeRegex, setPostalCodeRegex] = React.useState("");
    const [postalCodeRegexExample, setPostalCodeRegexExample] = React.useState("");

    let [selectedCountryData, setSelectedCountryData] = React.useState<CountryObject>();

    React.useEffect(() => {
        getAllCountries().then((countryResponse: CountriesResponse) => {
          if(countryResponse.httpstatus === 200) {
            setIsLoading(false);
            setIsError(false);
            setAllCountries(countryResponse.data)
            allCountries = countryResponse.data;
          } else {
            setIsError(true);
            setIsLoading(false);
            setErrorResponse(countryResponse);
          }
        });
      }, []);

      // TODO: Make a contructor
      var defaultDistributorObject : FullDistributorObject = {
        accountId: '',
        tenantId: '',
        ecmContractId: '',
        operationType: 'distributor',
        type: '',
        name: '',
        preferredLanguage: 'en-US',
        address: getEmptyAddressObject(),
        contacts: [],
        currencies: [getEmptyCurrenciesObject()],
        invalid: false,
        isLoading: false,
        checkValid: false
    }
    var [distributorObject, setDistributorObject] = React.useState(defaultDistributorObject);

    const nonRequiredFields = ['phoneNumber', 'addressLine2'];

    const validateAddress = (obj: object | undefined): boolean => {
        let isValid = true;
        if (obj) {
            Object.entries(obj).forEach(([key, val]) => {
                if (typeof val === 'string' && nonRequiredFields.indexOf(key) === -1 && val.trim() === '') {
                    testStatus += ""
                    testStatus = testStatus + "\n - The attribute for " + key + " is empty."
                    distributorObject.invalid = true
                    isValid = false;
                } 
            });

            if (distributorObject.address.postalCode !== '') {
                if(getPostalCodeValidationState(true, distributorObject.address.postalCode, new RegExp(postalCodeRegex))) {
                    testStatus += ""
                    testStatus = testStatus + "\n - The postal code is incorrect."
                    if(postalCodeRegex !== '') { 
                        testStatus = testStatus + " Looking for the Regex pattern: " + postalCodeRegex 
                    }
                    distributorObject.invalid = true
                    isValid = false;
                }
            }
        }
        return isValid;
      };

    const updateValidAddress = (isValidAddress: boolean) => {
        setIsAddressInvalid(isValidAddress)
        return
    }

    const updateAddressState = (address: AddressObject) => {
        distributorObject.address = address;
        setDistributorObject(distributorObject)

        if(selectDefaultAddress) {
            if (selectDefaultAddress !== JSON.stringify(getEmptyAddressObject())) {
                var defaultCurrencyInfo = JSON.parse(JSON.stringify(getDefaultPriceRegion(address.country))) as CurrencyObject
                distributorObject.currencies[0] = {currency: defaultCurrencyInfo.currency, priceRegion: defaultCurrencyInfo.priceRegion}
            }
        }

        if(selectPriceRegion !== '') {
            setReducedCountryList(getReducedCountries())
        }
        if(distributorObject.address.country !== "") {
            setReducedRegionList(getReducedRegions())
        }
        

        if(selectPriceRegion === "") {
            isCountriesDisabled = true
            setIsCountriesDisabled(true)
        } else {
            isCountriesDisabled = false
            setIsCountriesDisabled(false)
        }
        return
    };

    const updatePriceRegion = (priceRegion: string | string[]) => {
        var defaultCurrencyInfo = JSON.parse(priceRegion.toString()) as CurrencyOption
        distributorObject.currencies[0].priceRegion = defaultCurrencyInfo.priceRegion
        setSelectPriceRegion(priceRegion.toString())
        selectPriceRegion = priceRegion.toString()
        setSelectCurrency(defaultCurrencyInfo.currencies[0])
        selectCurrency = defaultCurrencyInfo.currencies[0]
        distributorObject.currencies[0].currency = defaultCurrencyInfo.currencies[0]
        setReducedCountryList(getReducedCountries())
        setReducedRegionList([])

        distributorObject.address.country = ""
        distributorObject.address.region = ""
        setDistributorObject(distributorObject)
        if(selectPriceRegion === "") {
            isCountriesDisabled = true
            setIsCountriesDisabled(true)
        } else {
            isCountriesDisabled = false
            setIsCountriesDisabled(false)
        }

        updateAddressState(distributorObject.address as AddressObject)

    };

    const getReducedCountries = () => {
        var priceRegionObject = JSON.parse(selectPriceRegion.toString()) as CurrencyOption
        var results = allCountries.filter(country => ~[priceRegionObject.priceRegion].indexOf(country.pricelist_region));
        var sortedCountryList = results.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        })
        return sortedCountryList
    }

    const getReducedRegions = () => {
        var results: CountryObject[] = allCountries.filter(ctry => ~[distributorObject.address.country].indexOf(ctry.code))
        setPostalCodeRegex(results[0].postal_code_format_regex)
        setPostalCodeRegexExample(new RegExGen(new RegExp(results[0].postal_code_format_regex)).gen())
        selectedCountryData = results[0]
        setSelectedCountryData(results[0])
        var sotedRegionsList = results[0].regions.sort((a, b) => {
            return a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        })
        return sotedRegionsList
    }

    const updateCurrency = (currency: string | string[]) => {
        distributorObject.currencies[0].currency = currency.toString()
        setSelectCurrency(currency.toString())
        selectCurrency = currency.toString()
    };

    const getCreationDestination = () => {
        var destination = "";
        if (getEnvironment() === Environment.LOCAL) {
            destination = 'dev'
        } else if (getEnvironment() === Environment.DEV) {
            destination = 'dev'
        } else if (getEnvironment() === Environment.PRESTAGE) {
            destination = 'prestage'
        } 
        setSelectCreationDestination(destination)
        selectCreationDestination = destination
    };

    const handleDismissErrorDialog = () => {
        if(errorResponse?.httpstatus === 401) {
            sessionStorage.clear();
            window.location.reload();
        } else {
            setIsError(false)
        }
    };

    const handleConfirmConfirmationDialog = () => {
        setTestCompleted(false)
    };

    const handleDismissConfirmationDialog = () => {
        setTestCompleted(false)

    };

    // TODO: create address form utils
    function returnPostalCode() {
        if(distributorObject.address.country === "" || postalCodeRegex === '') {
          return "No examples available."
        } else {
          return postalCodeRegexExample
        }
      }

    const handleCheckAddress = () => {
        setIsLoadingTest(true)
        testStatus = ""
        setTestStatus(testStatus)
        distributorObject.checkValid = true;
        let validAddress = distributorObject.address ? validateAddress(distributorObject.address) : true;
        getCreationDestination()
        distributorObject.accountId = getCurrentUserDistributorInfo().selectedDistributor.distributorId
        distributorObject.tenantId = getCurrentUserDistributorInfo().selectedDistributor.imsOrgId
        distributorObject.name = getCurrentUserDistributorInfo().selectedDistributor.distributorName


        if(validAddress !== true) {
            setIsLoadingTest(false)
            setIsError(true)
            setTestStatus(testStatus)
            let errorResponse = {message: "This is not an valid address. Please look below for the reasons why.", reason: testStatus}
            setErrorResponse(errorResponse)
        } else {
            let createDistributorBody = {
                newDistributorObject: distributorObject,
                destination: selectCreationDestination,
            };
            testAddress(createDistributorBody).then((response) => {
                if(response.httpstatus === 401) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (response.httpstatus !== 200){
                    setIsLoadingTest(false)
                    setIsError(true)
                    let errorResponse = {message: response.completed.message, reason: response.completed.reason}
                    setErrorResponse(errorResponse)

                } else {
                    setIsLoadingTest(false)
                    setIsError(false)
                    setTestCompleted(true)
                }
            })
        }
    };
    
    return (
        <div>
            { (errorResponse && isError) ? <ErrorDialog
                isError={true}
                status={errorResponse.httpstatus}
                reason={errorResponse.reason}
                message={errorResponse.message}
                handleDismissErrorDialog={handleDismissErrorDialog}
                />
                : null }

            { (!isError && testCompleted) ? <ConfirmationDialog
                    isOpen={testCompleted}
                    variant="information"
                    title= "Address Validated Successfully!"
                    message= "The inputted address is valid."
                    handleConfirmConfirmationDialog={handleConfirmConfirmationDialog}
                    handleDismissConfirmationDialog= {handleDismissConfirmationDialog}
                />
                : null }

            <div className='settings-heading-container new-line'>
                <Heading variant='subtitle1'>Test Address Input</Heading>
                <Heading variant='subtitle3'></Heading>
                <Heading variant='subtitle3'>{getEnvironment() === Environment.DEV ? "Internally test a custom address to see how it interacts with the CPAPI" : "Test a custom address to see how it interacts with the CPAPI."}</Heading>

                <br/>
                {isLoading ? (
                <CircularProgress size={20} />
                ) :
                <div>
                    <Grid container>
                        <Grid item md={7} >
                            <div>
                            Input an address below to see how it interacts with the CPAPI.
                            <br/>
                            <ul>
                                <li>The <i>Currency</i> drop down is dependent on the <i>Price Region.</i></li>
                                <li>The <i>Country</i> drop down is dependant on the <i>Price Region.</i></li>
                                <li>The <i>Region</i> drop down is dependant on the <i>Country.</i></li>
                            </ul>
                            </div>
                            <Grid container >
                              <Grid item xs={3}>
                                  <b>Price Region</b>
                                  <br />
                                  <Select
                                      aria-label='Price Region'
                                      value={selectPriceRegion}
                                      disabled={isDefault}
                                      options={getPriceRegionOptionsWithReason()}
                                      onSelect={(value) => updatePriceRegion(value)}
                                  />
                                  <br />
                              </Grid>
                              <Grid item xs={4}>
                                  <b>Currency</b>
                                  <br />
                                  <Select
                                      aria-label='Currency'
                                      value={selectCurrency}
                                      disabled={isDefault}
                                      options={getCurrencyOptionsWithReason(selectPriceRegion)}
                                      onSelect={(value) => updateCurrency(value)}
                                  />
                                  <br />
                              </Grid>
                            </Grid>
                            <Grid item sm={10}>
                                <AddressForm
                                    address={distributorObject.address}
                                    checkValid={distributorObject.checkValid}
                                    isDefault={isDefault}
                                    isCountriesDisabled={isCountriesDisabled}
                                    countries={reducedCountryList}
                                    regions={reducedRegionList}
                                    postalCodeRegex={postalCodeRegex}
                                    updateAddressState={ (address) => updateAddressState(address)}
                                    updateValidAddress={ (isAddressInvalid) => updateValidAddress(isAddressInvalid)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <br />
                                <Button disabled={(isLoadingTest)} onClick={event => handleCheckAddress()}>
                                    Check Address
                                </Button>
                                {isLoadingTest ? (
                                    <CircularProgress size={20}/>
                                ) : [
                                    loadedTest ? (
                                        [ testCompleted ? <CheckIcon className="success-color"/> : <ClearIcon color="secondary" /> ]
                                    ) : null
                                ]}
                            </Grid>
                        </Grid>
                        <Grid container md={5} >
                            <Grid item md={7} >
                                <div>
                                <Heading variant='subtitle2'>Address Issues:</Heading>
                                { testStatus ? "Here are the reasons why your address validation failed." : "No issues reported." }
                                <br/>
                                {testStatus}
                                <br/>
                                <br/>
                                <Heading variant='subtitle2'>Postal Code Info:</Heading>
                                Here is some data on your address' postal code.
                                <br/>
                                <br/>
                                <i>Country:</i> { distributorObject.address.country ? selectedCountryData?.name + "(" + selectedCountryData?.code + ")" : "No Country Selected."}
                                <br/>
                                <i>Postal Code Regex:</i> { distributorObject.address.country  ? selectedCountryData?.postal_code_format_regex : "No Regex Example Available."}
                                <br/>
                                <i>Postal Code Example:</i> { returnPostalCode() }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                }
            </div>
            <br />
        </div>
    );
}


export default CreateDistributorPage;
