import Heading from "@react/react-spectrum/Heading";
import { Tab, TabView } from "@react/react-spectrum/TabView";
import React from "react";

import RetailResourcePage from "../RecordsPage/RetailResourcePage";
import DefaultRetailRecordsPage from "./components/FactoryDefaultPage/DefaultRetailRecordsPage";

const ToolsTabs = ["Create Default Records", "Resellers", "Customers", "Orders"];

function ToolsPage(): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSearchText('');
    setSelectedIndex(newSelectedTabIndex);
  };

  const handleViewSubresource = (resourceType: string, searchText: string, modalData?: any, resourceId?: string) => {
    const tabValue = resourceType.startsWith('retail') ? resourceType.charAt(6).toUpperCase() + resourceType.slice(7) : resourceType.charAt(0).toUpperCase() + resourceType.slice(1);
    const tabIndex = ToolsTabs.indexOf(tabValue);
    setSearchText(searchText);
    setSelectedIndex(tabIndex);
  };

  return (
    <div>
      <Heading variant="pageTitle">Manage Records</Heading>
      <TabView selectedIndex={selectedIndex} onSelect={handleTabChange}>
        {ToolsTabs.map((tabItem, index) => (
          <Tab key={index} label={tabItem} />
        ))}
      </TabView>
      {selectedIndex === 0 && <DefaultRetailRecordsPage />}
      {selectedIndex === 1 && <RetailResourcePage resourceType='retailresellers' searchStr={searchText} handleViewSubresource={handleViewSubresource}/>}
      {selectedIndex === 2 && <RetailResourcePage resourceType='retailcustomers' searchStr={searchText} handleViewSubresource={handleViewSubresource}/>}
      {selectedIndex === 3 && <RetailResourcePage resourceType='retailorders' searchStr={searchText} handleViewSubresource={handleViewSubresource}/>}
    </div>
  );
}

export default ToolsPage;
