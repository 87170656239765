// Node Packages
import React from 'react';
import { Collapse, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '@react/react-spectrum/Button';

// Custom styling
import './DetailedRecordRow.css';

/**
 * DetailedRecordRow renders a row for a specific field in a record object.
 * Field values can be a string, an object, or an array of objects.
 * For objects and arrays of objects, DetailedRecordRow recursively calls itself.
 */

interface DetailedRecordRowProps {
  rowKey: string;
  rowVal: string | number | object | object[] | string[];
  editable: boolean;
  isEditing?: boolean;
  renderEdit?: (key: string, value: string | number | object) => JSX.Element | null;
  renderThreeYCEdit?: () => JSX.Element | null;
  handleLinkedAccountCode: () => void;
  handlePriceLevel: () => void;
  isLMAccepted?: boolean;
}

function DetailedRecordRow(props: DetailedRecordRowProps) {
  const { rowKey, rowVal, editable, isEditing, renderEdit, renderThreeYCEdit, handleLinkedAccountCode, handlePriceLevel, isLMAccepted } = props;
  const [expand, setExpand] = React.useState(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const isObjectType = typeof rowVal === 'object' && Object.keys(rowVal).length
  const isRowValNull = rowVal === null;
  const isArrayType = Array.isArray(rowVal) && typeof rowVal[0] === 'object';
  const isArrayOfStrings = Array.isArray(rowVal) && typeof rowVal[0] === 'string';
  const objectArrayKeys = isArrayType ? Object.keys(rowVal[0]) : [];

  const renderTable = () => {
    return (
      <table>
        <thead>
          <tr>
            {objectArrayKeys.map(key => (
              <th key={key} className='detail-table-cell'>
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(rowVal)
            ? isArrayOfStrings
              ? rowVal.map((value, index) => (
                  <tr key={index}>
                    <td className='detail-table-cell'>{value}</td>
                  </tr>
                ))
              : rowVal.map((obj, index) => (
                  <tr key={index}>
                    {Object.values(obj).map(val => (
                      <td key={val} className='detail-table-cell'>
                        {val}
                      </td>
                    ))}
                  </tr>
                ))
            : null}
        </tbody>
      </table>
    );
  };

  const renderObject = () => {
    if (isObjectType) {
      return (
        <div className='table-row'>
          <div className='table-small-cell' />
          <div className='table-cell colspan4'>
            <Collapse in={expand} timeout='auto' unmountOnExit>
              {isArrayType
                ? renderTable()
                : isArrayOfStrings
                ? rowVal.map((value, index) => (
                    <div key={index} className='table-row'>
                      <div className='table-small-cell' />
                      <div className='table-cell'>{value}</div>
                    </div>
                  ))
                : Object.entries(rowVal).map(([recordKey, recordVal]) => {
                  const rowVal = recordVal === null ? 'null' : recordVal;
                  return (
                    <DetailedRecordRow
                      key={recordKey}
                      rowKey={recordKey}
                      rowVal={rowVal}
                      editable={true}
                      isEditing={isEditing}
                      isLMAccepted={isLMAccepted}
                      renderEdit={renderEdit}
                      handleLinkedAccountCode={handleLinkedAccountCode}
                      handlePriceLevel={handlePriceLevel}
                    />
                  )
                })}
            </Collapse>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return  isRowValNull ? null : (
    <React.Fragment>
      <div className='table-row'>
        <div className='table-small-cell'>
          {isObjectType ? (
            <IconButton size='small' onClick={handleExpand}>
              {expand ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          ) : null}
        </div>
        <div className='table-cell'>{rowKey}</div>
        <div className='table-cell'>
          {isObjectType
            ? <p className='expand-detail'>Expand for detail</p>
            : <div>
                {typeof rowVal === 'boolean' ?
                  `${rowVal}`
                  : <div className='view-button'>
                    {rowVal}
                    {Boolean(['memberType'].includes(rowKey) && rowVal === 'OWNER' && isLMAccepted)
                      ? <>
                          <Button 
                            className='view-button-action'
                            variant='action'
                            onClick={handleLinkedAccountCode}
                            >Generate Linked Membership Code
                          </Button>
                          <Button 
                            className='view-button-action' 
                            variant='action'
                            onClick={handlePriceLevel}
                            >Refresh Discount Level
                          </Button>
                        </>
                      : null
                    }
                  </div>
                }
            </div> 
          }
          </div>
        {editable && isEditing && renderEdit ? renderEdit(rowKey, rowVal) : null}
      </div>
      <div> {renderThreeYCEdit ? renderThreeYCEdit() :null} </div>
      {renderObject()}
    </React.Fragment>
  );
}

export default DetailedRecordRow;
