enum ThreeYCStatus{
  INACTIVE = 'INACTIVE',
  COMMITTED ='COMMITTED',
  EXPIRED = 'EXPIRED',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NONCOMPLIANT = 'NONCOMPLIANT'
}

export default ThreeYCStatus;