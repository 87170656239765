// Node packages
import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import { TabView, Tab } from '@react/react-spectrum/TabView';

// Custom styling and utils
import CustomCreateForm from './components/CustomCreatePage/CustomCreateForm';
import QuickCreateForm from './components/QuickCreatePage/QuickCreatePage';
import IneligibleMembershipsPage from './components/IneligibleMembershipsPage/IneligibleMembershipsPage';
import CreatedMembershipsPage from './components/CreatedMembershipsPage/CreatedMembershipsPage';

/**
 * TransfersPage contains the page to organize and create membership IDs for test transfers.
 */

const TransfersTab = ['Quick Create', 'Custom Create', 'Ineligible Memberships', 'Created Memberships'];

function TransfersPage(): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSelectedIndex(newSelectedTabIndex);
  };

  return (
    <div>
      <Heading variant='pageTitle'>Membership ID Management</Heading>
      <Heading variant='subtitle2'>Create, View, and Delete Sandbox Membership IDs for use with Transfer API.</Heading>
      <TabView selectedIndex={selectedIndex} onSelect={handleTabChange}>
        {TransfersTab.map((tabItem, index) => (
          <Tab key={index} label={tabItem} />
        ))}
      </TabView>
      {selectedIndex === 0 && <QuickCreateForm />}
      {selectedIndex === 1 && <CustomCreateForm />}
      {selectedIndex === 2 && <IneligibleMembershipsPage />}
      {selectedIndex === 3 && <CreatedMembershipsPage />}
    </div>
  );
}

export default TransfersPage;
