import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import { TabView, Tab } from '@react/react-spectrum/TabView';

import CreateDistributorPage from './components/CreateDistributorPage/CreateDistributorPage';
import GrantAccessPage from './components/GrantAccessPage/GrantAccessPage';
import QuickCreateDistributorPage from './components/QuickCreateDistributorPage/QuickCreateDistributorPage';

const InternalTab = ['Quick Create Distributor', 'Advanced Create Distributor', 'Grant Portal Access'];

function InternalPage(): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSelectedIndex(newSelectedTabIndex);
  };

  return (
    <div>
      <Heading variant='pageTitle'>Adobe Internal Console</Heading>
      <TabView selectedIndex={selectedIndex} onSelect={handleTabChange}>
        { InternalTab.map((tabItem, index) => (
            <Tab key={index} label={tabItem} />
          ))
        }
      </TabView>
      {selectedIndex === 0 && <QuickCreateDistributorPage />}
      {selectedIndex === 1 && <CreateDistributorPage />}
      {selectedIndex === 2 && <GrantAccessPage />}
    </div>
  );
}

export default InternalPage;
