// Node packages
import React from 'react';
import {Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom components
import DetailedRecordRow from '../DetailedRecordRow/DetailedRecordRow';
import SubresourcesSection from '../SubresourcesSection';

// Custom styling and utils
import './DetailedRecordContent.css';
import {subResources} from '../../service/TableProperties';
import {getGlobalSalesIdentifierOptions, getStatusOptionsWithReason} from '../../service/TableService';
import {DetailRecordObject, RecordObject, ThreeYCBenefitObject} from '../../models/ResourceModels';
import {
  customEditThreeYCCommittmentTerms,
  getRecordDetailById,
  createApprovalCode,
  createPA,
  updateResourceStatus,
  updateThreeYCCommittmentTermsWithOnlyAction,
  generateLinkedAccountCode,
  priceLevelRefresh,
  enrollLMMember,
  lmTermsStatus
} from '../../../providers/ResourcesAPI';
import Datepicker from '@react/react-spectrum/Datepicker';
import Select from '@react/react-spectrum/Select';
import Button from '@react/react-spectrum/Button';

import {ErrorResponse} from '../../../models/ErrorResponse';
import ErrorDialog from '../../../../src/utils/Dialogs/ErrorDialog';
import InformationDialog from '../../../../src/utils/Dialogs/InformationDialog';
import Edit3YCDetailsDialog from "../../../utils/Dialogs/EditThreeYCDetailsDialog";
import CreatePADialog from "../../../utils/Dialogs/CreatePADialog";
import { getSubSegmentOptions, getThreeYCAction } from "../../../utils/Functions/utils";
import {
  isRcpFlagEnabled,
  isRcpFlagDevEnabled,
  isCreatePaFlagEnabled,
  isCreatePaFlagDevEnabled,
  isWorldWideFlagEnabled,
  isWorldWideFlagDevEnabled,
  isLMflagDevEnabled,
  isLMflagEnabled
} from "../../../config/FeatureFlags";
import ThreeYCAction from "../../../constants/ThreeYCAction";
import ThreeYCStatus from "../../../constants/ThreeYCStatus";

import LinkedMemberEnrollDialog from '../../../utils/Dialogs/LinkedMemberEnrollDialog';
import LinkedMemberTermsDialog from '../../../utils/Dialogs/LinkedMemberTermsDialog';
/**
 * DetailedRecordContent renders the collapsible content of single record row in records table.
 * Contains the detailed information about a record and edit views for the record.
 */

interface DetailedRecordContentProps {
  data: RecordObject;
  // Keeps track of current page tab
  selectedTab: string;
  isCoolDown: boolean;
  handleCoolDown: (isCoolDown: boolean) => void;
  countDownTimer: number;
  handleViewSubresource: (selectedTab: string, searchText: string) => void;
  refreshCurrentTab: (currentTab: string) => void;
  handleRefresh: (callback: (response: DetailRecordObject) => void, response: DetailRecordObject) => void;
}

function DetailedRecordContent(props: DetailedRecordContentProps) {
  const {data, selectedTab, handleViewSubresource, handleRefresh, isCoolDown, handleCoolDown, countDownTimer, refreshCurrentTab} = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const [isCodeOpen, setCodeOpen] = React.useState(false);
  const [isCreatePaOpen, setCreatePaOpen] = React.useState(false);
  const [approvalCode, setApprovalCode] = React.useState('');
  const [resellerId, setResellerId] = React.useState('');
  const [linkedAccountCode, setLinkedAccountCode] = React.useState('');
  const [priceLevelMsg, setPriceLevelMsg] = React.useState('');
  const [lmEnrollmentMsg, setLMEnrollmentMsg] = React.useState('');
  const [linkedMemberTermsMsg, setLinkedMemberTermsMsg] = React.useState('');
  const [cotermDate, setCotermDate] = React.useState('');
  const [createdDate, setCreatedDate] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectValue, setSelectValue] = React.useState('');
  const [globalSalesEnabled, setGlobalSalesEnabled] = React.useState('false');
  const [marketSegments, setMarketSegments] = React.useState<string[]>([]);
  const [marketSegment, setMarketSegment] = React.useState('');
  const [marketSubSegments, setMarketSubSegments] = React.useState<string[]>([]);
  const [stateData, setStateData] = React.useState<DetailRecordObject | undefined>();
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  const [isThreeYCEditing, setIsThreeYCEditing] = React.useState(false);
  const [threeYCAction, setThreeYCAction] = React.useState('');
  const [areRequestedTermsPresent, setAreRequestedTermsPresent] = React.useState(false);
  const [areCommittedTermsPresent, setAreCommittedTermsPresent] = React.useState(false);
  const [isLMEnrollable, setIsLMEnrollable] = React.useState(true);
  const [isLMEnrollableDialogOpen, setIsLMEnrollableDialogOpen] = React.useState(false);
  const [isLMTerms, setIsLMTerms] = React.useState('');
  const [isLMAccepted, setIsLMAccepted] = React.useState(false);
  const [isLMTermsDialogOpen, setIsLMTermsDialogOpen] = React.useState(false);

  React.useEffect(() => {
    let recordType = selectedTab;
    let recordId = data.id;
    let recordMembershipId = recordType === 'transfers' ? data.membershipId : '';
    let recordAccountId = recordType === 'orders' || recordType === 'retailorders' ? data.accountId : '';
    getRecordDetailById(recordType, recordId, recordMembershipId, recordAccountId).then(
      (response: DetailRecordObject) => {
        if(recordType === 'customers' && response.associations){
          setResellerId(response.associations[0].id);
        }
        setView(response);
      }
    );
  }, []);

  const setView = (response: DetailRecordObject) => {
    if (response.httpstatus === 200) {
      if ("benefits" in response) {
        let benefitRead: ThreeYCBenefitObject[] = Array.isArray(response.benefits) ? response.benefits : [];
        let dictionary = Object.assign({}, ...benefitRead.map((b) => ({[b.type]: b})));
        response.benefits = dictionary;
        const ineligibleStatuses = ['REJECTED', 'EXPIRED', 'NONCOMPLIANT', 'DECLINED', 'INACTIVE'];
        setIsLMEnrollable(ineligibleStatuses.includes(dictionary.THREE_YEAR_COMMIT.commitmentRequest?.status));
      }
      if(response.companyProfile?.marketSegment && !response.companyProfile?.marketSubSegments) {
        response.companyProfile.marketSubSegments = [];
      }
      if(response.status === '1004' || response.status === '1008' || response.status === '1002') {
        setIsLMEnrollable(false); 
      }


      if ("linkedAccountGroup" in response) {
        setIsLMEnrollable(false);
        if ("termsAcceptanceStatus" in response.linkedAccountGroup ) {
          setIsLMTerms(response.linkedAccountGroup.termsAcceptanceStatus);
        }
        if (response?.linkedAccountGroup?.memberType === 'OWNER' && response?.linkedAccountGroup?.termsAcceptanceStatus === 'ACCEPTED') {
          setIsLMAccepted(true);
        }
      }

      if (selectedTab === 'customers' && (isWorldWideFlagEnabled() || isWorldWideFlagDevEnabled())) {
        setGlobalSalesEnabled(`${response.globalSalesEnabled}`);
      } else {
        delete response.globalSalesEnabled
      }

      setStateData(response);
      setSelectValue(response.status);
      setMarketSegments(response?.companyProfile?.marketSegments || []);
      setMarketSegment(response?.companyProfile?.marketSegment);
      setMarketSubSegments(response?.companyProfile?.marketSubSegments || []);
      setCotermDate(response.cotermDate);
      setCreatedDate(response.createdDate);
      setIsLoading(false);
      setIsError(false);

      if (selectedTab === 'customers') {
        if("benefits" in response)
        {
          let threeYCBenefits : ThreeYCBenefitObject = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'];
          setAreRequestedTermsPresent("commitmentRequest" in threeYCBenefits);
          setAreCommittedTermsPresent("commitment" in threeYCBenefits);
        }
        setIsThreeYCEditing(false);
        setThreeYCAction(getThreeYCAction(response));
      }
    } else {
      setErrorResponse(response);
      setIsLoading(false);
      setIsError(true);
    }
  }

  // Updates view after record is updated. Used as callback function to be Called after records page reloads.
  const updateContent = (response: DetailRecordObject) => {
    setIsEditing(!isEditing);
    setView(response);
  };

  const updateThreeYCContent = (response: DetailRecordObject) => {
    setIsThreeYCEditing(!isThreeYCEditing);
    setView(response)
  }
  const subResourceTypes = subResources[selectedTab];

  const handleEdit = () => {
    let resourceType = selectedTab;
    let resourceId = data.id;
    let accountId = resourceType === 'orders' ? data.accountId : '';

    const recordEdited = selectValue !== '' ||
      marketSegments || 
      marketSegment !== '' ||
      marketSubSegments
    if (isEditing && data && recordEdited) {
      setIsLoading(true);
      updateResourceStatus(
        resourceType,
        resourceId,
        selectValue,
        globalSalesEnabled,
        accountId,
        cotermDate,
        stateData?.cotermDate,
        createdDate,
        marketSegment,
        marketSegments,
        marketSubSegments
      ).then(
        (response: DetailRecordObject) => {
          handleRefresh(updateContent, response);
        }
      );
    } else {
      setIsEditing(!isEditing);
    }
  };

  const handleEditThreeYC = () => {
    let resourceId = data.id;
    if (threeYCAction !== ThreeYCAction.ENROLL_3YC) {
      setIsThreeYCEditing(true);
    } else {
      setIsThreeYCEditing(false);
      setIsLoading(true);
      updateThreeYCCommittmentTermsWithOnlyAction(resourceId, 'ENROLL').then(
        (response: DetailRecordObject) => {
          getRecordDetailById(selectedTab,resourceId,"","").then(
            (response: DetailRecordObject) => {
              handleRefresh(updateThreeYCContent, response);
            }
          )
        }
      );
    }
  }


  const handleCancel = () => {
    setIsEditing(false);
  };

  const handle3YCCancel = () => {
    let resourceId = data.id;
    if (threeYCAction === ThreeYCAction.ACCEPT_3YC_UPDATE || threeYCAction === ThreeYCAction.ACCEPT_3YC_COMMITMENT || threeYCAction === ThreeYCAction.ACCEPT_3YC_RECOMMITMENT) {
      updateThreeYCCommittmentTermsWithOnlyAction(resourceId, 'REJECT').then(
        (response: DetailRecordObject) => {
          getRecordDetailById(selectedTab,resourceId,"","").then(
            (response: DetailRecordObject) => {
              handleRefresh(updateThreeYCContent, response);
            }
          )
        }
      );
    }
    setIsThreeYCEditing(false);
  }

  // Ex. resellers -> Reseller's and retailorders -> Order's
  const getCurrentResourceType = (): string => {
    return selectedTab.startsWith('retail') ?
      selectedTab.charAt(6).toUpperCase() + selectedTab.slice(7, selectedTab.length - 1)
      : selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1, selectedTab.length - 1);
  };

  const handleDismiss3YCDialog = (newThreeYCStatus: string, newStartDate: string, newEndDate: string) => {
    let resourceId = data.id;
    if (isThreeYCEditing) {
      setIsLoading(true);
      let action = '';
      if (threeYCAction === ThreeYCAction.ACCEPT_3YC_COMMITMENT || threeYCAction === ThreeYCAction.ACCEPT_3YC_UPDATE || threeYCAction === ThreeYCAction.ACCEPT_3YC_RECOMMITMENT) {
        action = 'ACCEPT';
      }
      if (threeYCAction === ThreeYCAction.EDIT_3YC_TERMS && newThreeYCStatus === ThreeYCStatus.INACTIVE) {
        action = 'DEACTIVATE';
      }
      if (action !== '') {
        updateThreeYCCommittmentTermsWithOnlyAction(resourceId, action).then(
          (response: DetailRecordObject) => {
            getRecordDetailById(selectedTab,resourceId,"","").then(
              (response: DetailRecordObject) => {
                handleRefresh(updateThreeYCContent, response);
              }
            )
          }
        );
      } else {
        customEditThreeYCCommittmentTerms(resourceId, newStartDate, newEndDate, newThreeYCStatus, areRequestedTermsPresent).then(
          (response: DetailRecordObject) => {
            getRecordDetailById(selectedTab,resourceId,"","").then(
              (response: DetailRecordObject) => {
                handleRefresh(updateThreeYCContent, response);
              }
            )
          }
        );
      }
    } else {
      setIsThreeYCEditing(!isThreeYCEditing);
    }
  }

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const handleApprovalCodeClose = () => {
    setApprovalCode('');
    setCodeOpen(false);
  }

  const handleCreatePaClose = () => {
    setCreatePaOpen(false);
  }

  const getMarketSegmentOptions = () => {
    return [
      {
        label: 'COM',
        value: 'COM'
      },
      {
        label: 'GOV',
        value: 'GOV'
      },
      {
        label: 'EDU',
        value: 'EDU'
      },
    ]
  }


  // Render view of cells to edit object values
  const renderEditCells = (key: string, val: string | number | object) => {
    if (isEditing && key === 'creationDate' && selectedTab === 'orders') {
      return (
        <Datepicker
          value={createdDate}
          onChange={value => {
            if (typeof value === 'string') {
              setCreatedDate(value);
            }
          }}
          selectionType='single'
          type='date'
        />
      );
    } else if (isEditing && key === 'status') {
      return (
        <div className='table-cell'>
          <Select
            aria-label='Status Code'
            value={selectValue}
            options={getStatusOptionsWithReason(selectedTab, selectValue)}
            onSelect={value => setSelectValue(value)}
          />
        </div>
      );
    } else if (isEditing && key === 'globalSalesEnabled' && selectedTab === 'customers') {
      return (
        <div className='table-cell'>
          <Select
            aria-label='Global Sales Enabled'
            value={`${globalSalesEnabled}`}
            options={getGlobalSalesIdentifierOptions()}
            onSelect={value => setGlobalSalesEnabled(value)}
          />
        </div>
      );
    } else if (isEditing && key === 'cotermDate' && selectedTab === 'customers') {
      return (
        <Datepicker
          value={cotermDate}
          onChange={value => {
            if (typeof value === 'string') {
              setCotermDate(value);
            }
          }}
          min='today'
          selectionType='single'
          type='date'
        />
      );
    } else if (isEditing && key === 'marketSegments') {
      return (
        <div className='table-cell'>
          <Select
            multiple
            aria-label='Market segments'
            placeholder="Market Segments"
            value={marketSegments}
            options={getMarketSegmentOptions()}
            onSelect={value => {
              const selectedMarketSegments = value as unknown
              setMarketSegments(selectedMarketSegments as string[])
            }}
          />
        </div>
      ); 
    } else if (isEditing && key === 'marketSegment') {
      return (
        <div className='table-cell'>
          <Select
            aria-label='Market segment'
            placeholder="Market Segment"
            value={marketSegment}
            options={getMarketSegmentOptions()}
            onSelect={value => setMarketSegment(value)}
          />
        </div>
      ); 
    } else if (isEditing && key === 'marketSubSegments' && marketSegment === 'EDU') {
      return (
        <div className='table-cell'>
          <Select
            multiple
            aria-label='Sub Segments'
            placeholder="Sub Segments"
            value={marketSubSegments}
            options={getSubSegmentOptions(marketSubSegments)}
            onSelect={value => {
              const selectedMarketSubSegments = value as unknown
              setMarketSubSegments(selectedMarketSubSegments as string[])
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderThree3YCEdit = () => {
    if (isThreeYCEditing && threeYCAction !== ThreeYCAction.ENROLL_3YC && selectedTab === 'customers') {
      return <Edit3YCDetailsDialog
        isThreeYCEditing={isThreeYCEditing}
        response={stateData}
        threeYCAction={threeYCAction}
        areCommittedTermsPresent={areCommittedTermsPresent}
        areRequestedTermsPresent={areRequestedTermsPresent}
        handleDismiss3YCDialog={handleDismiss3YCDialog}
        handleDismiss3YCCancelDialog={handle3YCCancel}/>
    } else {
      return null;
    }
  }

  const handleLinkedAccountCode = async () => {
    const customerId = data.id;
    const linkedId = stateData?.linkedAccountGroup?.id
    const res = await generateLinkedAccountCode(linkedId, customerId);
    setLinkedAccountCode(res?.approvalCode ? res.approvalCode : (res?.message || 'Failed to generate linked membership code'));
  }

  const handlePriceLevel = async () => {
    const customerId = data.id;
    const linkedId = stateData?.linkedAccountGroup?.id
    const res = await priceLevelRefresh(linkedId, customerId);
    setPriceLevelMsg(res?.message || 'Request to refresh discount level submitted successfully');
  }

  function triggerAutoRenewal() {
    // observeCoolDown(true)
    handleCoolDown(true)
  }

  const generateApprovalCode = () => {
    let resourceId = data.id;
    createApprovalCode(resourceId).then(
      (response: string) => {
         setApprovalCode(response);
      }
    );
    setCodeOpen(true);
  }

  const handleCreatePaSubmit = (customerId : string, resellerId : string, productValue : string, productQty : number) => {
    createPA(customerId, resellerId, productValue, productQty).then(
      (response: string) => {
        setCreatePaOpen(false);
      }
    );
  }

  const createPAforCustomer = () => {
    let resourceId = data.id;
    console.log("Create PA for customer with id: " + resourceId);
    setCreatePaOpen(true);
  }

  const displaySubresourcesSection = (recordVal: string) =>
    recordVal === 'deployments'
      ? stateData?.globalSalesEnabled
      : true
  
  const handleLMEnrollments = async (customerId: string, approvalCode: string) => {
    const res = await enrollLMMember(customerId, approvalCode);
    setIsLMEnrollableDialogOpen(false);
    setLMEnrollmentMsg(res?.message || 'Successfully Enrolled');
  }

  const handleLinkedMemberTermsSubmit = async (status: string) => {
    const customerId = data.id;
    const res = await lmTermsStatus(customerId, status)

    setIsLMTermsDialogOpen(false);
    setLinkedMemberTermsMsg(res || 'Successfully Enrolled');
  }

  const renderContent = () => {
    return (
      <div>
        <Grid container spacing={5}>
          <Grid item xs={10}>
            <div className='table'>
              <div className='table-header'>
                <div className='table-row'>
                  <div className='table-small-cell'/>
                  <div className='table-cell'>Field</div>
                  <div className='table-cell'>Value</div>
                  {isEditing && <div className='table-cell'>Update Value</div>}
                </div>
              </div>
              <div className='table-body'>
                {stateData &&
                  Object.entries(stateData).map(([recordKey, recordVal]) => {
                    if (recordKey === 'httpstatus' || recordKey === 'message' || recordKey === 'reason' || recordVal === null
                    ) {
                    } else {
                      return (
                        <>
                          <DetailedRecordRow
                            key={recordKey}
                            rowKey={recordKey}
                            rowVal={recordVal}
                            editable={true}
                            isEditing={isEditing}
                            renderEdit={renderEditCells}
                            renderThreeYCEdit={renderThree3YCEdit}
                            handleLinkedAccountCode={handleLinkedAccountCode}
                            handlePriceLevel={handlePriceLevel}
                            isLMAccepted={isLMAccepted}
                          />
                          {Boolean(linkedAccountCode || priceLevelMsg || lmEnrollmentMsg || linkedMemberTermsMsg) && (
                            <InformationDialog
                              isOpen={true}
                              title={linkedAccountCode ? 'Linked Membership Code' : ''}
                              message={linkedAccountCode || priceLevelMsg || lmEnrollmentMsg || linkedMemberTermsMsg}
                              variant={'information'}
                              className={lmEnrollmentMsg ? 'information-dialog-msg' : ''}
                              handleConfirmConfirmationDialog={() => {
                                setLinkedAccountCode('')
                                setPriceLevelMsg('')
                                setLMEnrollmentMsg('')

                                if (linkedMemberTermsMsg || lmEnrollmentMsg) {
                                  refreshCurrentTab('customers');
                                }
                                setLinkedMemberTermsMsg('')
                              }}
                            />
                          )}
                        </>
                      )
                    }
                  })}
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            {!isThreeYCEditing ? <Button variant='action' onClick={handleEdit}>
              {isEditing ? 'Save' : 'Edit'}
            </Button> : null}
            {isEditing && (
              <Button variant='action' onClick={handleCancel}>
                Cancel
              </Button>
            )}
            <br/>
            {selectedTab === 'customers' || (selectedTab === 'retailorders' && selectValue === '1000') ?
              !isEditing && !isThreeYCEditing && (
                <Button variant='action' disabled={isCoolDown} onClick={triggerAutoRenewal}>
                  Trigger Auto Renewal
                  ({countDownTimer})
                </Button>
              ) : null
            }
            <br/>
            {selectedTab === 'customers' &&
              !isEditing &&
              !isThreeYCEditing &&
              selectValue === '1000' &&
              threeYCAction !== '' && (
                <Button variant='action' onClick={handleEditThreeYC}>
                  {threeYCAction}
                </Button>
            )}
            <br/>
            {selectedTab === 'customers' && (isRcpFlagEnabled() || isRcpFlagDevEnabled()) && (
              <div>
                <Button variant='action' onClick={generateApprovalCode}>
                  Generate Approval Code
                </Button>
                <InformationDialog
                  isOpen={isCodeOpen}
                  title={'Approval Code'}
                  message={approvalCode}
                  variant={'information'}
                  handleConfirmConfirmationDialog={handleApprovalCodeClose}
                />
              </div>
            )}
            <br/>
            {selectedTab === 'customers' && (isCreatePaFlagEnabled() || isCreatePaFlagDevEnabled()) ?
             (
              <div>
                <Button variant='action' onClick={createPAforCustomer}>
                  Create PA
                </Button>
                <CreatePADialog
                  isOpen={isCreatePaOpen}
                  customerId={data.id}
                  resellerId={resellerId}
                  handleConfirmConfirmationDialog={handleCreatePaSubmit}
                  handleDismissConfirmationDialog={handleCreatePaClose}
                />
              </div>
              ) : null
            }
            <br />
            {selectedTab === 'customers' && (isLMflagDevEnabled() || isLMflagEnabled()) && isLMEnrollable ?
              <>
                <Button variant='action' onClick={() => setIsLMEnrollableDialogOpen(!isLMEnrollableDialogOpen)}>
                  Enroll to Linked Membership
                </Button>
                <LinkedMemberEnrollDialog
                  isOpen={isLMEnrollableDialogOpen}
                  customerId={data.id}
                  title='Linked Member Enrollment'
                  variant='confirmation'
                  handleLinkedMemberEnrollDialog={handleLMEnrollments}
                  handleLMDismissConfirmationDialog={() => setIsLMEnrollableDialogOpen(!isLMEnrollableDialogOpen)}
                />
              </>
              : null
            }
            {selectedTab === 'customers' && (isLMflagDevEnabled() || isLMflagEnabled()) && isLMTerms === 'REQUESTED' ?
              <>
                <Button variant='action'onClick={() => setIsLMTermsDialogOpen(!isLMTermsDialogOpen)} >
                  Linked Membership Terms
                </Button>
                <LinkedMemberTermsDialog
                  isOpen={isLMTermsDialogOpen}
                  customerId={data.id}
                  variant='confirmation'
                  handleLinkedMemberTermsSubmit={handleLinkedMemberTermsSubmit}
                />
              </>
              : null
            }
          </Grid>
        </Grid>
        <br/>
        {Object.entries(subResourceTypes).map(([recordKey, recordVal]) => {
          let currentRecordVal = selectedTab.startsWith('retail') ? recordVal.charAt(6).toUpperCase() + recordVal.slice(7)
            : recordVal.charAt(0).toUpperCase() + recordVal.slice(1);
          return (
            <>
              {displaySubresourcesSection(recordVal) && 
                <SubresourcesSection
                  key={recordKey}
                  title={`${getCurrentResourceType()}'s Associated ${currentRecordVal}`}
                  subresourceType={recordVal}
                  handleViewSubresource={handleViewSubresource}
                  resourceType={selectedTab}
                  resourceId={data.id}
                />
              }
            </>
        )})}
      </div>
    );
  };

  return (
    <div> {isLoading ? (<CircularProgress className='table-loading-spinner' size={20}/>) : [
      (errorResponse && isError ?
        <ErrorDialog
          isError={true}
          status={errorResponse.httpstatus}
          reason={errorResponse.reason}
          message={errorResponse.message}
          handleDismissErrorDialog={handleDismissErrorDialog}
        />
        :
        renderContent())
    ]
    }
    </div>
  )
}

export default DetailedRecordContent;
