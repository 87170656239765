import React from 'react';

// UI libary components
import Heading from '@react/react-spectrum/Heading';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Select from '@react/react-spectrum/Select';

// Custom styling and utils
import './ViewAvailableCountriesPage.css';
import { getAllCountries, getCountriesForDistributor } from '../../../providers/CountryAPI';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';
import { CountryObject, CountriesResponse } from '../../../models/distributors/Country';
import { checkIfAdobeUser } from '../../../service/ApiService';
import { getPriceRegionList, getPriceRegion } from '../service/SelectService';

function ViewAvailableCountriesPage() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [allCountries, setAllCountries] = React.useState<CountryObject[]>([]);
  var [priceRegionCountries, setPriceRegionCountries] = React.useState<CountryObject[]>([]);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  var [pricelistRegion, setPricelistRegion] = React.useState("");
  var [selectedPricelistRegion, setSelectedPricelistRegion] = React.useState("");
  const availableCountriesProperties: { key: keyof CountryObject; title: string }[] = [
    { key: 'name', title: 'Country Name (Country Code)' },
    { key: 'postal_code_format_regex', title: 'Postal Code Regex' },
    { key: 'regions', title: 'Regions'}
  ];

  React.useEffect(() => {

      getAllCountries().then((countriesResponse: CountriesResponse) => {
          if(countriesResponse.httpstatus === 200) {
              setIsLoading(false);
              setIsError(false);
              setAllCountries(countriesResponse.data);
          } else {
              setIsError(true);
              setIsLoading(false);
              setErrorResponse(countriesResponse);
          }
      });
      
      getCountriesForDistributor().then((countriesResponse: CountriesResponse) => {
          if(countriesResponse.httpstatus === 200) {
              setIsLoading(false);
              setIsError(false);
              var sortedCountryList = countriesResponse.data.sort((a, b) => {
                  return a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base'
                  });
              })
              setPriceRegionCountries(sortedCountryList);
              setPricelistRegion(countriesResponse.data[0].pricelist_region)
              pricelistRegion = countriesResponse.data[0].pricelist_region
              setSelectedPricelistRegion(countriesResponse.data[0].pricelist_region)
              selectedPricelistRegion = countriesResponse.data[0].pricelist_region

          } else {
              setIsError(true);
              setIsLoading(false);
              setErrorResponse(countriesResponse);
          }
      });

  }, []);

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const updatePriceRegion = (priceRegion: string) => {
    setSelectedPricelistRegion(priceRegion)
    selectedPricelistRegion = priceRegion
    var results = allCountries.filter(country => ~[priceRegion].indexOf(country.pricelist_region));
    var sortedCountryList = results.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
    })
    setPriceRegionCountries(sortedCountryList);
    priceRegionCountries = sortedCountryList
  };

  return (
    <div>
      <div className='country-heading-container'>
        <Heading variant='subtitle1'>All Available Countries</Heading>
      </div>
      <Heading variant='subtitle3'>View data on the countries available in your price region.</Heading>
      <br />
      {isLoading ? (
        <CircularProgress size={20} className='country-table-loading-spinner' />
      ) : [
      
        (errorResponse && isError ?
        <ErrorDialog
        isError={true}
        status={errorResponse.httpstatus}
        reason={errorResponse.reason}
        message={errorResponse.message}
        handleDismissErrorDialog={handleDismissErrorDialog}
        />
        :
        <div>
           <div>Select a price region to view its countries.
            <br/>
            <Select
              aria-label='price-region'
              value={selectedPricelistRegion}
              defaultValue={getPriceRegion(selectedPricelistRegion)}
              options={getPriceRegionList()}
              onSelect={(value) => updatePriceRegion(value)}
            />
            <br/>
            <br/>
            </div>

            <i>Current Distributor Price Region: { pricelistRegion ? getPriceRegion(pricelistRegion) + " ("+ (pricelistRegion) +")" : null } </i>

            <TableContainer>
            <Table size='medium'>
                <TableHead>
                <TableRow>
                  {availableCountriesProperties.map(property => (
                    <TableCell key={String(property.key)} className='country-table-head'>
                        {property.title}
                    </TableCell>
                  ))}
                </TableRow>
                </TableHead>
                <TableBody>
                {priceRegionCountries.map((country, index) => (
                    <TableRow key={index}>
                    {availableCountriesProperties.map(property => {
                        if(property.key === "regions") {
                            var regionList: string[] = [];
                            country[property.key].map(region => {
                                regionList.push(region)
                            })

                            var sortedRegionListList = regionList.sort((a, b) => {
                              return a.localeCompare(b, undefined, {
                              numeric: true,
                              sensitivity: 'base'
                              });
                          })
                            return <TableCell key={String(country[property.key])}>{sortedRegionListList.join(", ")}</TableCell>;
                        } else if(property.key === "name") {
                            return <TableCell key={String(property.key)}>{country[property.key]} ({country.code})</TableCell>;
                        }else {
                            return <TableCell key={String(property.key)}>{country[property.key]}</TableCell>;
                        }
                    })}
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </div>
      )
    ]}
    </div>
  );
}

export default ViewAvailableCountriesPage;
