import React from 'react';

// UI library components
import TextField from '@react/react-spectrum/Textfield';
import { Grid } from '@material-ui/core';
import Button from '@react/react-spectrum/Button';

// Custom styles and utils
import './Form.css';
import { ContactObject } from '../../models/memberships/Contact';
import { getValidationState } from '../../utils/FormUtils';

interface ContactFormProps {
  contact: ContactObject;
  checkValid: boolean;
  index: number;
  updateContactState: (contact: ContactObject, index: number) => void;
  deleteContact: (index: number) => void;
}

function ContactForm(props: ContactFormProps): React.ReactElement {
  const { checkValid, index, updateContactState, deleteContact } = props;

  return (

    <Grid container spacing={3}>
      <Grid item xs={3}>
        First Name
        <br />
        <TextField
          key={index}
          className='form-field-textfield'
          placeholder="Enter First Name"
          value={props.contact.firstName}
          validationState={getValidationState(checkValid, props.contact.firstName)}
          onChange={value => {
            props.contact.firstName = value.trim();
            updateContactState(props.contact, index);
          }}
        />
        <br />
      </Grid>
      <Grid item xs={3}>
          Last Name
          <br />
          <TextField
            key={index}
            className='form-field-textfield'
            placeholder="Enter Last Name"
            value={props.contact.lastName}
            validationState={getValidationState(checkValid, props.contact.lastName)}
            onChange={value => {
              props.contact.lastName = value.trim();
              updateContactState(props.contact, index);
            }}
          />
          <br />
      </Grid>
      <Grid item xs={4}>
          Email (must be valid email format)
          <br />
          <TextField
            key={index}
            className='form-field-textfield'
            placeholder="Enter Email Address"
            value={props.contact.email}
            validationState={getValidationState(checkValid, props.contact.email)}
            onChange={value => {
              props.contact.email = value.trim();
              updateContactState(props.contact, index);
            }}
          />
      </Grid>
      <Grid item md={1} xs={12}>
        <div 
          className='form-delete-container'
          >
          <Button quiet variant='primary' className='form-delete-button' onClick={event => deleteContact(props.index)}>
            Delete
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
