
import React from 'react';


// UI library components
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import TextField from '@react/react-spectrum/Textfield';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';
import ConfirmationDialog from '../../../utils/Dialogs/ConfirmationDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createDefaultOrder, createDefaultReseller } from '../../../providers/DefaultDataAPI';
import { getSelectedDistributor } from '../../../service/ApiService';
import { returnOrder } from '../../../providers/ResourcesAPI';

function DefaultRetailRecordsPage() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingResellerRecord, setIsLoadingResellerRecord] = React.useState(false);
    const [isLoadingOrderRecord, setIsLoadingOrderRecord] = React.useState(false);
    const [isLoadingReturnRecord, setIsLoadingReturnRecord] = React.useState(false);

    const [isError, setIsError] = React.useState(false);
    var [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
    const [isComplete, setIsComplete] = React.useState(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState("");

    const [orderStatus, setOrderStatus] = React.useState("");
    const [resellerStatus, setResellerStatus] = React.useState("");
    const [returnOrderStatus, setReturnOrderStatus] = React.useState("");
    const [referenceOrderId, setReferenceOrderId] = React.useState("");

    const handleCreateDefaultReseller = () => {
        setIsLoadingResellerRecord(true);
        const { distributorId, imsOrgId } = getSelectedDistributor() || {}
        var defaultSet = {
            accountId : distributorId,
            tenantId : imsOrgId
        }
        createDefaultReseller(defaultSet).then((response) => {
            setIsLoadingResellerRecord(false);
            if(response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The reseller record was created successfully.")
                setIsError(false)
                setIsComplete(true)
                setResellerStatus("Successfully created reseller : " + response.completed.resellerId)
                //setDefaultResellerId(response.completed.resellerId)

            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
            }

        });
    };

    const handleCreateDefaultOrder = () => {
        const { distributorId, imsOrgId } = getSelectedDistributor() || {}

        setIsLoadingOrderRecord(true);
        setOrderStatus("")
        var defaultSet = {
            accountId: distributorId,
            tenantId: imsOrgId
        }
        setOrderStatus("This might take a while. Creating default order...")
        createDefaultOrder(defaultSet).then((response) => {
            setIsLoadingOrderRecord(false);
            if (response.httpstatus === 200 || response.httpstatus === 201) {
                setConfirmationMessage("The order was created successfully.")
                setIsError(false)
                setIsComplete(true)
                setOrderStatus("Successfully created order : " + response.completed.orderId)
            } else {
                setIsError(true)
                errorResponse = response.completed
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
                setOrderStatus("There was an error.")
            }

        });
    };

    const handleCreateReturnOrder = () => {

        setIsLoadingReturnRecord(true);
        setReturnOrderStatus("")
        returnOrder(referenceOrderId).then((response) => {
            setIsLoadingReturnRecord(false);
            if (response.httpstatus === 200) {
                setConfirmationMessage("The order was created successfully.")
                setIsError(false)
                setIsComplete(true)
                setReturnOrderStatus("Successfully created return order : " + response.orderId)

            } else {
                setIsError(true)
                errorResponse = response
                errorResponse!.httpstatus = response.httpstatus
                setErrorResponse(errorResponse)
                setReturnOrderStatus("There was an error.")
            }

        });
    };

    const handleConfirmDialog = () => {
        // Completed task
        setResellerStatus("")
        setOrderStatus("")
        setReturnOrderStatus("")
        setReferenceOrderId("")
        setIsComplete(false)
    };

    const handleDismissErrorDialog = () => {
        // handle error
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <div>
            <div className="settings-heading-container">
                <Heading variant="subtitle2">
                    Create default records with one click.
                </Heading>
                <br />
                {isLoading ? (
                    <CircularProgress size={20} />
                ) : (
                    [
                        errorResponse && isError ? (
                            <ErrorDialog
                                isError={true}
                                status={errorResponse.httpstatus}
                                reason={errorResponse.reason}
                                message={errorResponse.message}
                                handleDismissErrorDialog={handleDismissErrorDialog}
                            />
                        ) : (
                            <div>
                                <Button onClick={(event) => handleCreateDefaultReseller()}>
                                    Create Default  Reseller
                                </Button>
                                {isLoadingResellerRecord ? <CircularProgress size={20} /> : null}
                                <br />
                                <b>Reseller Creation Status:</b> <br />{resellerStatus}
                                <br />
                                <br />
                                <Button onClick={(event) => handleCreateDefaultOrder()}>
                                    Create Default Order
                                </Button>
                                {isLoadingOrderRecord ? <CircularProgress size={20} /> : null}
                                <br />
                                <b>Order Creation Status:</b> <br />{orderStatus}
                                <br />
                                <br />
                                <TextField
                                    className="order-id-textfield"
                                    placeholder="Enter reference Order Id"
                                    value={referenceOrderId}
                                    onChange={(value) => {
                                        setReferenceOrderId(value.trim());
                                    }}
                                />
                                <Button disabled={referenceOrderId == ""} onClick={(event) => handleCreateReturnOrder()}>
                                    Create Return Order
                                </Button>
                                {isLoadingReturnRecord ? <CircularProgress size={20} /> : null}
                                <br />
                                <b>Order Creation Status:</b> <br />{returnOrderStatus}
                                <br />
                            </div>
                        ),
                    ]
                )}
                {isComplete && (
                    <ConfirmationDialog
                        title="Task Complete"
                        isOpen={true}
                        variant="information"
                        message={confirmationMessage}
                        handleConfirmConfirmationDialog={handleConfirmDialog}
                        handleDismissConfirmationDialog={handleConfirmDialog}
                    />
                )}
            </div>
            <br />
        </div>
    );
}


export default DefaultRetailRecordsPage;