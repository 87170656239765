import React from 'react';

// UI libary components
import Heading from '@react/react-spectrum/Heading';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

// Custom styling and utils
import './ViewAvailableOffersPage.css';
import { getAvailableOffers } from '../../../providers/ProductsAPI';
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';

import { AvailableOffersObject, AvailableOffersResponse } from '../../../models/products/Offer';
import Select from "@react/react-spectrum/Select";
import {getCurrencies, getMarketSegments, getPriceLevelList, getPriceRegionList} from "../service/SelectService";
import { isWorldWideFlagDevEnabled, isWorldWideFlagEnabled } from '../../../config/FeatureFlags';

function ViewAvailableOffersPage() {
  const [isLoading, setIsLoading] = React.useState(true);
  // const [isEmpty, setEmpty] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [allOffers, setAllOffers] = React.useState<AvailableOffersObject[]>([]);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  const availableOffersProperties: { key: keyof AvailableOffersObject; title: string }[] = [
    { key: 'productId', title: 'Offer ID' },
    { key: 'productName', title: 'Product Name' },
    { key: 'language', title: 'Language' },
    { key: 'prices', title: 'Currency'},
    { key: 'productType', title: 'Product Type'},
    { key: 'priceLevel', title: 'Price Level'},
    { key: 'marketSegment', title: 'Market Segment'},
    { key: 'priceRegion', title: 'Price Region'}
  ];
  const [priceLevel, setPriceLevel] = React.useState("01");
  const [priceRegion, setPriceRegion] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [marketSegment, setMarketSegment] = React.useState("COM");
  const [expanded, setExpanded] = React.useState(false);
  const [teamExpanded, setTeamExpanded] = React.useState(false);

  React.useEffect(() => {

    const params = new Map();
    if (priceRegion) {
      params.set('price-region', priceRegion);
    }

    if (currency) {
      params.set('currency', currency);
    }

    getAvailableOffers(params).then((availableOffersResponse: AvailableOffersResponse) => {
      if(availableOffersResponse.httpstatus === 200) {
        setIsLoading(false);
        setIsError(false);
        setAllOffers(availableOffersResponse.data);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorResponse(availableOffersResponse);
      }
    });
  }, [priceRegion, currency]);

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const shouldDisplayOffer = (offer: AvailableOffersObject) => {
    return offer["priceLevel"] == priceLevel &&
      offer["marketSegment"] == marketSegment
  }

  return (
    <div>
      <div className='offers-heading-container'>
        <Heading variant='subtitle1'>All Available Offers</Heading>
      </div>
      <Heading variant='subtitle3'>View all available offers.</Heading>
      <br />
      {isLoading ? (
        <CircularProgress size={20} className='offers-table-loading-spinner' />
      ) : [
      
        (errorResponse && isError ?
        <ErrorDialog
        isError={true}
        status={errorResponse.httpstatus}
        reason={errorResponse.reason}
        message={errorResponse.message}
        handleDismissErrorDialog={handleDismissErrorDialog}
        />
        :
        <TableContainer>
          <div> <Heading variant='subtitle3'>Select a price level to view all available offers at that level </Heading>
            <Select
                aria-label='priceLevel'
                value={priceLevel}
                defaultValue={priceLevel}
                options={getPriceLevelList()}
                onSelect={(value) => setPriceLevel(value)}
            />
            <br/>
            <br/>
          </div>
          <div> <Heading variant='subtitle3'>Select a market segment to view all available offers at that segment </Heading>
            <Select
                aria-label='marketSegment'
                value={marketSegment}
                defaultValue={marketSegment}
                options={getMarketSegments()}
                onSelect={(value) => setMarketSegment(value)}
            />
            <br/>
            <br/>
          </div>
          {(isWorldWideFlagDevEnabled() || isWorldWideFlagEnabled()) && (
            <>
              <div> <Heading variant='subtitle3'>Select a price region</Heading>
                <Select
                  aria-label='priceRegion'
                  value={priceRegion}
                  defaultValue={priceRegion}
                  options={getPriceRegionList()}
                  onSelect={(value) => setPriceRegion(value)}
                />
                <br/>
                <br/>
              </div>
              <div> <Heading variant='subtitle3'>Select a currency</Heading>
                <Select
                  aria-label='currency'
                  value={currency}
                  defaultValue={currency}
                  options={getCurrencies()}
                  onSelect={(value) => setCurrency(value)}
                />
                <br/>
                <br/>
              </div>
            </>
          )}
          <Accordion expanded={expanded} onClick= {() => {setExpanded(!expanded)}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Heading variant='subtitle1'>Enterprise Products&ensp;</Heading>
              <Heading variant='subtitle3'>(click to expand)</Heading>
            </AccordionSummary>
            <AccordionDetails>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    {availableOffersProperties.map(property => (
                        <TableCell key={String(property.key)} className='offers-table-head'>
                          {property.title}
                        </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allOffers.map((offer, index) => (
                      <TableRow key={index}>
                        {availableOffersProperties.map(property => {
                          if(offer["productType"] == "ENTERPRISE") {
                            if(shouldDisplayOffer(offer)) {
                              if (property.key === "prices") {
                                var currencyList: string[] = [];
                                offer[property.key].map(price => {
                                  currencyList.push(price.currency)
                                })
                                return <TableCell key={String(offer[property.key])}>{currencyList.join(", ")}</TableCell>;
                              } else {
                                return <TableCell key={String(property.key)}>{offer[property.key]}</TableCell>;
                              }
                            }
                          }
                        })}
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={teamExpanded} onClick= {() => {setTeamExpanded(!teamExpanded)}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Heading variant='subtitle1'>Team Products&ensp;&ensp;&ensp;&ensp;&ensp;</Heading>
              <Heading variant='subtitle3'>(click to expand)</Heading>
            </AccordionSummary>
            <AccordionDetails>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    {availableOffersProperties.map(property => (
                        <TableCell key={String(property.key)} className='offers-table-head'>
                          {property.title}
                        </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allOffers.map((offer, index) => (
                      <TableRow key={index}>
                        {availableOffersProperties.map(property => {
                          if(offer["productType"] == "TEAM") {
                            if(shouldDisplayOffer(offer)) {
                              if (property.key === "prices") {
                                var currencyList: string[] = [];
                                offer[property.key].map(price => {
                                  currencyList.push(price.currency)
                                })
                                return <TableCell
                                    key={String(offer[property.key])}>{currencyList.join(", ")}</TableCell>;
                              } else {
                                return <TableCell key={String(property.key)}>{offer[property.key]}</TableCell>;
                              }
                            }
                          }
                        })}
                      </TableRow>
                  ))}

                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </TableContainer>
      )
    ]}
    </div>
  );
}

export default ViewAvailableOffersPage;
