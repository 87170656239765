/**
 * Functions to return mocked data for all records.
 */

import { statusOptions, statusReasons } from './TableProperties';

export function getRecordStatus(status: string): string {
  if (status === '1000') {
    return 'success';
  } else if (status === '1002' || status === '1001') {
    return 'pending';
  } else {
    return 'failure';
  }
}

function getStatusCodeReason(status: string): string {
  return statusReasons[String(status)];
}

export function getStatusOptionsWithReason(recordType: string, currentStatus: string) {
  let options = statusOptions[recordType][currentStatus];
  let statusOptionsWithReason = options.map(option => {
    return {
      label: `${String(option)} - ${getStatusCodeReason(option)}`,
      value: option
    };
  });
  return statusOptionsWithReason;
}

export function getGlobalSalesIdentifierOptions() {
  return [
    { label: 'true', value: 'true' },
    { label: 'false', value: 'false' }
  ]
}
