import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Datepicker from "@react/react-spectrum/Datepicker";
import Select from "@react/react-spectrum/Select";
import ThreeYCAction from "../../constants/ThreeYCAction";
import {DetailRecordObject, ThreeYCBenefitObject} from "../../RecordsPage/models/ResourceModels";
import {formatDate} from "../Functions/utils";

interface Edit3YCDetailsProps {
  isThreeYCEditing: boolean;
  response: DetailRecordObject | undefined;
  threeYCAction: string;
  areCommittedTermsPresent: boolean;
  areRequestedTermsPresent: boolean;
  handleDismiss3YCDialog: (newThreeYCStatus: string, newStartDate: string, newEndDate: string) => void;
  handleDismiss3YCCancelDialog: () => void;
}

function Edit3YCDetailsDialog(props: Edit3YCDetailsProps) {
  const {
    isThreeYCEditing,
    response,
    threeYCAction,
    areCommittedTermsPresent,
    areRequestedTermsPresent,
    handleDismiss3YCDialog,
    handleDismiss3YCCancelDialog,
  } = props;

  let threeYCStatus = '';
  let threeYCEndDate = '';
  let threeYCStartDate = '';

  if(response !== undefined) {
    if (areCommittedTermsPresent) {
      threeYCStatus = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'].commitment?.status as string;
      threeYCStartDate = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'].commitment?.startDate as string;
      threeYCEndDate = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'].commitment?.endDate as string;
    } else if (!areCommittedTermsPresent && areRequestedTermsPresent) {
      threeYCStatus = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'].commitmentRequest?.status as string;
      threeYCStartDate = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'].commitmentRequest?.startDate as string;
      threeYCEndDate = (response.benefits as {[id: string]: ThreeYCBenefitObject})['THREE_YEAR_COMMIT'].commitmentRequest?.endDate as string;
    }
  }
  const [newThreeYCStatus, setNewThreeYCStatus] = React.useState(threeYCStatus);
  const [newThreeYCStartDate, setNewThreeYCStartDate] = React.useState(threeYCStartDate);
  const [newThreeYCEndDate, setNewThreeYCEndDate] = React.useState(threeYCEndDate);

  const getThreeYCStatusOptions = [
    {
      label: "COMMITTED",
      value: "COMMITTED",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    }
  ]

  function getDialogTitle(threeYCAction: string) {
    return (threeYCAction === ThreeYCAction.EDIT_3YC_TERMS ? ThreeYCAction.EDIT_3YC_TERMS : '');
  }
  function getDialogConfirmLabel(threeYCAction: string) {
    return (threeYCAction === ThreeYCAction.ACCEPT_3YC_COMMITMENT || threeYCAction === ThreeYCAction.ACCEPT_3YC_UPDATE || threeYCAction === ThreeYCAction.ACCEPT_3YC_RECOMMITMENT) ? 'Accept' : 'Save';
  }

  function getDialogCancelLabel(threeYCAction: string) {
    return (threeYCAction === ThreeYCAction.ACCEPT_3YC_COMMITMENT || threeYCAction === ThreeYCAction.ACCEPT_3YC_UPDATE || threeYCAction === ThreeYCAction.ACCEPT_3YC_RECOMMITMENT) ? 'Reject' : 'Cancel';
  }

  function getMinAnniversaryDate() {
    let today = new Date();
    today.setDate(today.getDate()-1);
    return today;
  }

  return (
    <Dialog
      title={getDialogTitle(threeYCAction)}
      open={isThreeYCEditing}
      confirmLabel={getDialogConfirmLabel(threeYCAction)}
      cancelLabel={getDialogCancelLabel(threeYCAction)}
      onConfirm={() => handleDismiss3YCDialog(newThreeYCStatus, newThreeYCStartDate, newThreeYCEndDate)}
      onCancel={() => handleDismiss3YCCancelDialog()}
    >
      { threeYCAction === ThreeYCAction.EDIT_3YC_TERMS ?
        <div>
          Start Date :
          <Datepicker
            value={newThreeYCStartDate}
            onChange={value => {
              if (typeof value === 'string') {
                setNewThreeYCStartDate(value);
              }
            }}
            max={newThreeYCEndDate}
            selectionType='single'
            type='date'
          />
          <br/>
          <br/>
          End Date :
          <Datepicker
            value={newThreeYCEndDate}
            onChange={value => {
              if (typeof value === 'string') {
                setNewThreeYCEndDate(value);
              }
            }}
            min={formatDate(getMinAnniversaryDate())}
            selectionType='single'
            type='date'
          />
          <br/>
          <br/>
          Status :
          {
            <Select
              aria-label='3YC Status'
              value={newThreeYCStatus}
              options={getThreeYCStatusOptions}
              onSelect={value => setNewThreeYCStatus(value)}
            />
          }
        </div> : <div> Accept/Reject 3YC with given requested terms </div>
      }
    </Dialog>
  );
}

export default Edit3YCDetailsDialog;