import React from "react";
import Dialog from "@react/react-spectrum/Dialog";
import "./LinkedMemberEnrollDialog.css";

interface LinkedMemberTermsDialogProps {
    isOpen: boolean;
    customerId: string;
    variant: "confirmation" | "information" | "destructive" | "error";
    handleLinkedMemberTermsSubmit: (status: string) => void;
}

function LinkedMemberTermsDialog(props: LinkedMemberTermsDialogProps) {
    const { isOpen,  variant, handleLinkedMemberTermsSubmit } = props;

    return (
        <Dialog
            className="lm-dialog"
            open={isOpen}
            cancelLabel="Reject"
            confirmLabel="Accept"
            variant={variant}
            onConfirm={() => handleLinkedMemberTermsSubmit('ACCEPTED')}
            onCancel={() => handleLinkedMemberTermsSubmit('REJECTED')}
        >
            Accept/Reject Linked Membership Terms
        </Dialog>
    );
}

export default LinkedMemberTermsDialog;
