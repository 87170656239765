import ApiConstants from '../constants/ApiConstants';

const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

let host: string;
let envName: string;
let floodgateUrl = 'https://p13n-stage.adobe.io/fg/api/v3/feature';
if (hostname === 'partnerportal-dev.adobe.com') {
  host = ApiConstants.DEV_ENDPOINT;
  envName = "SANDBOX (DEV)";
} else if (hostname === 'partnerportal-sandbox.adobe.com') {
  host = ApiConstants.PRESTAGE_ENDPOINT;
  floodgateUrl = 'https://p13n.adobe.io/fg/api/v3/feature';
  envName = "SANDBOX";
} else {
  host = ApiConstants.LOCAL_ENDPOINT;
  envName = "SANDBOX (LOCAL)";
}

export const API_ROOT = `${host}/${apiVersion}`;
export const ENV_NAME = envName;
export const FG_URL = floodgateUrl;
