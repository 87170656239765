// Node packages
import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import { TabView, Tab } from '@react/react-spectrum/TabView';

// Custom styling and utils
import ManageMembersPage from './components/ManageMembersPage/ManageMembersPage';

/**
 * ManageDistributorsPage contains the page to organize and manage distributors and their members.
 */

const ManageTab = ['Manage Direct Partner Members'];

function ManageDistributorsPage(): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (newSelectedTabIndex: number) => {
    setSelectedIndex(newSelectedTabIndex);
  };

  return (
    <div>
      <Heading variant='pageTitle'>Direct Partner Management</Heading>
      <Heading variant='subtitle3' style={{ color: 'red' }} >Note - Within this portal, direct partners may be referred to as distributors.</Heading>
      <TabView selectedIndex={selectedIndex} onSelect={handleTabChange}>
        {
          ManageTab.map((tabItem, index) => (
            <Tab key={index} label={tabItem} />
          ))
        }
      </TabView>
      {selectedIndex === 0 && <ManageMembersPage />}

    </div>
  );
}

export default ManageDistributorsPage;
