import React from 'react';

// UI library components
import { Toolbar } from '@material-ui/core';
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Link from '@react/react-spectrum/Link';
// import Tooltip from '@react/react-spectrum/Tooltip';
import Dropdown from '@react/react-spectrum/Dropdown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MenuItem, Menu } from '@react/react-spectrum/Menu';

// Custom styling and utils
import './NavigationBar.css';
import { IMSAccountService } from '../../service/ims/IMSAccountService';
import UserInfo from '../../models/UserInfo';
import { Distributor } from '../../providers/DistributorsAPI';
import {ENV_NAME} from '../../config/ApiConfig'
import { checkIfAdobeUser, getAllDistributors, getSelectedDistributor, isSelectedDistributorProgramRetail, selectDistributor } from '../../service/ApiService';
import { getCurrentUserDistributorInfo } from '../../utils/Functions/utils';

/**
 * NavigationBar renders the main navigation bar and is shown on every page.
 */

interface Option {
  label: string;
  value: string;
}

function NavigationBar(): React.ReactElement {
  const [userInfo, setUserInfo] = React.useState<UserInfo>();
  const [distributorList, setDistributorList] = React.useState<Distributor[]>([]);
  const [distributorOptions, setDistributorOptions] = React.useState<Option[]>([]);
  const [selectedDistributorName, setSelectedDistributorName] = React.useState('');

  React.useEffect(() => {
    let distributorsOptions = getAllDistributors().map(distributor => {
      return {
        label: distributor.distributorName,
        value: distributor.distributorName
      };
    });

    setUserInfo(getCurrentUserDistributorInfo());
    setDistributorList(getAllDistributors());
    setDistributorOptions(distributorsOptions);
    setSelectedDistributorName(getSelectedDistributor().distributorName);
  }, []);

  const handleSaveDistributor = (value: String) => {
    let selectedDistributor = distributorList.find(({ distributorName }) => distributorName === value);
    if (selectedDistributor) {
      selectDistributor(selectedDistributor);
      // kind of clumsy... should be able to handle this internally via state.
      window.location.reload();
    }
  };

  const logout = () => {
    IMSAccountService.signOut();
  };

  return (
    <div className='navigation-bar-container'>
      <Toolbar variant='dense' className='navigation-bar-toolbar'>
        <div>
        
          <Heading variant='subtitle1' className='navigation-bar-heading'>
          PARTNER API {ENV_NAME}
          </Heading>
          {!isSelectedDistributorProgramRetail() ? (
            <>
              <Link href='/records' className='navigation-bar-link'>
                Manage Records
              </Link>
              <Link href='/transfers' className='navigation-bar-link'>
                Transfer Memberships
              </Link>
              <Link href='/resources' className='navigation-bar-link'>
                Portal Resources
              </Link>
              <Link href='/tools' className='navigation-bar-link'>
                API Tools
              </Link>
            </>
          ) : (<Link href='/retail/records' className='navigation-bar-link'>
              Manage Records
            </Link>)
          }
          {checkIfAdobeUser() &&
            <Link href='/internal' className='navigation-bar-link'>
              Internal
            </Link>
          }
          <Heading variant='subtitle2' className='navigation-bar-banner' style={{ color: 'red' }}>
          IMPORTANT - THIS PORTAL IS INTENDED FOR NON-PRODUCTION USE ONLY.
          </Heading>
        </div>

        <div className='navbar-user-info-container'>
          <div>
            <div className="tooltip">
              <Link href='/distributors' className='navigation-bar-link-right'>
                My Distributors
              </Link>
              <span className="tooltiptext ">

                Current Distributor Name:&nbsp;
                <div
                  className='copy-id-tooltip-white'
                  onClick={() => {
                    navigator.clipboard.writeText(String(userInfo?.selectedDistributor.distributorName));
                  }}
                >
                   {" " + userInfo?.selectedDistributor.distributorName}
                  <span className='copy-id-tooltiptext'>Copy Distributor Name</span>
                </div>  
                <br/>
                Distributor ID:&nbsp;
                <div
                  className='copy-id-tooltip-white'
                  onClick={() => {
                    navigator.clipboard.writeText(String(userInfo?.selectedDistributor.distributorId));
                  }}
                >
                   {" " + userInfo?.selectedDistributor.distributorId}
                  <span className='copy-id-tooltiptext'>Copy Distributor ID</span>
                </div>  
                <br/>
                IMS Org ID:&nbsp;
                <div
                  className='copy-id-tooltip-white'
                  onClick={() => {
                    navigator.clipboard.writeText(String(userInfo?.selectedDistributor.imsOrgId));
                  }}
                >
                  
                   {userInfo?.selectedDistributor.imsOrgId}
                  <span className='copy-id-tooltiptext'>Copy IMS Org ID</span>
                </div>  
                
              </span>
              {/* The code below serves as a spacer to keep the "My Distributor" link aligned 
              with the rest of the  menu items in the nav bar (WA)*/}
              <Heading variant='subtitle3' className='navigation-bar-spacer' style={{ color: 'white' }}>
              "blank"
              </Heading>
            </div>
          </div>
          <Dropdown onSelect={value => handleSaveDistributor(value)}>
            <Button
              dropdownTrigger
              variant='action'
              className='navbar-distributor-dropdown'
              icon={<KeyboardArrowDownIcon />}
            >
              {selectedDistributorName}
            </Button>
            <Menu dropdownMenu className='navbar-distributor-dropdown' value={selectedDistributorName}>
              {distributorOptions.map(distributor => {
                return distributor.value === selectedDistributorName ? (
                  <MenuItem key={distributor.value} role='option' value={distributor.value} selected>
                    {distributor.label}
                  </MenuItem>
                ) : (
                  <MenuItem key={distributor.value} role='option' value={distributor.value}>
                    {distributor.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Dropdown>
          <div>
          <Button onClick={logout}>Logout</Button>
          </div>

        </div>
      </Toolbar>
    </div>
  );
}

export default NavigationBar;
