import React from 'react';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom styles and utils
import './IneligibleMembershipsPage.css';
import { getInvalidMemberships } from '../../../providers/MembershipsAPI';

import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../../src/utils/Dialogs/ErrorDialog';

interface InvalidMembershipObject {
  membershipId: string;
  ineligibleCode: string;
  ineligibleMessage: string;
}

interface InvalidMembershipResponse {
  totalCount: number;
  data: InvalidMembershipObject[];
  httpstatus: number;
  message: string;
  reason: string;
}

function IneligibleMembershipsPage() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [invalidMemberships, setInvalidMemberships] = React.useState<InvalidMembershipObject[]>([]);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();
  const invalidMembershipProperties: { key: keyof InvalidMembershipObject; title: string }[] = [
    { key: 'membershipId', title: 'Membership ID' },
    { key: 'ineligibleCode', title: 'Error Code' },
    { key: 'ineligibleMessage', title: 'Error Message' }
  ];

  const sortMemberships = (memberships: InvalidMembershipObject[]) => {
    let sortedMemberships = memberships.sort(function(a, b) {
      return parseInt(a.ineligibleCode) - parseInt(b.ineligibleCode);
    });
    return sortedMemberships;
  };

  React.useEffect(() => {
    getInvalidMemberships().then((invalidMembershipResponse: InvalidMembershipResponse) => {
      if(invalidMembershipResponse.httpstatus === 200) {
        let sortedIneligibleMemberships = sortMemberships(invalidMembershipResponse.data);
        setIsLoading(false);
        setIsError(false);
        setInvalidMemberships(sortedIneligibleMemberships);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorResponse(invalidMembershipResponse);
      }
    });
  }, []);

  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <div className='memberships-header-container'>
        <Heading variant='subtitle1'>Ineligible Memberships</Heading>
      </div>
      <Heading variant='subtitle3'>Default set of ineligible Membership IDs for testing error responses</Heading>
      <br />
      {isLoading ? (
        <CircularProgress size={20} className='memberships-table-loading-spinner' />
      ) : [

        (errorResponse && isError ?
        <ErrorDialog
        isError={true}
        status={errorResponse.httpstatus}
        reason={errorResponse.reason}
        message={errorResponse.message}
        handleDismissErrorDialog={handleDismissErrorDialog}
        />
        :
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {invalidMembershipProperties.map(property => (
                  <TableCell key={String(property.key)} className='memberships-table-head'>
                    {property.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invalidMemberships.map(membership => (
                <TableRow key={membership.membershipId}>
                  {invalidMembershipProperties.map(property => (
                    <TableCell key={String(property.key)}>{membership[property.key]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    ]}
    </div>
  );
}

export default IneligibleMembershipsPage;
