// Node packages
import React from 'react';

// UI library components
import Button from '@react/react-spectrum/Button';
import Search from '@react/react-spectrum/Search';

// Custom styles
import './RecordsSearchBar.css';

/**
 * RecordsSearchBar renders the main search bar on the RecordsPage
 */

 // to do update placeholder
interface RecordsSearchBarProps {
  searchText: string;
  handleSearchClick: (searchText: string) => void;
}

function RecordsSearchBar(props: RecordsSearchBarProps) {
  const { handleSearchClick, searchText } = props;
  const [searchInput, setSearchInput] = React.useState(searchText);
  const [isSearching, setIsSearching] = React.useState(searchText === '');

  React.useEffect(() => {
    setSearchInput(searchText);
    setIsSearching(searchText === '');
  }, [searchText]);

  const onSearchClick = () => {
    // If searching, search for text. Otherwise, clear search.
    let input = isSearching ? searchInput : '';
    handleSearchClick(input);
    setSearchInput(input);
    setIsSearching(!isSearching);
  };

  const onInputChange = (newSearchText: string) => {
    setSearchInput(newSearchText);
    setIsSearching(true);
  };

  return (
    <div className='search-container'>
      <Search
        className='records-search-bar'
        aria-label='Search'
        placeholder='Search by id...'
        value={searchInput}
        onChange={(value, event) => onInputChange(value)}
        onSubmit={value => onSearchClick()}
      />
      <Button className='search-bar-button' onClick={onSearchClick}>
        {isSearching ? 'Search' : 'Clear'}
      </Button>
    </div>
  );
}

export default RecordsSearchBar;
