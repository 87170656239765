import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';
export async function resetToFactoryDefault(): Promise<any> {
  console.log("Reset to factory Default from the API backend.")
  // let response = await fetch(`${API_ROOT}/api/reset_factory_default`, {
  //   headers: generateRequestHeaders()
  // });
  // let data = await response.json();
  // data.httpstatus = response.status;
  // return data;

  var data: any = {};
  data.httpstatus = 200; //500;
  // data.message = "Failure";
  // data.reason = "Failure";
  return data
}

// TODO: create optional response from backend

export async function createDefaultSet(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/default/all`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function createDefaultReseller(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/resellers/default`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}
export async function createDefaultCustomer(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/customers/default`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}
export async function createDefaultOrder(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/orders/default`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}
export async function createDefaultMembership(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/memberships/default`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}
export async function createDefaultTransfer(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/transfers/default`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}

export async function importResellers(body: Object): Promise<any> {
  let response = await fetch(`${API_ROOT}/resellers/import`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });

  var data = {httpstatus: 500, completed: false};
  data.completed = await response.json();
  data.httpstatus = response.status;
  return data;
}


